const Button = {
  baseStyle: {
    fontWeight: "medium",
    fontSize: "md",
    borderRadius: "5px",
  },

  variants: {
    primary: {
      bg: "primary.500",
      color: "white",
      _hover: {
        bg: "primary.600",
        _disabled: { bg: "" },
      },
      _focus: {
        bg: "primary.600",
        boxShadow: "0 0 0 3px rgba(61, 205, 181, 0.5)",
      },
      _active: { bg: "primary.600" },
    },
    secondary: {
      bg: "secondary.500",
      color: "white",
      _hover: {
        bg: "secondary.600",
        _disabled: { bg: "" },
      },
      _focus: {
        bg: "secondary.600",
        boxShadow: "0 0 0 3px rgba(207, 174, 137, 0.5)",
      },
      _active: { bg: "secondary.600" },
    },
    outline: {
      bg: "white",
      color: "primary.500",
      borderWidth: "1px",
      borderColor: "primary.500",
      _hover: {
        bg: "gray.50",
        _disabled: { bg: "" },
      },
      _focus: {
        bg: "gray.50",
        boxShadow: "0 0 0 3px rgba(61, 205, 181, 0.5)",
      },
      _active: { bg: "gray.50" },
    },
    light: {
      bg: "primary.50",
      color: "primary.500",
      _hover: {
        bg: "primary.100",
        _disabled: { bg: "" },
      },
      _focus: {
        bg: "primary.100",
        boxShadow: "0 0 0 3px rgba(61, 205, 181, 0.5)",
      },
      _active: { bg: "primary.100" },
    },
    dark: {
      bg: "primary.800",
      color: "white",
      _hover: {
        bg: "primary.900",
        _disabled: { bg: "" },
      },
      _focus: {
        bg: "primary.900",
        boxShadow: "0 0 0 3px rgba(61, 205, 181, 0.5)",
      },
      _active: { bg: "primary.900" },
    },
  },

  defaultProps: {
    variant: "primary",
    size: "md",
  },
};

export default Button;
