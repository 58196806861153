import { Icon, IconProps } from "@chakra-ui/react";

const Twitter = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.750577 3.5935C0 5.03843 0 6.93673 0 10.7333V21.2667C0 25.0633 0 26.9616 0.750577 28.4065C1.38308 29.6241 2.37588 30.6169 3.5935 31.2494C5.03843 32 6.93673 32 10.7333 32H21.2667C25.0633 32 26.9616 32 28.4065 31.2494C29.6241 30.6169 30.6169 29.6241 31.2494 28.4065C32 26.9616 32 25.0633 32 21.2667V10.7333C32 6.93673 32 5.03843 31.2494 3.5935C30.6169 2.37588 29.6241 1.38308 28.4065 0.750577C26.9616 0 25.0633 0 21.2667 0H10.7333C6.93673 0 5.03843 0 3.5935 0.750577C2.37588 1.38308 1.38308 2.37588 0.750577 3.5935ZM24.8923 12.701C24.8923 12.5011 24.8897 12.3013 24.8819 12.1015C25.7097 11.4564 26.4263 10.648 26.9956 9.73043C26.2368 10.0938 25.4185 10.3389 24.5607 10.448C25.4375 9.88469 26.1085 8.98546 26.4263 7.91344C25.6063 8.44037 24.7002 8.82191 23.7329 9.02178C22.9603 8.13145 21.8596 7.57719 20.6408 7.57719C18.2997 7.57719 16.4022 9.63043 16.4022 12.1651C16.4022 12.5194 16.4393 12.8737 16.5116 13.2098C12.9897 13.019 9.86565 11.1929 7.77609 8.41293C7.40917 9.0943 7.20073 9.88473 7.20073 10.7205C7.20073 12.3104 7.94922 13.7185 9.08789 14.5453C8.3928 14.518 7.73821 14.3092 7.16629 13.973V14.0276C7.16629 16.2534 8.62794 18.1067 10.5685 18.5246C10.2102 18.6336 9.83638 18.6881 9.45137 18.6881C9.17833 18.6881 8.91134 18.6607 8.65208 18.6062C9.19213 20.4232 10.758 21.7587 12.6124 21.7859C11.162 23.0215 9.33338 23.7575 7.34803 23.7575C7.00609 23.7575 6.66931 23.7302 6.3377 23.6939C8.21366 24.993 10.4402 25.7562 12.8346 25.7562C20.6296 25.7562 24.8923 18.7607 24.8923 12.701Z"
        fill="#0A230E"
      />
    </Icon>
  );
};

export default Twitter;
