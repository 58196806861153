import { Icon, IconProps } from "@chakra-ui/react";

const OngoingCases = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 25 25" fill="currentcolor" {...props}>
      <path d="M5.03335 21.2128C4.635 21.2128 4.28645 21.0634 3.98769 20.7647C3.68893 20.4659 3.53955 20.1174 3.53955 19.719V4.78106C3.53955 4.38271 3.68893 4.03416 3.98769 3.7354C4.28645 3.43664 4.635 3.28726 5.03335 3.28726H19.9713C20.3697 3.28726 20.7182 3.43664 21.017 3.7354C21.3157 4.03416 21.4651 4.38271 21.4651 4.78106V19.719C21.4651 20.1174 21.3157 20.4659 21.017 20.7647C20.7182 21.0634 20.3697 21.2128 19.9713 21.2128H5.03335ZM5.03335 19.719H19.9713V16.3331H16.3364C15.9049 16.997 15.3447 17.5074 14.6559 17.8642C13.9671 18.2211 13.2492 18.3995 12.5023 18.3995C11.7554 18.3995 11.0376 18.2211 10.3488 17.8642C9.65997 17.5074 9.0998 16.997 8.66825 16.3331H5.03335V19.719ZM12.5023 16.9057C13.1828 16.9057 13.797 16.7107 14.3447 16.3206C14.8924 15.9306 15.3571 15.4368 15.7389 14.8393H19.9713V4.78106H5.03335V14.8393H9.26577C9.64752 15.4368 10.1123 15.9306 10.66 16.3206C11.2077 16.7107 11.8218 16.9057 12.5023 16.9057ZM12.5023 13.694L8.69315 9.88487L9.76371 8.81431L11.7554 10.806V6.10058H13.2492V10.806L15.241 8.81431L16.3115 9.88487L12.5023 13.694ZM5.03335 19.719H19.9713H5.03335Z" />
    </Icon>
  );
};

export default OngoingCases;
