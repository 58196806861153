import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/flagit-logo.png";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import Link from "../../components/common/Link";
import Email from "../../components/svg/Email";
import { useAuth } from "../../context/useAuth";

export const VerifyEmail = () => {
  const auth = useAuth();
  const [timer, setTimer] = useState(60);
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setTimer(prev => prev - 1);
    }, 1000);
    if (timer === 0 && interval.current) clearInterval(interval.current);
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [timer]);

  const resendVerification = () => {
    setTimer(60);
    auth?.resendEmailVerification();
  };

  return (
    <Container p={4}>
      <Helmet>
        <title>FlagIt | Verify Email</title>
      </Helmet>
      <Box my={{ base: 0, sm: 8 }}>
        <Box w="92px">
          <Link to="/">
            <Image src={logo} />
          </Link>
        </Box>
      </Box>
      <Flex align="center" justify="center">
        <Box textAlign="center">
          <Heading size="2xl">Email verification sent!</Heading>
          <Text fontSize="md" my={2}>
            Please check your mail for verification link to access your
            dashboard
          </Text>
          <Email fontSize="300px" />
          <Text my={4}>
            If you do not receive a mail in{" "}
            <Text as="span" color="textSecondary">
              {`${timer}s`}
            </Text>
            , click the button below to resend
          </Text>
          <Button
            onClick={resendVerification}
            disabled={timer > 0}
            isLoading={auth?.loading}
            h="50px"
            w="full"
            mb={4}
          >
            Resend
          </Button>
          <Link to="/login">
            <Button variant="outline" h="50px" w="full">
              Back to app
            </Button>
          </Link>
        </Box>
      </Flex>
    </Container>
  );
};
