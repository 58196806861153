import axiosInstance from "../helpers/axiosInstance";
import { LocationDTO } from "../interfaces/Location";
import { ResponseWithPagination } from "../interfaces/Response";

const getLocations = (page: number, size: number) => {
  const url = `/locations/nonGeoCodedLocations?page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<any>>(url);
};

const editLocation = (data: LocationDTO) => {
  const url = `/locations`;
  return axiosInstance.put(url, data);
};

const locationService = {
  getLocations,
  editLocation,
};

export default locationService;
