import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useMutation } from "react-query";
import reportsService from "../services/reportsService";

interface Props {
  dateFilter: { startDate: string; endDate: string };
  pageIndex: number;
  pageSize: number;
}

const useExportOverallReports = () => {
  const toast = useToast();
  const { isError, error, isSuccess, data, mutate, isLoading } = useMutation(
    ({ dateFilter, pageIndex, pageSize }: Props) =>
      reportsService.exportOverallReports(
        dateFilter.startDate,
        dateFilter.endDate,
        pageIndex + 1,
        pageSize
      )
  );

  useEffect(() => {
    if (isError) {
      toast({
        title: "Failed to export report",
        description: (error as any).response.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [isError, toast, error]);

  useEffect(() => {
    if (isSuccess) {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const headerLine = data.headers["content-disposition"];
      const startFileNameIndex = headerLine?.indexOf('"') + 1;
      const endFileNameIndex = headerLine?.lastIndexOf('"');
      const fileName = headerLine?.substring(
        startFileNameIndex,
        endFileNameIndex
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [isSuccess, data]);

  return { exportReports: mutate, isExporting: isLoading };
};

export default useExportOverallReports;
