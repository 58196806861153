import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Pause from "../svg/Pause";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useReactMediaRecorder } from "react-media-recorder";
import { v4 as uuidv4 } from "uuid";
import { useRecorder } from "../../context/useRecorder";
import fileUploadService from "../../services/fileUploadService";
import { DeleteIcon } from "@chakra-ui/icons";
import Mic from "../svg/Mic";
import Play from "../svg/Play";

const Recorder = () => {
  const toast = useToast();
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    error,
  } = useReactMediaRecorder({
    audio: true,
    onStop(blobUrl, blob) {
      const file = new File([blob], `VN-${uuidv4()}.mp3`, {
        type: blob.type,
        lastModified: Date.now(),
      });
      setAudio(file);
    },
  });
  const audioRef = useRef<HTMLAudioElement>(null);
  const intervalRef = useRef<NodeJS.Timeout>();
  const rafRef = useRef<number>();
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState<File | null>(null);
  const [timer, setTimer] = useState(0);
  const [seeker, setSeeker] = useState(0);
  const { recording, setRecording } = useRecorder();
  const [uploading, setUploading] = useState(false);

  if (error === "permission_denied") {
    toast({
      title: "Microphone permission required",
      description: "User denied microphone",
      status: "error",
      isClosable: false,
      position: "top-right",
      duration: 999999,
    });
  }

  const calculateTime = (secs?: number) => {
    if (secs && secs !== Infinity) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    }
  };

  useEffect(() => {
    if (status === "recording") {
      intervalRef.current = setInterval(() => setTimer(prev => prev + 1), 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [status]);

  const uploadRecording = () => {
    if (!audio) return;
    const blob = URL.createObjectURL(audio);
    setUploading(true);
    fileUploadService
      .uploadFile(audio)
      .then(res => {
        setRecording({ ...res.data.body, blob });
        clearBlobUrl();
        clearInterval(intervalRef.current);
        setTimer(0);
      })
      .catch(err => {
        if (err.code !== "ERR_CANCELED") {
          toast({
            title: "Upload Failed",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          console.error(err.response);
        }
      })
      .finally(() => setUploading(false));
  };

  const whilePlaying = () => {
    if (audioRef.current) setSeeker(Math.floor(audioRef.current.currentTime));
    rafRef.current = requestAnimationFrame(whilePlaying);
  };

  if (audioRef.current)
    audioRef.current.addEventListener("ended", function () {
      if (audioRef.current) audioRef.current.currentTime = 0;
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
      setPlaying(false);
    });

  if (status === "idle")
    return (
      <Button
        onClick={() => (recording ? setRecording(null) : startRecording())}
        p={1}
        fontSize="xs"
        size="sm"
        variant="secondary"
        leftIcon={recording ? <DeleteIcon /> : <Mic boxSize="14px" />}
      >
        {recording ? "Remove Message" : "Voice Message"}
      </Button>
    );

  return (
    <Flex align="center" bg="#D8F5F0" borderRadius="10px" w="full" minH="66px">
      {mediaBlobUrl && <audio src={mediaBlobUrl} ref={audioRef}></audio>}
      <Flex
        gap={2}
        justify="space-between"
        align="center"
        w="full"
        p={2}
        fontSize="sm"
        borderRadius="5px"
        bg="#D8F5F0"
      >
        {status !== "stopped" ? (
          <Box>
            <Text color="#424D44" mb={2}>
              {timer === 0 ? "0:00" : calculateTime(timer)}
            </Text>
            <Text color="primary.800">Recording...</Text>
          </Box>
        ) : !playing ? (
          <IconButton
            bg="#9EE6DA"
            _hover={{}}
            _active={{}}
            _focus={{}}
            color="#0C2924"
            borderRadius="full"
            aria-label="play recording"
            icon={<Play />}
            fontSize="20px"
            size="sm"
            onClick={() => {
              setPlaying(true);
              audioRef.current?.play();
              requestAnimationFrame(whilePlaying);
            }}
          />
        ) : (
          <IconButton
            bg="#9EE6DA"
            _hover={{}}
            _active={{}}
            _focus={{}}
            color="#0C2924"
            borderRadius="full"
            aria-label="pause recording"
            icon={<Pause />}
            fontSize="20px"
            size="sm"
            onClick={() => {
              setPlaying(false);
              audioRef.current?.pause();
              if (rafRef.current) cancelAnimationFrame(rafRef.current);
            }}
          />
        )}
        {mediaBlobUrl && audioRef.current && (
          <>
            <Text color="#424D44">
              {audioRef.current.currentTime
                ? calculateTime(audioRef.current.currentTime)
                : calculateTime(audioRef.current.duration)}
            </Text>
            <Slider
              flex="2"
              min={0}
              defaultValue={0}
              max={Math.floor(audioRef.current.duration)}
              value={seeker}
              onChange={val => {
                if (audioRef.current) {
                  audioRef.current.currentTime = val;
                  setSeeker(val);
                }
              }}
            >
              <SliderTrack bg="white">
                <SliderFilledTrack bg="primary.800" />
              </SliderTrack>
            </Slider>
          </>
        )}
        {status === "stopped" ? (
          <Button
            variant="dark"
            size="sm"
            borderRadius="15px"
            p={3}
            onClick={uploadRecording}
            isLoading={uploading}
            isDisabled={uploading}
          >
            Attach Recording
          </Button>
        ) : (
          <Button
            variant="dark"
            size="sm"
            borderRadius="15px"
            p={3}
            onClick={() => {
              stopRecording();
              clearInterval(intervalRef.current);
              setTimer(0);
            }}
          >
            Stop Recording
          </Button>
        )}
      </Flex>
      <IconButton
        boxSize="max-content"
        variant="link"
        borderRadius="full"
        aria-label="delete recording"
        color="#FF0000"
        size="sm"
        _focus={{}}
        _active={{}}
        icon={<AiOutlineCloseCircle fontSize="15px" />}
        onClick={() => {
          clearBlobUrl();
          clearInterval(intervalRef.current);
          setTimer(0);
        }}
      />
    </Flex>
  );
};

export default Recorder;
