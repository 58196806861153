const faq = [
  {
    question: "What can I report on the FlagIt App",
    answer:
      "Corruption by public service institutions or individuals | Malpractices by Security Services or Para-military organizations such as the Nigerian Police and Federal Road Safety Officials | Public officials’ Highhandedness | Abuse of power | Electoral Malpractices | All forms of Sexual and gender-based violence | Domestic Violence  | Cyberbullying | Sextortion | Sex-grooming | Harassment | Success Stories-positive feedback and commendation for public officers etc.",
  },
  {
    question: "Where can I download the FlagIt App",
    answer:
      "You can download the FlagIt App on Google Play Store or iOS App Store.",
  },
  {
    question: "What documents do I need to Sign up for the FlagIt App",
    answer:
      "You only need an email and a valid phone number to sign up on the FlagIt App.",
  },
  {
    question: "What steps can I follow to make a report on the FlagIt App",
    answer: `<p class="MsoListParagraphCxSpMiddle">
          <b>
            <span>Sign up: </span>
          </b>
          <span>input your contact details</span>
        </p>

        <p class="MsoListParagraphCxSpMiddle">
          <b>
            <span>Complete the form:</span>
          </b>
          <span>
            Fill in information about your experience as accurately and detailed
            as possible using the provided form
          </span>
        </p>

        <p class="MsoListParagraphCxSpMiddle">
          <b>
            <span>Attachments:</span>
          </b>
          <span>
            Attach any evidence of your complaint (video, audio recording, pictures) within the size limit provided
            (additional evidence can be sent to:
          </span>
          <a class="MsoHyperlink" href="mailto:flagit@akinfadeyifoundation.org">
            <span>flagIt@akinfadeyifoundation.org</span>
          </a>
          )
        </p>
        <p class="MsoListParagraphCxSpMiddle">
          <b>
            <span>Preview:</span>
          </b>
          <span>Confirm the information and evidence submitted</span>
        </p>
        <p class="MsoListParagraphCxSpMiddle">
          <b>
            <span>Submit:</span>
          </b>
          <span>
            Click submit to send your complaint to the selected
            agency/institution
          </span>
        </p>
        <p class="MsoListParagraphCxSpMiddle">
          <b>
            <span>Follow Up:</span>
          </b>
          <span>
            Your submitted report and case number can be found in the report
            section of the app located on the homepage.
          </span>
        </p>`,
  },
  {
    question:
      "Is it only corruption by Government officials that I can report on the FlagIt App",
    answer:
      "No, you can also make a report on any incident of corruption, electoral malpractices, human trafficking, and all forms of human rights abuses involving individuals, groups or organizations.",
  },
  {
    question: "How can I make a report against individuals",
    answer:
      "Go to the home page of the FlagIt App and choose any of the agencies or issues that best relate to the case or individual you want to report. If the agency/issue is not listed, select other and fill in the form",
  },
  {
    question: "Is the FlagIt App owned by the Government",
    answer:
      "No, it is not. However, we work with many Government agencies to resolve complaints within their purview and area of functionalities.",
  },
  {
    question:
      "How can I submit fresh evidence to support my report after I have submitted my report",
    answer: `<p class="MsoListParagraphCxSpMiddle">
            <span>Send additional details as an email to: </span>
            <a class="MsoHyperlink" href="mailto:flagit@akinfadeyifoundation.org">
              <span>flagIt@akinfadeyifoundation.org</span>
            </a>
            <span>,</span>
          </p>
          <p class="MsoListParagraphCxSpMiddle">
            <span>
              with your unique Case Number.
              Your Case Number for every
              submitted complaint can be found in the report section of the app
              located on the homepage.
            </span>
          </p>`,
  },
  {
    question: "What is a unique case tracking ID",
    answer:
      "These are a set of alphabets and numbers that are generated after you successfully submit a report on the FlagIt App.",
  },
  {
    question: "Where can I find my unique Case tracking ID",
    answer:
      "Go to the FlagIt App, click reports and all your earlier submitted reports will be displayed. Click on the case ID to track your report, or use it as the subject of your email to send us additional details to support your already submitted report.",
  },
  {
    question: "How long will it take for a report to be resolved",
    answer:
      "The duration and resolution of cases are dependent on the complexity of the case and the partnering agency. However, you can track the progress of your report by clicking on the unique case number ID on the FlagIt App.",
  },
];

export default faq;
