import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import NoData from "../svg/NoData";
import Heading from "./Heading";

interface EmptyStateProps extends FlexProps {
  isAgency?: boolean;
  isAction?: boolean;
  isStory?: boolean;
  isFilter?: boolean;
  isAgencies?: boolean;
}

const EmptyState = ({
  isAction,
  isAgency,
  isStory,
  isFilter,
  isAgencies,
  ...props
}: EmptyStateProps) => {
  return (
    <Flex align="center" justify="center" {...props}>
      <Box textAlign="center">
        <NoData mb={10} boxSize="200px" />
        <Heading>
          {isAction
            ? "No actions"
            : isStory
            ? "No stories"
            : isAgencies
            ? "No Agencies"
            : "No cases"}
        </Heading>
        <Text fontWeight="semibold" my={1}>
          {isAgencies
            ? "No agencies have been created"
            : isAgency
            ? "Agency cases submitted will show up here"
            : isAction
            ? "Actions taken on report will show up here"
            : isStory
            ? isFilter
              ? "No stories for with status"
              : "Success stories will show up here"
            : isFilter
            ? "No cases found"
            : "Cases in progress will show up here"}
        </Text>
      </Box>
    </Flex>
  );
};

export default EmptyState;
