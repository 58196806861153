import { Heading as ChakraHeading, HeadingProps } from "@chakra-ui/react";

const Heading = ({ children, ...props }: HeadingProps) => {
  return (
    <ChakraHeading size="lg" color="#0A230E" {...props}>
      {children}
    </ChakraHeading>
  );
};

export default Heading;
