import {
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ErrorIcon from "../svg/ErrorIcon";

interface FormPhoneNumberProps extends InputProps {
  form: any;
  label: string;
  id: string;
}

const FormPhoneNumber = ({ form, label, id }: FormPhoneNumberProps) => {
  return (
    <FormControl my={4} isInvalid={form.formState.errors[id]}>
      <Flex align="center" justify="space-between">
        <Flex gap={2} wrap="wrap" mb={2} align="baseline">
          <FormLabel
            m={0}
            fontWeight="semibold"
            fontSize="sm"
            color="textSecondary"
            htmlFor={id}
          >
            {label}
          </FormLabel>
        </Flex>
      </Flex>
      <InputGroup>
        <Controller
          control={form.control}
          name={id}
          rules={{
            required: "Phone Number is required",
            minLength: {
              value: 13,
              message: "Phone Number must be at least 11 characters",
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <PhoneInput
              {...field}
              inputProps={{
                ref,
                required: true,
                autoFocus: true,
              }}
              country="ng"
              countryCodeEditable={false}
              specialLabel=""
              inputStyle={{
                border: "none",
                height: "40px",
                width: "100%",
                padding: 0,
                color: "inherit",
              }}
              containerStyle={{
                border: "1px solid #D4D4D4",
                fontFamily: "inherit",
                fontSize: "inherit",
                display: "flex",
                flexDirection: "row-reverse",
                gap: "10px",
                borderRadius: "0.375rem",
              }}
              buttonStyle={{ position: "static" }}
            />
          )}
        />
        <InputRightElement>
          <FormErrorIcon as={ErrorIcon} fontSize="20px" />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormPhoneNumber;
