import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Flex,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect } from "react";
import { useMutation } from "react-query";
import getUserRole from "../../helpers/getUserRole";
import { useAuth } from "../../context/useAuth";
import { SuccessStory } from "../../interfaces/SuccessStory";
import successStoriesService from "../../services/successStoriesService";

interface ViewStoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  story?: SuccessStory;
  fetchData: any;
}
const ViewStoryModal = ({
  isOpen,
  onClose,
  story,
  fetchData,
}: ViewStoryModalProps) => {
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);
  const toast = useToast();
  const approveMutation = useMutation((storyId: number) => {
    return successStoriesService.approveSuccessStory(storyId);
  });

  const disapproveMutation = useMutation((storyId: number) => {
    return successStoriesService.disapproveSuccessStory(storyId);
  });

  useEffect(() => {
    if (approveMutation.isError) {
      toast({
        title: "Failed to approve story",
        description: (approveMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [approveMutation.isError, toast, approveMutation.error]);

  useEffect(() => {
    if (disapproveMutation.isError) {
      toast({
        title: "Failed to disapprove story",
        description: (disapproveMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [disapproveMutation.isError, toast, disapproveMutation.error]);

  useEffect(() => {
    if (approveMutation.isSuccess) {
      fetchData({ pageIndex: 0, pageSize: 9 });
      onClose();
      toast({
        title: "Story has been approved",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [approveMutation.isSuccess, toast, onClose, fetchData]);

  useEffect(() => {
    if (disapproveMutation.isSuccess) {
      fetchData({ pageIndex: 0, pageSize: 9 });
      onClose();
      toast({
        title: "Story has been disapproved",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [disapproveMutation.isSuccess, toast, onClose, fetchData]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md" fontWeight="bold" color="textSecondary">
          SUCCESS STORY
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={6}>
            <Text mb={2} fontWeight="bold" color="textSecondary">
              {story?.title}
            </Text>
            <Text fontSize="xs">
              {moment(story?.date).format("Mo, MMMM")} -{" "}
              {story?.incidenceType?.name}
            </Text>
          </Box>
          <Text mb={2} fontWeight="semibold" color="textSecondary">
            Story
          </Text>
          <Text fontSize="xs">{story?.description}</Text>
          <Flex gap={4} justify="space-between">
            {!isOverallAdmin ? null : story?.aprovalStatus ? (
              <Button
                isLoading={disapproveMutation.isLoading}
                isDisabled={disapproveMutation.isLoading}
                onClick={() => disapproveMutation.mutate(Number(story?.id))}
                variant="outline"
                w="200px"
                my={4}
                p={2}
              >
                Disapprove
              </Button>
            ) : (
              <Button
                isLoading={approveMutation.isLoading}
                isDisabled={approveMutation.isLoading}
                onClick={() => approveMutation.mutate(Number(story?.id))}
                variant="outline"
                w="200px"
                my={4}
                p={2}
              >
                Approve
              </Button>
            )}
            <Button w="200px" my={4} p={2} onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewStoryModal;
