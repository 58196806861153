const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : undefined;
};
export const getYoutubeURL = (url: string) => {
  return getYoutubeVideoId(url)
    ? `https://www.youtube.com/embed/${getYoutubeVideoId(url)}`
    : undefined;
};

export const getYoutubeThumbnail = (url: string) => {
  return getYoutubeVideoId(url)
    ? `http://img.youtube.com/vi/${getYoutubeVideoId(url)}/hqdefault.jpg`
    : undefined;
};
