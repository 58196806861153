import { useToast } from "@chakra-ui/react";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber
} from "firebase/auth";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { auth as firebaseAuth } from "../firebase";
import formatFirebaseAuthError from "../helpers/formatFirebaseAuthError";
import getUserRole from "../helpers/getUserRole";
import { UserDTO, UserProfile } from "../interfaces/User";
import agencyUsersService from "../services/agencyUsersService";
import authService from "../services/authService";
import usersService from "../services/usersService";

export interface IAuthContext {
  loading: boolean;
  user: UserProfile | null;
  userData: UserDTO | null;
  setUserData: React.Dispatch<React.SetStateAction<UserDTO | null>>;
  login: (email: string, password: string) => void;
  register: (data: UserDTO) => void;
  getUserInfo: (redirect: boolean) => void;
  refreshToken: (refreshToken: any) => void;
  confirmPhoneNumber: (code: string) => void;
  verifyPhoneNumber: (userData: UserDTO, verifier: RecaptchaVerifier) => void;
  resendEmailVerification: () => void;
  logout: () => void;
}

const AuthContext = React.createContext<IAuthContext | null>(null);
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const getToken = () => localStorage.getItem("token");
  const getUser = () => {
    const user = localStorage.getItem("user");
    if (!user) return null;
    const userData = JSON.parse(user);
    return userData;
  };
  const [user, setUser] = useState<UserProfile | null>(getUser());
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserDTO | null>(null);
  const [result, setResult] = useState<ConfirmationResult | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { from: string };
  const toast = useToast();
  const queryClient = useQueryClient();

  const register = (user: UserDTO) => {
    setLoading(true);
    authService
      .register(user)
      .then(() => {
        setUserData(user);
        navigate("/verify-email");
        setLoading(false);
      })
      .catch(err => {
        console.error(err.response);
        toast({
          title: "User registration failed",
          description: err.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
      });
  };

  const verifyPhoneNumber = (
    userData: UserDTO,
    verifier: RecaptchaVerifier
  ) => {
    if (!userData || !verifier) return;
    setLoading(true);

    signInWithPhoneNumber(firebaseAuth, userData.phoneNumber, verifier)
      .then(confirmationResult => {
        setUserData(userData);
        navigate("/verify");
        setResult(confirmationResult);
        setLoading(false);
        (verifier as any).recaptcha.reset();
      })
      .catch(err => {
        console.error(err);
        toast({
          title: "Phone number verification failed",
          description: formatFirebaseAuthError(err),
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
        (verifier as any).recaptcha.reset();
      });
  };

  const resendEmailVerification = () => {
    if (!userData) return;
    setLoading(true);

    authService
      .resendEmailVerification(userData.email)
      .then(() => {
        toast({
          title: "Email verification sent",
          description: "Please check your mail",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        toast({
          title: "Failed to send verification email",
          description: "Please try again later",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
      });
  };

  const confirmPhoneNumber = (code: string) => {
    if (!result) {
      toast({
        title: "Phone number verification failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setLoading(true);

    result
      .confirm(code)
      .then(() => {
        if (userData) register(userData);
      })
      .catch(err => {
        console.error(err);
        toast({
          title: "Phone number verification failed",
          description: formatFirebaseAuthError(err),
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
      });
  };

  const login = (email: string, password: string) => {
    setLoading(true);

    authService
      .login(email, password)
      .then(res => {
        localStorage.setItem("token", JSON.stringify(res.data.body));
        getUserInfo(true);
      })
      .catch(err => {
        console.error(err.response);
        toast({
          title: "Login failed",
          description: err.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        if (err.response?.data?.message === "Email Not verified") {
          setUserData({
            firstName: "",
            lastName: "",
            email: email,
            phoneNumber: "",
            password: "",
            confirmPassword: "",
          });
          navigate("/verify-email");
        }
        localStorage.removeItem("token");
        setLoading(false);
      });
  };

  const getUserInfo = (redirect: boolean) => {
    usersService
      .getUserInfo()
      .then(res => {
        const user = getUser();
        const token = getToken();
        if (token) {
          const parsedToken = JSON.parse(token);
          const decodedToken: any = jwt_decode(parsedToken.firebaseToken);
          setUser(prev => ({ ...prev, ...res.data.body, ...decodedToken }));
          localStorage.setItem(
            "user",
            JSON.stringify({ ...user, ...res.data.body, ...decodedToken })
          );
          const { isAgencyUser, isAgencyAdmin } = getUserRole(
            decodedToken.roles
          );
          if (!!isAgencyUser || !!isAgencyAdmin) {
            getAgencyUserInfo(res.data.body.id, redirect);
          } else {
            if (redirect) {
              if (state?.from) navigate(state.from, { replace: true });
              else navigate("/", { replace: true });
            }
            setLoading(false);
          }
        }
      })
      .catch(err => {
        console.error(err);
        toast({
          title: "Login failed",
          description: err.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setUser(null);
        localStorage.clear();
        setLoading(false);
      });
  };

  const getAgencyUserInfo = (userId: number, redirect?: boolean) => {
    agencyUsersService
      .getAgencyUser(userId)
      .then(res => {
        const agency = res.data.body.agency;
        const user = getUser();
        setUser(prev => ({ ...prev, agency }));
        localStorage.setItem("user", JSON.stringify({ ...user, agency }));
        if (redirect) {
          if (state?.from) navigate(state.from, { replace: true });
          else navigate("/", { replace: true });
        }
        setLoading(false);
      })
      .catch(err => {
        console.error(err.response);
        toast({
          title: "Login failed",
          description: err.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setUser(null);
        localStorage.clear();
        setLoading(false);
      });
  };

  const refreshToken = (tokenData: any) => {
    authService
      .refreshToken(tokenData)
      .then(res => {
        localStorage.setItem("token", JSON.stringify(res.data.body));
        getUserInfo(false);
      })
      .catch(() => {
        navigate("/login", { state: { from: location.pathname } });
        localStorage.clear();
      });
  };

  const logout = () => {
    navigate("/");
    setUser(null);
    queryClient.clear();
    authService
      .logout()
      .catch(err => console.error(err.response))
      .then(() => localStorage.clear());
  };

  const auth: IAuthContext = {
    login,
    register,
    getUserInfo,
    logout,
    user,
    userData,
    setUserData,
    loading,
    refreshToken,
    verifyPhoneNumber,
    confirmPhoneNumber,
    resendEmailVerification,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
