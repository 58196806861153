import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RecaptchaVerifier } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import VerificationInput from "react-verification-input";
import * as yup from "yup";
import { useAuth } from "../../context/useAuth";
import { auth as firebaseAuth } from "../../firebase";
import { phoneRegExp } from "../../utils/regex";
import ErrorIcon from "../svg/ErrorIcon";
import "./VerifyForm.css";

const VerifyForm = () => {
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(60);
  const auth = useAuth();
  const interval = useRef<NodeJS.Timeout | null>(null);
  const verifier = useRef<RecaptchaVerifier | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { onToggle, onClose, isOpen } = useDisclosure();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<{ phoneNumber: string }>({
    resolver: yupResolver(
      yup
        .object({
          phoneNumber: yup
            .string()
            .required("Phone Number is required")
            .matches(phoneRegExp, "Phone number is not valid"),
        })
        .required()
    ),
  });

  useEffect(() => {
    setValue("phoneNumber", `${auth?.userData?.phoneNumber}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code.length === 6) {
      auth?.confirmPhoneNumber(code);
      setCode("");
    }
  }, [code, auth]);

  useEffect(() => {
    interval.current = setInterval(() => {
      setTimer(prev => prev - 1);
    }, 1000);
    if (timer === 0 && interval.current) clearInterval(interval.current);
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [timer]);

  const resendVerification = () => {
    if (verifier.current && auth?.userData) {
      buttonRef.current?.click();
      auth?.verifyPhoneNumber(auth.userData, verifier.current);
      setTimer(60);
    }
  };

  function onSubmit({ phoneNumber }: { phoneNumber: string }) {
    auth?.setUserData(prev => {
      if (prev) return { ...prev, phoneNumber: `+${phoneNumber}` };
      else return prev;
    });
    onClose();
  }

  useEffect(() => {
    try {
      verifier.current = new RecaptchaVerifier(
        "resend-button",
        { size: "invisible" },
        firebaseAuth
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Box maxW="480px">
      {auth?.loading ? (
        <Flex align="center" justify="center" minH="270px">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={onClose}
            closeOnBlur={false}
            placement="right"
          >
            <PopoverTrigger>
              <Link
                onClick={onToggle}
                display="inline-block"
                color="secondary.500"
                my={2}
              >
                Edit phone number
              </Link>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Enter phone number</PopoverHeader>
              <PopoverBody>
                <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                  <Flex gap={4} direction="column">
                    <FormControl isInvalid={!!errors.phoneNumber}>
                      <FormLabel color="#0A230E" htmlFor="phoneNumber">
                        Phone Number
                      </FormLabel>
                      <InputGroup>
                        <Controller
                          control={control}
                          name="phoneNumber"
                          render={({ field: { ref, ...field } }) => (
                            <PhoneInput
                              {...field}
                              inputProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              country="ng"
                              countryCodeEditable={false}
                              specialLabel=""
                              inputStyle={{
                                border: "none",
                                height: "46px",
                                width: "100%",
                                padding: 0,
                                color: "inherit",
                              }}
                              containerStyle={{
                                border: "1px solid #D4D4D4",
                                fontFamily: "inherit",
                                fontSize: "inherit",
                                display: "flex",
                                flexDirection: "row-reverse",
                                gap: "10px",
                                borderRadius: "0.375rem",
                              }}
                              buttonStyle={{ position: "static" }}
                            />
                          )}
                        />

                        <InputRightElement h="full">
                          <Flex align="center" h="full">
                            <FormErrorIcon as={ErrorIcon} fontSize="20px" />
                          </Flex>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.phoneNumber?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      alignSelf="flex-end"
                      variant="outline"
                      isLoading={auth?.loading}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Flex>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Flex justify="center" mb={10}>
            <VerificationInput
              length={6}
              value={code}
              onChange={setCode}
              validChars="0-9"
              placeholder=""
              inputProps={{ autoComplete: "one-time-code" }}
              classNames={{
                container: "container",
                character: "character",
                characterInactive: "character--inactive",
                characterSelected: "character--selected",
              }}
            />
          </Flex>
          <Text my={6}>
            If you do not receive a message in{" "}
            <Text as="span" color="textSecondary">
              {`${timer}s`}
            </Text>
            , click the button below to resend
          </Text>
          <Button
            onClick={resendVerification}
            // disabled={timer > 0}
            h="50px"
            w="full"
            my={6}
          >
            Resend
          </Button>
        </>
      )}
      <Button
        h={0}
        w={0}
        id="resend-button"
        visibility="hidden"
        ref={buttonRef}
      >
        Recaptcha
      </Button>
    </Box>
  );
};

export default VerifyForm;
