import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import theme from "./theme";
import { AuthProvider } from "./context/useAuth";
import history from "./helpers/history";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme} resetCSS>
      <HistoryRouter history={history}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </HistoryRouter>
    </ChakraProvider>
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
