import { IconProps } from "@chakra-ui/react";
import Agencies from "../components/svg/Agencies";
import AgencyUsers from "../components/svg/AgencyUsers";
import AssignedCases from "../components/svg/AssignedCases";
import Dashboard from "../components/svg/Dashboard";
import Library from "../components/svg/Library";
import Location from "../components/svg/Location";
import OverallCases from "../components/svg/OverallCases";
import ReportCase from "../components/svg/ReportCase";
import Reports from "../components/svg/Reports";
import SuccessStories from "../components/svg/SuccessStories";
import UserDevices from "../components/svg/UserDevices";

export interface Navigation {
  label: string;
  route: string;
  icon: (props: IconProps) => JSX.Element;
}

export const overallAdminNavigation: Navigation[] = [
  {
    label: "Dashboard",
    route: "",
    icon: Dashboard,
  },
  // {
  //   label: "Overall Cases",
  //   route: "overall-cases",
  //   icon: OverallCases,
  // },
  {
    label: "Users",
    route: "users",
    icon: AgencyUsers,
  },
  {
    label: "User Devices",
    route: "user-devices",
    icon: UserDevices,
  },
  {
    label: "Locations",
    route: "locations",
    icon: Location,
  },
  {
    label: "Agencies",
    route: "agencies",
    icon: Agencies,
  },
  {
    label: "Library",
    route: "library",
    icon: Library,
  },
  {
    label: "Success Stories",
    route: "success-stories",
    icon: SuccessStories,
  },
];

export const agencyAdminNavigation: Navigation[] = [
  {
    label: "Dashboard",
    route: "",
    icon: Dashboard,
  },
  {
    label: "Assigned Cases",
    route: "assigned-cases",
    icon: AssignedCases,
  },
  {
    label: "Agency Users",
    route: "agency-users",
    icon: AgencyUsers,
  },
  {
    label: "Library",
    route: "library",
    icon: Library,
  },
  {
    label: "Success Stories",
    route: "success-stories",
    icon: SuccessStories,
  },
];

export const agencyUserNavigation: Navigation[] = [
  {
    label: "Dashboard",
    route: "",
    icon: Dashboard,
  },
  {
    label: "Assigned Cases",
    route: "assigned-cases",
    icon: AssignedCases,
  },
  {
    label: "Library",
    route: "library",
    icon: Library,
  },
  {
    label: "Success Stories",
    route: "success-stories",
    icon: SuccessStories,
  },
];

export const userNavigation: Navigation[] = [
  {
    label: "Dashboard",
    route: "",
    icon: Dashboard,
  },
  {
    label: "My Cases",
    route: "my-cases",
    icon: Reports,
  },
  {
    label: "Library",
    route: "library",
    icon: Library,
  },
  {
    label: "Success Stories",
    route: "success-stories",
    icon: SuccessStories,
  },
];
