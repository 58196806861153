import { useState } from "react";
import {
  Box,
  BoxProps,
  Divider,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Flex,
  Spinner,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import reportsService from "../../services/reportsService";
import Tag from "../common/Tag";
import Link from "../svg/Link";
import moment from "moment";
import { ReportActionRes } from "../../interfaces/ReportAction";
import AttachmentsModal from "./AttachmentsModal";
import MoreModal from "./MoreModal";
import { FileStore } from "../../interfaces/FileUpload";
import EmptyState from "../common/EmptyState";

interface AppliedActionsProps extends BoxProps {
  reportId: number;
}

const AppliedActions = ({ reportId, ...props }: AppliedActionsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenMore,
    onOpen: onOpenMore,
    onClose: onCloseMore,
  } = useDisclosure();
  const { data: response, isLoading } = useQuery(
    ["actions", reportId],
    () => reportsService.getActionLog(reportId),
    { staleTime: 60000 }
  );

  const [attachments, setAttachments] = useState<FileStore[]>([]);
  const [log, setLog] = useState<ReportActionRes | null>(null);

  const openModal = (files?: FileStore[]) => {
    if (files) setAttachments(files);
    onOpen();
  };

  const readMore = (log: ReportActionRes) => {
    if (log) setLog(log);
    onOpenMore();
  };

  return (
    <Box
      border="1px solid #D4D4D4"
      borderRadius="10px"
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      {...props}
      p={4}
    >
      <Text color="textSecondary" my={4} fontSize="md">
        Applied Actions
      </Text>
      <Divider bg="#D4D4D4" my={4} />
      {isLoading ? (
        <Flex my={6} justify="center" align="center" h="100%" minH="150px">
          <Spinner />
        </Flex>
      ) : !!response?.data.body?.length ? (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr bg="#DADADA21">
                <Th fontSize="xs" color="text">
                  Date
                </Th>
                <Th fontSize="xs" color="text">
                  User
                </Th>
                <Th fontSize="xs" color="text">
                  Action
                </Th>
                <Th fontSize="xs" color="text">
                  Note
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {response?.data.body.map((log: ReportActionRes) => (
                <Tr key={log.id}>
                  <Td fontSize="xs">
                    {moment(log.modifiedDate).format("DD-MM-YYYY")}
                  </Td>
                  <Td fontSize="xs">{log.createdBy}</Td>
                  <Td fontSize="xs">
                    <Tag variant={log.reportStatusName}>
                      {log.reportStatusName}
                    </Tag>
                  </Td>
                  <Td fontSize="xs">
                    <Flex align="center">
                      {!!log.reportActionAttachments?.length && (
                        <IconButton
                          variant="link"
                          size="sm"
                          icon={<Link fontSize="24px" />}
                          aria-label="link"
                          onClick={() => openModal(log.reportActionAttachments)}
                        />
                      )}
                      {log.note.length < 40 ? (
                        log.note
                      ) : (
                        <>
                          {`${log.note.substring(0, 40)}...`}
                          <Button
                            color="secondary.500"
                            variant="link"
                            fontSize="xs"
                            onClick={() => readMore(log)}
                          >
                            Read more
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState h="500px" isAction={true} />
      )}

      <MoreModal isOpen={isOpenMore} onClose={onCloseMore} log={log} />
      <AttachmentsModal
        isOpen={isOpen}
        onClose={onClose}
        attachments={attachments}
      />
    </Box>
  );
};

export default AppliedActions;
