import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

interface FormRadioProps extends RadioGroupProps {
  form: any;
  label: string;
  id: string;
  options?: any;
  children: React.ReactNode;
}

const FormRadio = ({
  form,
  label,
  id,
  options,
  children,
  ...props
}: FormRadioProps) => {
  return (
    <FormControl as="fieldset" my={4} isInvalid={form.formState.errors[id]}>
      <FormLabel
        as="legend"
        fontWeight="semibold"
        fontSize="sm"
        color="textSecondary"
      >
        {label}
      </FormLabel>
      <Controller
        name={id}
        control={form.control}
        rules={options}
        render={({ field }) => (
          <RadioGroup {...field} {...props}>
            <Stack spacing={4} direction="row">
              {children}
            </Stack>
          </RadioGroup>
        )}
      />

      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormRadio;
