import { Icon, IconProps } from "@chakra-ui/react";

const Export = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="currentcolor" {...props}>
      <path d="M11.4449 16.6877H6.55486C2.87236 16.6877 1.29736 15.1127 1.29736 11.4302V11.3327C1.29736 8.0027 2.60986 6.3977 5.54986 6.1202C5.84986 6.0977 6.13486 6.3227 6.16486 6.6302C6.19486 6.9377 5.96986 7.2152 5.65486 7.2452C3.29986 7.4627 2.42236 8.5727 2.42236 11.3402V11.4377C2.42236 14.4902 3.50236 15.5702 6.55486 15.5702H11.4449C14.4974 15.5702 15.5774 14.4902 15.5774 11.4377V11.3402C15.5774 8.5577 14.6849 7.4477 12.2849 7.2452C11.9774 7.2152 11.7449 6.9452 11.7749 6.6377C11.8049 6.3302 12.0674 6.0977 12.3824 6.1277C15.3674 6.3827 16.7024 7.9952 16.7024 11.3477V11.4452C16.7024 15.1127 15.1274 16.6877 11.4449 16.6877Z" />
      <path d="M9 11.8123C8.6925 11.8123 8.4375 11.5573 8.4375 11.2498V2.71484C8.4375 2.40734 8.6925 2.15234 9 2.15234C9.3075 2.15234 9.5625 2.40734 9.5625 2.71484V11.2498C9.5625 11.5573 9.3075 11.8123 9 11.8123Z" />
      <path d="M11.5124 4.95008C11.3699 4.95008 11.2274 4.89758 11.1149 4.78508L8.99988 2.67008L6.88488 4.78508C6.66738 5.00258 6.30738 5.00258 6.08988 4.78508C5.87238 4.56758 5.87238 4.20758 6.08988 3.99008L8.60238 1.47758C8.81988 1.26008 9.17988 1.26008 9.39738 1.47758L11.9099 3.99008C12.1274 4.20758 12.1274 4.56758 11.9099 4.78508C11.8049 4.89758 11.6549 4.95008 11.5124 4.95008Z" />
    </Icon>
  );
};

export default Export;
