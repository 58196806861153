import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useUploads } from "../../context/useUploads";
import fileUploadService from "../../services/fileUploadService";

interface UploadProps extends BoxProps {
  file: File;
}

const Upload = ({ file, ...props }: UploadProps) => {
  const { setUploads, setFiles } = useUploads();
  const toast = useToast();
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [fileId, setFileId] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const timeStarted = useMemo(() => new Date().getTime(), []);
  const controller = useMemo(() => new AbortController(), []);

  useEffect(() => {
    const timeController = setInterval(() => {
      const timeElapsed = Math.round(new Date().getTime() - timeStarted);
      const uploadSpeed = Math.round(loaded / (timeElapsed / 1000));
      const difference = total - loaded;
      setTimeRemaining(Math.round(difference / uploadSpeed));
    }, 100);

    if (progress === 100) clearInterval(timeController);

    return () => clearInterval(timeController);
  }, [timeRemaining, loaded, total, progress, timeStarted]);

  useEffect(() => {
    const blob = URL.createObjectURL(file);

    fileUploadService
      .uploadFile(file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: data => {
          setLoaded(data.loaded);
          setTotal(data.total);
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
        signal: controller.signal,
      })
      .then(res => {
        setFileId(res.data.body.id);
        setUploads(prev => {
          const updated = [...prev];
          updated.push({ ...res.data.body, blob });
          return updated;
        });
      })
      .catch(err => {
        if (err.code !== "ERR_CANCELED") {
          toast({
            title: "Upload Failed",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          console.error(err.response);
        }
      });

    return () => URL.revokeObjectURL(blob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFile = () => {
    if (progress < 100) controller.abort();
    setUploads(prev => prev.filter(f => f.id !== fileId));
    setFiles(prev => prev.filter(f => f.lastModified !== file.lastModified));
  };

  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
  const sizeInKB = (file.size / 1024).toFixed(2);

  if (progress === 100) return <></>;

  return (
    <Box
      p={2}
      bg="#D8F5F05C"
      border="1px solid #FF00001A"
      borderRadius="5px"
      my={4}
      {...props}
    >
      <Flex my={2} align="flex-start" justify="space-between">
        <Box>
          <Text fontSize="sm" fontWeight="semibold" color="textSecondary">
            Uploading
            <Text color="text" fontSize="x-small" as="span">
              {" "}
              (
              {file.type.startsWith("audio")
                ? "Audio"
                : file.type.startsWith("video")
                ? "Video"
                : file.type.split("/")[1].toUpperCase()}{" "}
              file)
            </Text>
          </Text>
        </Box>
        <IconButton
          variant="link"
          borderRadius="full"
          aria-label="Cancel upload"
          color="#FF0000"
          size="md"
          icon={<AiOutlineCloseCircle fontSize="20px" />}
          onClick={removeFile}
        />
      </Flex>
      <Text fontSize="x-small">
        {progress}% &middot;{" "}
        {progress !== 100 &&
          isNaN(timeRemaining) === false &&
          `${timeRemaining} seconds remaining `}
        {Number(sizeInMB) < 1 ? `${sizeInKB} KB` : `${sizeInMB} MB`}
      </Text>
      {progress > 0 && progress < 100 && (
        <Progress
          h="5px"
          my={2}
          colorScheme="green"
          size="sm"
          value={progress}
        />
      )}
      {progress === 100 && (
        <Text my={2} color="green.500" fontSize="xs">
          {file.name}
        </Text>
      )}
    </Box>
  );
};

export default Upload;
