import { Box, Flex, Grid, Select, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { useAuth } from "../../context/useAuth";
import useDebounce from "../../hooks/useDebounce";
import useExportAgencyReports from "../../hooks/useExportAgencyReports";
import useGetAgencyReports from "../../hooks/useGetAgencyReports";
import { Report, ReportStatus } from "../../interfaces/Report";
import reportsService from "../../services/reportsService";
import DataTable from "../common/DataTable";
import Heading from "../common/Heading";
import Tag from "../common/Tag";
import ClosedCases from "../svg/ClosedCases";
import NewCases from "../svg/NewCases";
import OngoingCases from "../svg/OngoingCases";
import PendingCases from "../svg/PendingCases";
import UnassignedCases from "../svg/UnassignedCases";
import CasesCard from "./CasesCard";

export const AgencyAdminDashboard = () => {
  const auth = useAuth();
  const [status, setStatus] = useState("");
  const [reports, setReports] = useState<Report[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [order, setOrder] = useState("createdDate");
  const [orderBy, setOrderBy] = useState("desc");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState("");
  const debouncedQuery = useDebounce(query, 1000);
  const tableData = useMemo(() => reports, [reports]);
  const shortCode = auth?.user?.agency?.shortCode || "";
  const { data: statsRes } = useQuery(
    "statistics",
    () => reportsService.getAgencyReportStatistics(shortCode),
    { staleTime: 60000 }
  );
  const { data: statusRes } = useQuery(
    "statuses",
    () => reportsService.getReportStatuses(),
    { staleTime: 60000 }
  );
  const { isFetchingAgencyReports, agencyReports } = useGetAgencyReports({
    page,
    pageSize,
    order,
    orderBy,
    filter,
    shortCode,
    query: debouncedQuery,
  });
  const { isExporting, exportReports } = useExportAgencyReports();
  const statistics = statsRes?.data.body;

  useEffect(() => {
    if (!agencyReports) return;
    setReports(agencyReports.data);
    setTotalCount(agencyReports.totalCount);
    setPageCount(Math.ceil(agencyReports.totalCount / pageSize));
  }, [agencyReports, pageSize]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Case Number",
        accessor: "caseNumber",
      },
      {
        Header: "Assigned to",
        accessor: "agentAssigned",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.agentAssigned ?? "Unassigned"}</Text>
        ),
      },
      {
        Header: "Incident",
        accessor: "incidentType",
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell }) => <Text>{cell.row.values.location ?? "-"}</Text>,
        disableSortBy: true,
      },
      {
        Header: "Date submitted",
        accessor: "createdDate",
        Cell: ({ cell }) => (
          <Text>
            {moment(cell.row.values.createdDate).format("YYYY-MM-DD")}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "incidentStatus",
        Cell: ({ cell }) => (
          <Tag variant={cell.row.values.incidentStatus}>
            {cell.row.values.incidentStatus}
          </Tag>
        ),
      },
    ],
    []
  );

  const handlePagination = useCallback(
    (pageIndex: number, pageSize: number) => {
      setPage(pageIndex);
      setPageSize(pageSize);
    },
    []
  );

  const handleSort = useCallback((sort: { id: string; desc?: boolean }) => {
    setOrder(sort.id);
    setOrderBy(sort.desc ? "desc" : "asc");
  }, []);

  const handleFilter = (stat: string) => {
    if (filter === stat) setFilter("");
    else setFilter(stat);
  };

  const handleExport = useCallback(() => {
    if (!auth?.user?.agency?.shortCode) return;
    exportReports({
      agency: auth.user.agency.shortCode,
      pageIndex: page,
      pageSize,
      filter: status,
    });
  }, [exportReports, auth, page, pageSize, status]);

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Dashboard</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>{auth?.user?.agency?.shortCode} Cases</Heading>
      </Flex>
      <Grid
        templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gap={4}
        my={6}
      >
        <CasesCard
          title="NEW CASES"
          number={statistics?.newCases}
          color="#3DCDB5"
          bg="#D8F5F05C"
          Icon={NewCases}
          flex={1}
          isActive={filter === "newCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("newCases")}
        />
        <CasesCard
          title="UNASSIGNED CASES"
          number={statistics?.unassignedCases}
          color="#B13D3D"
          bg="#FFCCCC3D"
          Icon={UnassignedCases}
          flex={1}
          isActive={filter === "unassignedCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("unassignedCases")}
        />
        <CasesCard
          title="ONGOING CASES"
          number={statistics?.ongoingCases}
          color="#CFAE89"
          bg="#F5EFE7"
          Icon={OngoingCases}
          flex={1}
          isActive={filter === "ongoingCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("ongoingCases")}
        />
        <CasesCard
          title="PENDING CASES"
          number={statistics?.pendingCases}
          color="#EFC14A"
          bg="#FFF5003D"
          Icon={PendingCases}
          flex={1}
          isActive={filter === "pendingCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("pendingCases")}
        />
        <CasesCard
          title="CLOSED CASES"
          number={statistics?.closedCases}
          color="#2ED2E9"
          bg="#D8F2F58C"
          Icon={ClosedCases}
          flex={1}
          isActive={filter === "closedCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("closedCases")}
        />
      </Grid>
      {tableData && (
        <DataTable
          handlePagination={handlePagination}
          handleSort={handleSort}
          loading={isFetchingAgencyReports}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          filter={filter}
          query={query}
          setQuery={setQuery}
          statistics={statistics}
          setFilter={setFilter}
          isExporting={isExporting}
          handleExport={handleExport}
          ExportComponent={
            <Select
              fontSize="sm"
              value={status}
              onChange={e => setStatus(e.target.value)}
            >
              <option value="">All Reports</option>
              {(statusRes?.data.body.data as ReportStatus[])
                ?.sort((a, b) => a.order - b.order)
                .map(status => (
                  <option value={status.name} key={status.id}>
                    {status.name}
                  </option>
                ))}
            </Select>
          }
        />
      )}
    </Box>
  );
};
