import { Box, Flex, IconButton } from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import ProfileMenu from "../components/common/ProfileMenu";
// import Search from "../components/common/Search";

interface HeaderProps {
  onOpen: () => void;
}

const Header = ({ onOpen }: HeaderProps) => {
  return (
    <Box p={{ base: 2, md: 4 }} boxShadow="0px 8px 17px #D4D4D43D">
      <Flex justify={{ base: "space-between", md: "flex-end" }} align="center">
        <Box display={{ base: "block", md: "none" }}>
          <IconButton
            onClick={onOpen}
            variant="ghost"
            aria-label="expand sidebar"
            icon={<FiMenu fontSize="24px" />}
          />
        </Box>
        {/* <Search /> */}
        <ProfileMenu />
      </Flex>
    </Box>
  );
};

export default Header;
