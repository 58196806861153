import { AgencyAdminDashboard } from "../../components/dashboard/AgencyAdminDashboard";
import { AgencyUserDashboard } from "../../components/dashboard/AgencyUserDashboard";
import { OverallAdminDashboard } from "../../components/dashboard/OverallAdminDashboard";
import { UserDashboard } from "../../components/dashboard/UserDashboard";
import { useAuth } from "../../context/useAuth";
import getUserRole from "../../helpers/getUserRole";

export const Dashboard = () => {
  const auth = useAuth();
  const { isOverallAdmin, isAgencyAdmin, isAgencyUser } = getUserRole(
    auth?.user?.roles
  );
  return isOverallAdmin ? (
    <OverallAdminDashboard />
  ) : isAgencyAdmin ? (
    <AgencyAdminDashboard />
  ) : isAgencyUser ? (
    <AgencyUserDashboard />
  ) : (
    <UserDashboard />
  );
};
