import { Icon, IconProps } from "@chakra-ui/react";

const Link = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 25 26" fill="currentcolor" {...props}>
      <path
        d="M17.2601 15.6287L16.2526 16.1459C15.979 16.2863 15.6357 16.1759 15.4953 15.9023C15.3548 15.6288 15.4652 15.2855 15.7388 15.145L16.7463 14.6278C18.4944 13.7304 19.186 11.5797 18.2886 9.83158C17.3912 8.08347 15.2405 7.39185 13.4924 8.28924L12.4916 8.80302C12.218 8.94345 11.8747 8.83305 11.7343 8.55949C11.5938 8.28593 11.6976 7.94604 11.9778 7.80219L12.9786 7.28841C15.2805 6.10673 18.1078 7.0159 19.2895 9.31781C20.4712 11.6197 19.562 14.447 17.2601 15.6287Z"
        fill="#A0A6A1"
      />
      <path
        d="M12.2561 18.1975L11.2553 18.7112C8.95338 19.8929 6.12612 18.9838 4.94444 16.6818C3.76276 14.3799 4.67193 11.5527 6.97384 10.371L7.97466 9.85721C8.24823 9.71678 8.59154 9.82718 8.73197 10.1007C8.8724 10.3743 8.762 10.7176 8.48844 10.858L7.48761 11.3718C5.73949 12.2692 5.04788 14.42 5.94527 16.1681C6.84266 17.9162 8.9934 18.6078 10.7415 17.7104L11.7423 17.1966C12.0159 17.0562 12.3592 17.1666 12.4997 17.4402C12.6401 17.7137 12.5297 18.057 12.2561 18.1975Z"
        fill="#A0A6A1"
      />
      <path
        d="M15.0424 12.1303L9.70462 14.8704C9.43106 15.0108 9.08775 14.9004 8.94732 14.6269C8.80689 14.3533 8.91728 14.01 9.19084 13.8696L14.5286 11.1294C14.8022 10.989 15.1455 11.0994 15.2859 11.373C15.4263 11.6465 15.3159 11.9898 15.0424 12.1303Z"
        fill="#A0A6A1"
      />
    </Icon>
  );
};

export default Link;
