import {
  FormControl,
  TextareaProps,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import { useRecorder } from "../../context/useRecorder";
import AudioPlayer from "./AudioPlayer";
import Recorder from "./Recorder";

interface FormTextareaProps extends TextareaProps {
  form: any;
  label: string;
  id: string;
  options?: any;
  withRecorder?: boolean;
}

const FormTextarea = ({
  form,
  label,
  id,
  options,
  withRecorder,
  maxW, maxLength = 700,
  ...props
}: FormTextareaProps) => {
  const { recording } = useRecorder();

  return (
    <FormControl my={4} isInvalid={form.formState.errors[id]} maxW={maxW}>
      <Flex mb={2} align="flex-end" gap={2} justify="space-between" wrap="wrap">
        <FormLabel
          m={0}
          fontWeight="semibold"
          fontSize="sm"
          color="textSecondary"
          htmlFor={id}
        >
          {label}
        </FormLabel>
        {withRecorder && <Recorder />}
      </Flex>
      {recording ? (
        <AudioPlayer recording={recording} />
      ) : (
        <Textarea
          _invalid={{
            bg: "#FFCCCC",
            border: "1px solid #B13D3D",
            boxShadow: "0 0 0 1px #e53e3e",
          }}
          id={id}
          isDisabled={!!recording}
          maxLength={maxLength}
          {...form.register(id, options)}
          {...props}
        />
      )}
      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormTextarea;
