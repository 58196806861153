import {
  AspectRatio,
  Box,
  BoxProps,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { FiPlayCircle } from "react-icons/fi";
import getUserRole from "../../helpers/getUserRole";
import { useAuth } from "../../context/useAuth";
import { LibraryItem } from "../../interfaces/Library";
import Tag from "../common/Tag";
import ResourceModal from "./ResourceModal";
import { getYoutubeThumbnail } from "../../utils/getYoutubeURL";

interface LibraryCardProps extends BoxProps {
  label?: string;
  item?: LibraryItem;
}

const LibraryCard = ({ label, item, ...props }: LibraryCardProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);

  return (
    <Box>
      <Box
        pos="relative"
        w={{ base: "100%", sm: "320px" }}
        h="210px"
        px="20px"
        {...props}
      >
        {item?.libraryItemType === "Image" &&
          (item.url ? (
            <Image
              cursor="pointer"
              onClick={onOpen}
              h="100%"
              w="100%"
              src={item.url}
              borderRadius="5px"
              objectFit="cover"
            />
          ) : (
            <Image
              cursor="pointer"
              onClick={onOpen}
              h="100%"
              w="100%"
              src={item?.libraryItemAttachment?.fileStore?.url}
              borderRadius="5px"
              objectFit="cover"
            />
          ))}
        {item?.libraryItemType === "Video" && (
          <>
            {item.url ? (
              item.url.includes("youtube") || item.url.includes("youtu.be") ? (
                <Image
                  cursor="pointer"
                  onClick={onOpen}
                  h="100%"
                  w="100%"
                  src={getYoutubeThumbnail(item.url)}
                  borderRadius="5px"
                  objectFit="cover"
                />
              ) : (
                <AspectRatio
                  h="210px"
                  ratio={16 / 9}
                  cursor="pointer"
                  onClick={onOpen}
                >
                  <video>
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              )
            ) : (
              <AspectRatio
                h="210px"
                ratio={16 / 9}
                cursor="pointer"
                onClick={onOpen}
              >
                <video>
                  <source
                    src={item?.libraryItemAttachment?.fileStore?.url}
                    type={item?.libraryItemAttachment?.fileStore?.mimeType}
                  />
                  Your browser does not support the video tag.
                </video>
              </AspectRatio>
            )}
            <IconButton
              variant="unstyled"
              display="flex"
              alignItems="center"
              justifyContent="center"
              aria-label="play video"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              icon={<FiPlayCircle fontSize="60px" />}
              boxSize="60px"
              color="gray.200"
              onClick={onOpen}
            />
          </>
        )}
        {item?.libraryItemType === "Document" &&
          (item.url ? (
            <AspectRatio
              h="210px"
              ratio={16 / 9}
              cursor="pointer"
              onClick={onOpen}
            >
              <iframe
                loading="lazy"
                style={{ pointerEvents: "none" }}
                title={item.title}
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${item.url}`}
              ></iframe>
            </AspectRatio>
          ) : (
            <AspectRatio
              h="210px"
              ratio={16 / 9}
              cursor="pointer"
              onClick={onOpen}
            >
              <iframe
                loading="lazy"
                style={{ pointerEvents: "none" }}
                title={item.title}
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${item?.libraryItemAttachment?.fileStore?.url}`}
              ></iframe>
            </AspectRatio>
          ))}

        <Tag
          borderRadius="full"
          pos="absolute"
          top="16px"
          left="calc(16px + 20px)"
        >
          {label}
        </Tag>
        {!!isOverallAdmin &&
          (item?.isApproved ? (
            <Tag
              pos="absolute"
              bottom="16px"
              right="calc(16px + 20px)"
              variant="approved"
            >
              Approved
            </Tag>
          ) : (
            <Tag
              pos="absolute"
              bottom="16px"
              right="calc(16px + 20px)"
              variant="disapproved"
            >
              Disapproved
            </Tag>
          ))}
      </Box>
      <ResourceModal isOpen={isOpen} onClose={onClose} item={item} />
    </Box>
  );
};

export default LibraryCard;
