import axiosInstance from "../helpers/axiosInstance";
import { SuccessStory } from "../interfaces/SuccessStory";

const postSuccessStory = (data: SuccessStory) => {
  const url = `/successStory`;
  return axiosInstance.post(url, data);
};

const approveSuccessStory = (storyId: number) => {
  const url = `/successStory/approve/${storyId}`;
  return axiosInstance.put(url);
};

const disapproveSuccessStory = (storyId: number) => {
  const url = `/successStory/disapprove/${storyId}`;
  return axiosInstance.put(url);
};

const getSuccessStories = (page: number, size: number) => {
  const url = `/successStory?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const getOverallSuccessStories = (page: number, size: number) => {
  const url = `/successStory/OverAll/admin/all?&page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const getApprovedSuccessStories = (page: number, size: number) => {
  const url = `/successStory/OverAll/admin?status=approved&page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const getDisapprovedSuccessStories = (page: number, size: number) => {
  const url = `/successStory/OverAll/admin?status=disapproved&page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const successStoriesService = {
  postSuccessStory,
  getSuccessStories,
  approveSuccessStory,
  disapproveSuccessStory,
  getOverallSuccessStories,
  getApprovedSuccessStories,
  getDisapprovedSuccessStories,
};

export default successStoriesService;
