import axiosInstance from "../helpers/axiosInstance";
import { AgencyUser, AgencyUserDTO } from "../interfaces/AgencyUser";

const getAgencyUsers = (agencyId: number, page: number, size: number) => {
  const url = `/agencyUsers/all/${agencyId}?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const getAgencyUsersOverallAdmin = (
  agencyId: number,
  page: number,
  size: number
) => {
  const url = `/agencyUsers/overallAdmin/all/${agencyId}?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const getAgencyUser = (userId: number) => {
  const url = `/agencyUsers/${userId}`;
  return axiosInstance.get(url);
};

const createAgencyUser = (data: AgencyUserDTO) => {
  const url = `/agencyUsers`;
  return axiosInstance.post(url, data);
};

const editAgencyUser = (data: AgencyUserDTO) => {
  const url = `/agencyUsers`;
  return axiosInstance.put(url, data);
};

const changeAgencyUserStatus = (
  status: "disable" | "enable",
  userId: number,
  shortCode: string
) => {
  const url = `/agencyUsers/lockout?status=${status}&UserId=${userId}&shortCode=${shortCode}`;
  return axiosInstance.put(url);
};

const changeUserAdminStatus = (data: AgencyUser) => {
  const url = `/agencyUsers/admin`;
  return axiosInstance.put(url, data);
};

const agencyUsersService = {
  getAgencyUsers,
  getAgencyUser,
  getAgencyUsersOverallAdmin,
  createAgencyUser,
  changeUserAdminStatus,
  editAgencyUser,
  changeAgencyUserStatus,
};

export default agencyUsersService;
