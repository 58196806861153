import { createBreakpoints } from "@chakra-ui/theme-tools";
const breakpoints = createBreakpoints({
  xsm: "376px",
  sm: "426px",
  md: "769px",
  lg: "1025px",
  xl: "1281px",
  "2xl": "1441px",
  "3xl": "2561px",
});

export default breakpoints;
