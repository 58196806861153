import axiosInstance from "../helpers/axiosInstance";
import { LibraryItem } from "../interfaces/Library";

const addLibraryItem = (data: LibraryItem) => {
  const url = `/library`;
  return axiosInstance.post(url, data);
};

const getLibraryTypes = () => {
  const url = `/library/types`;
  return axiosInstance.get(url);
};

const getImageResources = ({ pageParam = 1 }) => {
  const url = `/library?fileTypeId=1&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getApprovedImageResources = ({ pageParam = 1 }) => {
  const url = `/library/approved?fileTypeId=1&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getDisapprovedImageResources = ({ pageParam = 1 }) => {
  const url = `/library/disapproved?fileTypeId=1&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getVideoResources = ({ pageParam = 1 }) => {
  const url = `/library?fileTypeId=2&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getApprovedVideoResources = ({ pageParam = 1 }) => {
  const url = `/library/approved?fileTypeId=2&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getDisapprovedVideoResources = ({ pageParam = 1 }) => {
  const url = `/library/disapproved?fileTypeId=2&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getDocumentsResources = ({ pageParam = 1 }) => {
  const url = `/library?fileTypeId=3&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getApprovedDocumentsResources = ({ pageParam = 1 }) => {
  const url = `/library/approved?fileTypeId=3&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const getDisapprovedDocumentsResources = ({ pageParam = 1 }) => {
  const url = `/library/disapproved?fileTypeId=3&page=${pageParam}&pageSize=${6}`;
  return axiosInstance.get(url);
};

const approveLibraryItem = (resourceId: number) => {
  const url = `/library/approve/${resourceId}`;
  return axiosInstance.put(url);
};

const disapproveLibraryItem = (data: { id: number; note: string }) => {
  const url = `/library/disapprove`;
  return axiosInstance.put(url, data);
};

const deleteLibraryItem = (resourceId: number) => {
  const url = `/library/${resourceId}`;
  return axiosInstance.delete(url);
};

const libraryService = {
  addLibraryItem,
  getLibraryTypes,
  approveLibraryItem,
  disapproveLibraryItem,
  deleteLibraryItem,
  getImageResources,
  getApprovedImageResources,
  getDisapprovedImageResources,
  getVideoResources,
  getApprovedVideoResources,
  getDisapprovedVideoResources,
  getDocumentsResources,
  getApprovedDocumentsResources,
  getDisapprovedDocumentsResources,
};

export default libraryService;
