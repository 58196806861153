import { Icon, IconProps } from "@chakra-ui/react";

const ErrorIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        d="M10 11.4583C9.65833 11.4583 9.375 11.175 9.375 10.8333V6.45833C9.375 6.11666 9.65833 5.83333 10 5.83333C10.3417 5.83333 10.625 6.11666 10.625 6.45833V10.8333C10.625 11.175 10.3417 11.4583 10 11.4583Z"
        fill="#B13D3D"
      />
      <path
        d="M10.0001 14.375C9.77508 14.375 9.56673 14.2917 9.4084 14.1333C9.3334 14.05 9.27509 13.9583 9.22509 13.8583C9.18342 13.7583 9.16675 13.65 9.16675 13.5416C9.16675 13.325 9.2584 13.1083 9.4084 12.95C9.71673 12.6416 10.2834 12.6416 10.5918 12.95C10.7418 13.1083 10.8334 13.325 10.8334 13.5416C10.8334 13.65 10.8084 13.7583 10.7667 13.8583C10.7251 13.9583 10.6668 14.05 10.5918 14.1333C10.4334 14.2917 10.2251 14.375 10.0001 14.375Z"
        fill="#B13D3D"
      />
      <path
        d="M10.0001 18.9583C9.44174 18.9583 8.87506 18.8166 8.37506 18.525L3.42506 15.6666C2.42506 15.0833 1.80005 14.0083 1.80005 12.85V7.14999C1.80005 5.99166 2.42506 4.91665 3.42506 4.33332L8.37506 1.47499C9.37506 0.891658 10.6167 0.891658 11.6251 1.47499L16.5751 4.33332C17.5751 4.91665 18.2001 5.99166 18.2001 7.14999V12.85C18.2001 14.0083 17.5751 15.0833 16.5751 15.6666L11.6251 18.525C11.1251 18.8166 10.5584 18.9583 10.0001 18.9583ZM10.0001 2.29164C9.6584 2.29164 9.30839 2.38331 9.00006 2.55831L4.05006 5.41664C3.43339 5.77497 3.05005 6.43332 3.05005 7.14999V12.85C3.05005 13.5583 3.43339 14.225 4.05006 14.5833L9.00006 17.4416C9.61673 17.8 10.3834 17.8 10.9917 17.4416L15.9417 14.5833C16.5584 14.225 16.9418 13.5666 16.9418 12.85V7.14999C16.9418 6.44166 16.5584 5.77497 15.9417 5.41664L10.9917 2.55831C10.6917 2.38331 10.3417 2.29164 10.0001 2.29164Z"
        fill="#B13D3D"
      />
    </Icon>
  );
};

export default ErrorIcon;
