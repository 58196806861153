const uploadLimit = (file: File) => {
  const isImage = file.type.startsWith("image");
  const isVideo = file.type.startsWith("video");
  const isAudio = file.type.startsWith("audio");
  const isDocument = file.type.startsWith("application");
  if (isImage)
    return { type: "Image", size: "5MB", isExceeded: file.size > 5242880 };
  if (isAudio)
    return { type: "Audio", size: "70MB", isExceeded: file.size > 73400320 };
  if (isVideo)
    return { type: "Video", size: "70MB", isExceeded: file.size > 73400320 };
  if (isDocument)
    return { type: "Document", size: "70MB", isExceeded: file.size > 73400320 };
};

export default uploadLimit;
