import { Icon, IconProps } from "@chakra-ui/react";

const Mic = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="currentcolor" {...props}>
      <path d="M10.0001 13.5416C7.81675 13.5416 6.04175 11.7666 6.04175 9.58329V4.99996C6.04175 2.81663 7.81675 1.04163 10.0001 1.04163C12.1834 1.04163 13.9584 2.81663 13.9584 4.99996V9.58329C13.9584 11.7666 12.1834 13.5416 10.0001 13.5416ZM10.0001 2.29163C8.50841 2.29163 7.29175 3.50829 7.29175 4.99996V9.58329C7.29175 11.075 8.50841 12.2916 10.0001 12.2916C11.4917 12.2916 12.7084 11.075 12.7084 9.58329V4.99996C12.7084 3.50829 11.4917 2.29163 10.0001 2.29163Z" />
      <path d="M10 16.4583C6.14167 16.4583 3 13.3166 3 9.45829V8.04163C3 7.69996 3.28333 7.41663 3.625 7.41663C3.96667 7.41663 4.25 7.69996 4.25 8.04163V9.45829C4.25 12.625 6.83333 15.2083 10 15.2083C13.1667 15.2083 15.75 12.625 15.75 9.45829V8.04163C15.75 7.69996 16.0333 7.41663 16.375 7.41663C16.7167 7.41663 17 7.69996 17 8.04163V9.45829C17 13.3166 13.8583 16.4583 10 16.4583Z" />
      <path d="M11.1584 5.98331C11.0917 5.98331 11.0167 5.97498 10.9417 5.94998C10.3334 5.72498 9.66673 5.72498 9.05839 5.94998C8.73339 6.06664 8.37506 5.89998 8.25839 5.57498C8.14173 5.24998 8.30839 4.89164 8.63339 4.77498C9.51673 4.45831 10.4917 4.45831 11.3751 4.77498C11.7001 4.89164 11.8667 5.24998 11.7501 5.57498C11.6501 5.82498 11.4084 5.98331 11.1584 5.98331Z" />
      <path d="M10.6666 7.74998C10.6082 7.74998 10.5582 7.74164 10.4999 7.72498C10.1666 7.63331 9.8249 7.63331 9.49157 7.72498C9.15823 7.81664 8.81657 7.61664 8.7249 7.28331C8.63323 6.95831 8.83323 6.61664 9.16657 6.52498C9.70823 6.37498 10.2916 6.37498 10.8332 6.52498C11.1666 6.61664 11.3666 6.95831 11.2749 7.29164C11.1999 7.56664 10.9416 7.74998 10.6666 7.74998Z" />
      <path d="M10 18.9584C9.65833 18.9584 9.375 18.675 9.375 18.3334V15.8334C9.375 15.4917 9.65833 15.2084 10 15.2084C10.3417 15.2084 10.625 15.4917 10.625 15.8334V18.3334C10.625 18.675 10.3417 18.9584 10 18.9584Z" />
    </Icon>
  );
};

export default Mic;
