import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { Agency } from "../../interfaces/Agency";
import FormSelect from "../common/FormSelect";

interface TransferModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  form: any;
  agencies: Agency[];
  buttonRef: React.RefObject<HTMLButtonElement>;
}

const TransferModal = ({
  agencies,
  form,
  isOpen,
  onClose,
  isLoading,
  buttonRef,
}: TransferModalProps) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md" fontWeight="bold" color="textSecondary">
          TRANSFER
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormSelect
            maxW="max-content"
            label="Select an Agency"
            id="agency"
            options={{ required: "Please select an agency" }}
            form={form}
          >
            <option value=""></option>
            {agencies &&
              agencies.map(agency => (
                <option key={agency.id} value={agency.id}>
                  {agency.name}
                </option>
              ))}
          </FormSelect>
          <Button
            onClick={() => buttonRef.current?.click()}
            isLoading={isLoading}
            px={10}
            my={6}
          >
            Apply
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TransferModal;
