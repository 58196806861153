import { Icon, IconProps } from "@chakra-ui/react";

const Apple = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 30 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0568 19.1447C25.0938 16.3401 26.5998 13.7495 29.0472 12.2806C27.4962 10.118 24.9916 8.79604 22.2888 8.71349C19.4453 8.42209 16.6887 10.3747 15.2397 10.3747C13.7626 10.3747 11.5317 8.74242 9.12928 8.79067C5.96837 8.89038 3.09299 10.6024 1.55514 13.3004C-1.71976 18.836 0.723023 26.9715 3.86011 31.446C5.42966 33.6371 7.26401 36.0846 9.66422 35.9978C12.013 35.9027 12.8902 34.5356 15.7253 34.5356C18.5342 34.5356 19.3572 35.9978 21.8061 35.9426C24.3264 35.9027 25.9143 33.7418 27.4288 31.53C28.5565 29.9688 29.4243 28.2433 30 26.4174C27.0066 25.1814 25.0603 22.3178 25.0568 19.1447Z"
        fill="#424D44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4313 5.77069C21.8055 4.16013 22.4825 2.09003 22.3185 0C20.2191 0.21528 18.2798 1.1949 16.887 2.74367C15.5118 4.27173 14.8151 6.27085 14.9504 8.30071C17.078 8.3221 19.0981 7.38961 20.4313 5.77069Z"
        fill="#424D44"
      />
    </Icon>
  );
};

export default Apple;
