import React, { createContext, useContext, useState } from "react";
import { FileStore } from "../interfaces/FileUpload";

interface IRecorderContext {
  recording: FileStore | null;
  setRecording: React.Dispatch<React.SetStateAction<FileStore | null>>;
}

const RecorderContext = createContext<IRecorderContext>({} as IRecorderContext);

export const RecorderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [recording, setRecording] = useState<FileStore | null>(null);

  return (
    <RecorderContext.Provider value={{ recording, setRecording }}>
      {children}
    </RecorderContext.Provider>
  );
};

export const useRecorder = () => useContext(RecorderContext);
