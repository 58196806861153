import { Box, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Column } from "react-table";
import Heading from "../../../components/common/Heading";
import Table from "../../../components/common/Table";
import EditLocationModal from "../../../components/locations/EditLocationModal";
import { Location } from "../../../interfaces/Location";
import locationService from "../../../services/locationService";

export const Locations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);
  const [location, setLocation] = useState<Location | null>(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableData = React.useMemo(() => locations, [locations]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Report Description",
        accessor: "reportDescription",
        Cell: ({ cell }) => (
          <Text maxW={200} noOfLines={1}>
            {cell.row.values.reportDescription}
          </Text>
        ),
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Latitude",
        accessor: "latitude",
      },
      {
        Header: "Longitude",
        accessor: "longitude",
      },
    ],
    []
  );

  const handleClick = (data: any) => {
    if (!data) return;
    setLocation(data as Location);
    onOpen();
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setPageIndex(pageIndex + 1);
      setPageSize(pageSize);
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        locationService
          .getLocations(pageIndex + 1, pageSize)
          .then(res => {
            setLocations(res.data.body.data);
            setTotalCount(res.data.body.totalCount);
            setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
          })
          .catch(err => {
            toast({
              title: "Failed to fetch locations",
              description: err?.response?.data?.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            console.error(err.response);
          })
          .finally(() => setLoading(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Locations</title>
      </Helmet>
      <Heading my={8}>Locations</Heading>

      {tableData && (
        <Table
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Locations"
          onClick={handleClick}
        />
      )}
      {location && (
        <EditLocationModal
          isOpen={isOpen}
          onClose={onClose}
          location={location}
          refetch={() => fetchData({ pageIndex: pageIndex - 1, pageSize })}
        />
      )}
    </Box>
  );
};
