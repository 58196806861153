import axios from "axios";
import authService from "../services/authService";
import history from "./history";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

axiosInstance.interceptors.request.use(
  req => {
    const token = localStorage.getItem("token");
    if (req.headers && token)
      req.headers.Authorization = `Bearer ${JSON.parse(token).firebaseToken}`;
    return req;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  res => res,
  async err => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/usersProfile/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const token = localStorage.getItem("token");
          const tokenData = JSON.parse(token ?? "");
          const response = await authService.refreshToken(tokenData);
          localStorage.setItem("token", JSON.stringify(response.data.body));
          return axiosInstance(originalConfig);
        } catch (_error) {
          history.replace("/login");
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
