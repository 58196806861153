import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Skeleton } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppliedActions from "../../../components/caseDetails/AppliedActions";
import Attachments from "../../../components/caseDetails/Attachments";
import DetailsCard from "../../../components/caseDetails/DetailsCard";
import Note from "../../../components/caseDetails/Note";
import Heading from "../../../components/common/Heading";
import Link from "../../../components/common/Link";
import { useAuth } from "../../../context/useAuth";
import { UploadProvider } from "../../../context/useUploads";
import reportsService from "../../../services/reportsService";

export const AgencyCaseDetails = () => {
  const { caseId } = useParams();
  const auth = useAuth();
  const { data: reportRes, isLoading: reportLoading } = useQuery(
    ["report", caseId],
    () => reportsService.getReport(`${caseId}`),
    { staleTime: 60000 }
  );
  const { data: response, isLoading } = useQuery(
    ["moreInfo", caseId],
    () => reportsService.getMoreInformation(Number(caseId)),
    { staleTime: 60000 }
  );

  if (reportLoading || isLoading) {
    return (
      <Box>
        <Flex
          justify="space-between"
          align="center"
          flexWrap="wrap"
          gap={4}
          my={8}
        >
          <Skeleton h="40px" w="300px" />
        </Flex>
        <Flex flexDir={{ base: "column", lg: "row" }} gap={6}>
          <Skeleton
            w={{ base: "100%", lg: "50%" }}
            h={{ base: "700px", lg: "auto" }}
          />
          <Flex flexDir="column" gap={6} w={{ base: "100%", lg: "50%" }}>
            <Skeleton h="312px" />
            <Skeleton h="244px" />
            <Skeleton h="300px" />
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Agency Cases</title>
      </Helmet>
      {reportRes && (
        <UploadProvider>
          <Flex
            justify="space-between"
            align="center"
            flexWrap="wrap"
            gap={4}
            my={8}
          >
            <Flex align="center" gap={4}>
              <Heading>{reportRes.data.body.ownedBy} Reported Case</Heading>
              <Link to="/dashboard" _hover={{}}>
                <Button variant="ghost" size="sm" leftIcon={<ArrowBackIcon />}>
                  Go back
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Flex flexDir={{ base: "column", lg: "row" }} gap={6}>
            <DetailsCard
              report={reportRes.data.body}
              moreInfo={response?.data.body}
              flex="1"
            />
            <Flex
              flexDir="column"
              gap={6}
              flex="1"
              maxW={{ base: "unset", lg: "50%" }}
            >
              <Note
                agentAssigned={reportRes.data.body.agentAssigned}
                dateAssigned={reportRes.data.body.dateAssigned}
                reportId={Number(caseId)}
                agencyId={Number(auth?.user?.agency?.id)}
                isAgent={
                  auth?.user?.agency?.shortCode === reportRes.data.body.ownedBy
                }
                flex="1"
              />
              <Attachments flex="1" />
              <AppliedActions reportId={Number(caseId)} flex="1" />
            </Flex>
          </Flex>
        </UploadProvider>
      )}
    </Box>
  );
};
