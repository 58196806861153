import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Location, LocationDTO } from "../../interfaces/Location";
import locationService from "../../services/locationService";
import FormInput from "../common/FormInput";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  location: Location;
  refetch: () => void;
}

const EditLocationModal = ({ isOpen, onClose, location, refetch }: Props) => {
  const form = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation((payload: LocationDTO) => {
    return locationService.editLocation(payload);
  });

  useEffect(() => {
    form.setValue("reportDescription", `${location.reportDescription}`);
    form.setValue("address", `${location.address}`);
    form.setValue("longitude", `${location.longitude}`);
    form.setValue("latitude", `${location.latitude}`);
  }, [form, location]);

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to edit location",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast({
        title: "Changes have been saved",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess, toast, navigate, onClose]);

  function onSubmit({ longitude, latitude }: any) {
    mutation.mutate({ id: location.id, longitude, latitude });
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="textSecondary">Edit Location</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Box maxW="950px">
              <Flex flexDir="column">
                <FormInput
                  form={form}
                  label="Report Description"
                  id="reportDescription"
                  isReadOnly
                  bgColor="gray.100"
                  opacity={0.8}
                />
                <FormInput
                  form={form}
                  label="Address"
                  id="address"
                  isReadOnly
                  bgColor="gray.100"
                  opacity={0.8}
                />
                <FormInput
                  form={form}
                  label="Latitude"
                  id="latitude"
                  options={{ required: "Latitude is required" }}
                  type="number"
                  step="any"
                />
                <FormInput
                  form={form}
                  label="Longitude"
                  id="longitude"
                  options={{ required: "Longitude is required" }}
                  type="number"
                  step="any"
                />
              </Flex>
            </Box>
            <Button
              my={4}
              w="full"
              h="50px"
              px="50px"
              isLoading={form.formState.isSubmitting || mutation.isLoading}
              type="submit"
              disabled={form.formState.isSubmitting || mutation.isLoading}
            >
              Save Changes
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditLocationModal;
