import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import { Column } from "react-table";
import Heading from "../../../components/common/Heading";
import Table from "../../../components/common/Table";
import Export from "../../../components/svg/Export";
import { User } from "../../../interfaces/User";
import usersService from "../../../services/usersService";

export const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);
  const toast = useToast();

  const mutation = useMutation(() =>
    usersService.exportUsers(pageIndex, pageSize)
  );

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to export users",
        description: (mutation.error as any).response.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      const url = window.URL.createObjectURL(new Blob([mutation.data.data]));
      const headerLine = mutation.data.headers["content-disposition"];
      const startFileNameIndex = headerLine?.indexOf('"') + 1;
      const endFileNameIndex = headerLine?.lastIndexOf('"');
      const fileName = headerLine?.substring(
        startFileNameIndex,
        endFileNameIndex
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [mutation.isSuccess, mutation.data]);

  const tableData = React.useMemo(() => users, [users]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Birth Date",
        accessor: "birthdate",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setPageIndex(pageIndex + 1);
      setPageSize(pageSize);
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        usersService
          .getAllUsers(pageIndex + 1, pageSize)
          .then(res => {
            setUsers(res.data.body.data);
            setTotalCount(res.data.body.totalCount);
            setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
          })
          .catch(err => {
            toast({
              title: "Failed to fetch users",
              description: err?.response?.data?.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            console.error(err.response);
          })
          .finally(() => setLoading(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <Box>
      <Helmet>
        <title>FlagIt | Users</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>Users</Heading>
        <Button
          variant="outline"
          onClick={() => mutation.mutate()}
          isLoading={mutation.isLoading}
          rightIcon={<Export />}
          isDisabled={loading || tableData?.length === 0}
        >
          Export
        </Button>
      </Flex>

      {tableData && (
        <Table
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Users"
          cursor="auto"
        />
      )}
    </Box>
  );
};
