import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import Tag from "../common/Tag";
import { ReportAction } from "../../interfaces/ReportAction";
import AttachmentsView from "./AttachmentsView";

interface MoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  log: ReportAction | null;
}
const MoreModal = ({ isOpen, onClose, log }: MoreModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="textSecondary">Action Log</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex my={2} gap={2}>
            <Text minW="50px">User</Text>
            <Text color="textSecondary">{log?.createdBy}</Text>
          </Flex>
          <Flex my={2} gap={2}>
            <Text minW="50px">Date</Text>
            <Text color="textSecondary">
              {moment(log?.modifiedDate).format("DD-MM-YYYY")}
            </Text>
          </Flex>
          <Flex my={2} gap={2}>
            <Text minW="50px">Action</Text>
            <Tag variant={log?.reportStatusName}>{log?.reportStatusName}</Tag>
          </Flex>
          <Flex my={2} gap={2}>
            <Text minW="50px">Note</Text>
            <Text color="textSecondary">{log?.note}</Text>
          </Flex>

          {!!log?.reportActionAttachments?.length && (
            <>
              <Text>Attachments</Text>
              <Flex
                justify="space-evenly"
                align="center"
                gap={6}
                flexWrap="wrap"
                my={6}
                mb={10}
              >
                {log?.reportActionAttachments?.map(attachment => (
                  <AttachmentsView
                    key={attachment.id}
                    file={attachment}
                    w={{ base: "full", sm: "calc(100% / 3 - 1rem)" }}
                  />
                ))}
              </Flex>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MoreModal;
