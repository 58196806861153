import axiosInstance from "../helpers/axiosInstance";
import { UserDTO } from "../interfaces/User";

const login = (email: string, password: string) => {
  const url = "/usersProfile/login";
  return axiosInstance.post(url, { email, password });
};

const register = (user: UserDTO) => {
  const url = "/usersProfile/register";
  return axiosInstance.post(url, user);
};

const resendEmailVerification = (email: string) => {
  const url = `/usersProfile/email/verification/${email}`;
  return axiosInstance.get(url);
};
const resetPassword = (email: string) => {
  const url = `/usersProfile/password/recover?email=${email}`;
  return axiosInstance.get(url);
};

const refreshToken = (token: any) => {
  const url = `/usersProfile/refreshToken`;
  return axiosInstance.post(url, token);
};

const logout = () => {
  const url = `/usersProfile/logout`;
  return axiosInstance.get(url);
};

const authService = {
  login,
  register,
  resendEmailVerification,
  resetPassword,
  refreshToken,
  logout,
};

export default authService;
