import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/useAuth";
import { loginSchema } from "../../utils/validationSchema";
import Link from "../common/Link";
import ErrorIcon from "../svg/ErrorIcon";

interface FieldValues {
  email: string;
  password: string;
}

const LoginForm = () => {
  const [show, setShow] = useState(false);
  const handlePassword = () => setShow(prev => !prev);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FieldValues>({ resolver: yupResolver(loginSchema) });
  const auth = useAuth();

  function onSubmit(values: any) {
    const { email, password } = values;
    auth?.login(email, password);
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={10}>
      <Box mb={10}>
        <FormControl my={6} isInvalid={!!errors.email}>
          <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="email">
            Email Address
          </FormLabel>
          <InputGroup h="50px">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="email"
              type="email"
              placeholder="e.g. john-doe@flagit.com"
              {...register("email")}
            />
            <InputRightElement h="full">
              <Flex align="center" h="full">
                <FormErrorIcon as={ErrorIcon} fontSize="20px" />
              </Flex>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl my={6} isInvalid={!!errors.password}>
          <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="password">
            Password
          </FormLabel>
          <Box w="full" position="relative">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="password"
              pr="99px"
              type={show ? "text" : "password"}
              placeholder="Tiger123@Naija"
              autoComplete="on"
              {...register("password")}
            />
            <Button
              position="absolute"
              zIndex="overlay"
              top="50%"
              transform="translateY(-50%)"
              right={2}
              fontWeight="bold"
              fontSize="xs"
              variant="link"
              onClick={handlePassword}
              color="secondary.500"
              _focus={{}}
            >
              {show ? "Hide" : "Show"}
            </Button>
          </Box>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Button h="50px" w="full" my={6} isLoading={auth?.loading} type="submit">
        Log In
      </Button>
      <Link
        to="/forgot-password"
        textAlign="center"
        color="#0A230E"
        fontWeight="bold"
        display="block"
        my={6}
      >
        Forgot Password?
      </Link>
      <Text textAlign="center" color="#0A230E" fontWeight="bold">
        Don’t have an account?{" "}
        <Link color="secondary.500" to="/register">
          Sign up
        </Link>
      </Text>
    </Box>
  );
};

export default LoginForm;
