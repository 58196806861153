import { Box, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Heading from "../../../components/common/Heading";
import ReportCaseForm from "../../../components/reportCase/ReportCaseForm";
import { UploadProvider } from "../../../context/useUploads";

export const EmergencyMessage = () => {
  return (
    <Box>
      <Helmet>
        <title>FlagIt | Emergency Message</title>
      </Helmet>
      <Box my={8}>
        <Heading mb={3}>Emergency</Heading>
        <Text fontSize="md" maxW="448px">
          Your information is treated with utmost confidentiality will not be
          shared with third parties
        </Text>
      </Box>
      <UploadProvider>
        <ReportCaseForm />
      </UploadProvider>
    </Box>
  );
};
