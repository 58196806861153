import {
  AspectRatio,
  Box,
  BoxProps,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { FiPlayCircle } from "react-icons/fi";
import { FileStore } from "../../interfaces/FileUpload";
import MediaModal from "./MediaModal";
interface AttachmentsViewProps extends BoxProps {
  file?: FileStore;
}

const AttachmentsView = ({ file, ...props }: AttachmentsViewProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box pos="relative" {...props}>
      {file?.fileType === "audio" && (
        <audio controls>
          <source src={file?.url || file?.fileUrl} type={file?.mimeType} />
          Your browser does not support the audio element.
        </audio>
      )}
      {file?.fileType === "image" && (
        <AspectRatio onClick={onOpen} maxW="full" ratio={1}>
          <Image
            cursor="pointer"
            src={file?.url || file?.fileUrl}
            objectFit="cover"
            borderRadius="5px"
            alt="media file"
            boxSize="full"
          />
        </AspectRatio>
      )}
      {file?.fileType === "video" && (
        <>
          <AspectRatio onClick={onOpen} maxW="full" ratio={1}>
            <video>
              <source src={file?.url || file?.fileUrl} type={file?.mimeType} />
              Your browser does not support the video tag.
            </video>
          </AspectRatio>
          <IconButton
            variant="unstyled"
            display="flex"
            alignItems="center"
            justifyContent="center"
            aria-label="play video"
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            icon={<FiPlayCircle fontSize="60px" />}
            boxSize="60px"
            color="gray.200"
            onClick={onOpen}
          />
        </>
      )}
      <MediaModal isOpen={isOpen} onClose={onClose} file={file} />
    </Box>
  );
};

export default AttachmentsView;
