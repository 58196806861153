import { Box, Flex, Grid, Input, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Column } from "react-table";
import useDebounce from "../../hooks/useDebounce";
import useExportOverallReports from "../../hooks/useExportOverallReports";
import useGetOverallReports from "../../hooks/useGetOverallReports";
import { Report } from "../../interfaces/Report";
import reportsService from "../../services/reportsService";
import DataTable from "../common/DataTable";
import Heading from "../common/Heading";
import Tag from "../common/Tag";
import ClosedCases from "../svg/ClosedCases";
import NewCases from "../svg/NewCases";
import OngoingCases from "../svg/OngoingCases";
import PendingCases from "../svg/PendingCases";
import UnassignedCases from "../svg/UnassignedCases";
import CasesCard from "./CasesCard";

export const OverallAdminDashboard = () => {
  const today = new Date();
  const priorDate = new Date().setDate(today.getDate() - 30);
  const startDate = moment(priorDate).format("YYYY-MM-DD");
  const endDate = moment(today).format("YYYY-MM-DD");
  const [dateFilter, setDateFilter] = useState({ startDate, endDate });
  const [reports, setReports] = useState<Report[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [order, setOrder] = useState("createdDate");
  const [orderBy, setOrderBy] = useState("desc");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState("");
  const debouncedQuery = useDebounce(query, 1000);
  const tableData = useMemo(() => reports, [reports]);
  const { data: statsRes } = useQuery(
    "statistics",
    reportsService.getOverallReportStatistics,
    { staleTime: 60000 }
  );
  const { isFetchingOverallReports, overallReports } = useGetOverallReports({
    page,
    pageSize,
    order,
    orderBy,
    filter,
    query: debouncedQuery,
  });
  const { isExporting, exportReports } = useExportOverallReports();
  const statistics = statsRes?.data.body;

  useEffect(() => {
    if (!overallReports) return;
    setReports(overallReports.data);
    setTotalCount(overallReports.totalCount);
    setPageCount(Math.ceil(overallReports.totalCount / pageSize));
  }, [overallReports, pageSize]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Case Number",
        accessor: "caseNumber",
      },
      {
        Header: "Assigned to",
        accessor: "agentAssigned",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.agentAssigned ?? "Unassigned"}</Text>
        ),
      },
      {
        Header: "Incident",
        accessor: "incidentType",
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell }) => <Text>{cell.row.values.location ?? "-"}</Text>,
        disableSortBy: true,
      },
      {
        Header: "Date submitted",
        accessor: "createdDate",
        Cell: ({ cell }) => (
          <Text>
            {moment(cell.row.values.createdDate).format("YYYY-MM-DD")}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "incidentStatus",
        Cell: ({ cell }) => (
          <Tag variant={cell.row.values.incidentStatus}>
            {cell.row.values.incidentStatus}
          </Tag>
        ),
      },
    ],
    []
  );

  const handlePagination = useCallback(
    (pageIndex: number, pageSize: number) => {
      setPage(pageIndex);
      setPageSize(pageSize);
    },
    []
  );

  const handleSort = useCallback((sort: { id: string; desc?: boolean }) => {
    setOrder(sort.id);
    setOrderBy(sort.desc ? "desc" : "asc");
  }, []);

  const handleFilter = (stat: string) => {
    if (filter === stat) setFilter("");
    else setFilter(stat);
  };

  const handleExport = useCallback(() => {
    exportReports({ dateFilter, pageIndex: page, pageSize });
  }, [dateFilter, exportReports, page, pageSize]);

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Dashboard</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>Reported Cases</Heading>
      </Flex>
      <Grid
        templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gap={4}
        my={6}
      >
        <CasesCard
          title="NEW CASES"
          number={statistics?.newCases}
          color="#3DCDB5"
          bg="#D8F5F05C"
          Icon={NewCases}
          flex={1}
          isActive={filter === "newCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("newCases")}
        />
        <CasesCard
          title="UNASSIGNED CASES"
          number={statistics?.unassignedCases}
          color="#B13D3D"
          bg="#FFCCCC3D"
          Icon={UnassignedCases}
          flex={1}
          isActive={filter === "unassignedCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("unassignedCases")}
        />
        <CasesCard
          title="ONGOING CASES"
          number={statistics?.ongoingCases}
          color="#CFAE89"
          bg="#F5EFE7"
          Icon={OngoingCases}
          flex={1}
          isActive={filter === "ongoingCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("ongoingCases")}
        />
        <CasesCard
          title="PENDING CASES"
          number={statistics?.pendingCases}
          color="#EFC14A"
          bg="#FFF5003D"
          Icon={PendingCases}
          flex={1}
          isActive={filter === "pendingCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("pendingCases")}
        />
        <CasesCard
          title="CLOSED CASES"
          number={statistics?.closedCases}
          color="#2ED2E9"
          bg="#D8F2F58C"
          Icon={ClosedCases}
          flex={1}
          isActive={filter === "closedCases"}
          role="button"
          cursor="pointer"
          onClick={() => handleFilter("closedCases")}
        />
      </Grid>
      {tableData && (
        <DataTable
          handlePagination={handlePagination}
          handleSort={handleSort}
          loading={isFetchingOverallReports}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          filter={filter}
          query={query}
          setQuery={setQuery}
          statistics={statistics}
          setFilter={setFilter}
          isExporting={isExporting}
          handleExport={handleExport}
          ExportComponent={
            <>
              <Flex
                gap={4}
                align="center"
                justify="space-between"
                w={{ base: "full", sm: "auto" }}
              >
                <Text fontSize="md">Start date</Text>
                <Input
                  type="date"
                  placeholder="Start Date"
                  onChange={e =>
                    setDateFilter(prev => ({
                      ...prev,
                      startDate: e.target.value,
                    }))
                  }
                  value={dateFilter.startDate}
                  w={{ sm: "max-content" }}
                />
              </Flex>
              <Flex
                gap={4}
                align="center"
                justify="space-between"
                w={{ base: "full", sm: "auto" }}
              >
                <Text fontSize="md">End date</Text>
                <Input
                  type="date"
                  placeholder="End Date"
                  onChange={e =>
                    setDateFilter(prev => ({
                      ...prev,
                      endDate: e.target.value,
                    }))
                  }
                  value={dateFilter.endDate}
                  w={{ sm: "max-content" }}
                />
              </Flex>
            </>
          }
        />
      )}
    </Box>
  );
};
