import React from "react";
import { Box, BoxProps, Divider, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import { Report } from "../../interfaces/Report";
import { ReportActionRes } from "../../interfaces/ReportAction";
import AudioPlayer from "../common/AudioPlayer";
import Tag from "../common/Tag";
import AttachmentsView from "./AttachmentsView";
import getUserRole from "../../helpers/getUserRole";
import { useAuth } from "../../context/useAuth";

interface DetailsCardProps extends BoxProps {
  report?: Report;
  moreInfo?: ReportActionRes[];
}

const DetailsCard = ({ report, moreInfo, ...props }: DetailsCardProps) => {
  const voiceNotes = report?.reportAttachments?.filter(
    attachment => attachment.fileStore?.fileType === "audio"
  );
  const otherAttachments = report?.reportAttachments?.filter(
    file => file.fileStore?.fileType !== "audio"
  );
  const auth = useAuth();
  const { isAgencyUser, isAgencyAdmin, isOverallAdmin } = getUserRole(
    auth?.user?.roles
  );

  return (
    <Box
      border="1px solid #D4D4D4"
      borderRadius="10px"
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      p={4}
      pb={10}
      fontWeight="semibold"
      {...props}
    >
      <Flex justify="space-between" align="center" wrap="wrap">
        <Text color="textSecondary" my={4} fontSize="md">
          Case Details
        </Text>
        <Tag variant={report?.reportStatusName}>{report?.reportStatusName}</Tag>
      </Flex>
      <Divider bg="#D4D4D4" my={4} />
      <Box fontSize="xs" mb={10}>
        <Flex my={2} gap={{ base: 1, sm: 10 }}>
          <Text maxW="90px" minW="90px">
            Case Number
          </Text>
          <Text color="textSecondary">{report?.caseNumber}</Text>
        </Flex>
        <Flex my={2} gap={{ base: 1, sm: 10 }}>
          <Text maxW="90px" minW="90px">
            Incident
          </Text>
          <Text color="textSecondary">{report?.incidenceType?.name}</Text>
        </Flex>
        {(!!isAgencyUser || !!isAgencyAdmin || !!isOverallAdmin) && (
          <>
            {report?.userProfile ? (
              <>
                <Flex my={2} gap={{ base: 1, sm: 10 }}>
                  <Text maxW="90px" minW="90px">
                    First Name
                  </Text>
                  <Text color="textSecondary">
                    {report?.userProfile.firstName}
                  </Text>
                </Flex>
                <Flex my={2} gap={{ base: 1, sm: 10 }}>
                  <Text maxW="90px" minW="90px">
                    Last Name
                  </Text>
                  <Text color="textSecondary">
                    {report?.userProfile.lastName}
                  </Text>
                </Flex>
                <Flex my={2} gap={{ base: 1, sm: 10 }}>
                  <Text maxW="90px" minW="90px">
                    Email
                  </Text>
                  <Text color="textSecondary">{report?.userProfile.email}</Text>
                </Flex>
                <Flex my={2} gap={{ base: 1, sm: 10 }}>
                  <Text maxW="90px" minW="90px">
                    Phone Number
                  </Text>
                  <Text color="textSecondary">
                    {report?.userProfile.phoneNumber}
                  </Text>
                </Flex>
                {report?.userProfile.birthdate && (
                  <Flex my={2} gap={{ base: 1, sm: 10 }}>
                    <Text maxW="90px" minW="90px">
                      Date of Birth
                    </Text>
                    <Text color="textSecondary">
                      {report?.userProfile.birthdate}
                    </Text>
                  </Flex>
                )}
                {report?.userProfile.gender && (
                  <Flex my={2} gap={{ base: 1, sm: 10 }}>
                    <Text maxW="90px" minW="90px">
                      Gender
                    </Text>
                    <Text color="textSecondary">
                      {report?.userProfile.gender}
                    </Text>
                  </Flex>
                )}
              </>
            ) : (
              <Flex my={2} gap={{ base: 1, sm: 10 }}>
                <Text maxW="90px" minW="90px">
                  Reporter
                </Text>
                <Text color="textSecondary">Anonymous</Text>
              </Flex>
            )}
          </>
        )}
        {report?.dateOfIncidence && (
          <Flex my={2} gap={{ base: 1, sm: 10 }}>
            <Text maxW="90px" minW="90px">
              Date
            </Text>
            <Text color="textSecondary">{report?.dateOfIncidence}</Text>
          </Flex>
        )}
        {report?.time && (
          <Flex my={2} gap={{ base: 1, sm: 10 }}>
            <Text maxW="90px" minW="90px">
              Time
            </Text>
            <Text color="textSecondary">{report?.time}</Text>
          </Flex>
        )}
        {report?.location?.map(
          (location, index) =>
            location.isReportLocation && (
              <React.Fragment key={index}>
                {location.address && (
                  <Flex my={2} gap={{ base: 1, sm: 10 }}>
                    <Text maxW="90px" minW="90px">
                      Address
                    </Text>
                    <Text color="textSecondary">{location.address}</Text>
                  </Flex>
                )}
                {location.state && (
                  <Flex my={2} gap={{ base: 1, sm: 10 }}>
                    <Text maxW="90px" minW="90px">
                      State
                    </Text>
                    <Text color="textSecondary">{location.state}</Text>
                  </Flex>
                )}
                {location.city && (
                  <Flex my={2} gap={{ base: 1, sm: 10 }}>
                    <Text maxW="90px" minW="90px">
                      City
                    </Text>
                    <Text color="textSecondary">{location.city}</Text>
                  </Flex>
                )}
              </React.Fragment>
            )
        )}
        <Flex my={2} gap={{ base: 1, sm: 10 }}>
          <Text maxW="90px" minW="90px">
            Owned By
          </Text>
          <Text color="textSecondary">{report?.ownedBy}</Text>
        </Flex>
        <Flex my={2} gap={{ base: 1, sm: 10 }}>
          <Text maxW="90px" minW="90px">
            Reported As
          </Text>
          <Text color="textSecondary">{report?.reporterType}</Text>
        </Flex>
        {report?.agentAssigned && (
          <>
            <Flex my={2} gap={{ base: 1, sm: 10 }}>
              <Text maxW="90px" minW="90px">
                Agent Assigned
              </Text>
              <Text color="textSecondary">{report?.agentAssigned}</Text>
            </Flex>
            <Flex my={2} gap={{ base: 1, sm: 10 }}>
              <Text maxW="90px" minW="90px">
                Date Assigned
              </Text>
              <Text color="textSecondary">
                {moment(report?.dateAssigned).format("YYYY-MM-DD")}
              </Text>
            </Flex>
          </>
        )}
        {report?.reportData?.map(data => (
          <Flex my={2} gap={{ base: 1, sm: 10 }} key={data.id}>
            <Text maxW="90px" minW="90px">
              {data.question?.title}
            </Text>
            <Text color="textSecondary">{data.value}</Text>
          </Flex>
        ))}
      </Box>
      <Box my={4} fontSize="sm">
        <Text fontSize="md" color="textSecondary" mb={4}>
          Description
        </Text>
        {report?.incidentDescription && (
          <Text fontWeight="normal" mb={4}>
            {report?.incidentDescription}
          </Text>
        )}
        {voiceNotes?.map((voiceNote, index) => (
          <AudioPlayer
            key={index}
            w={{ base: "full", sm: "90%", md: "80%" }}
            recording={voiceNote?.fileStore}
          />
        ))}
      </Box>
      {!!otherAttachments?.length && (
        <Box my={4}>
          <Text fontSize="md" color="textSecondary" mb={4}>
            Media Files
          </Text>
          <Flex gap={4} flexWrap="wrap" justify="space-between">
            {otherAttachments?.map(file => (
              <AttachmentsView
                key={file.fileId}
                file={file.fileStore}
                w={{ base: "full", sm: "calc(100% / 4 - 1rem)" }}
              />
            ))}
          </Flex>
        </Box>
      )}
      {!!moreInfo?.length && (
        <>
          <Text mt={20} fontSize="lg" color="textSecondary" mb={4}>
            Additional Information
          </Text>
          {moreInfo.map(info => (
            <Box key={info.id} mb={10}>
              <Box my={4} fontSize="sm">
                <Text fontSize="md" color="textSecondary" mb={4}>
                  Note
                </Text>
                <Text fontWeight="normal">{info.note}</Text>
              </Box>

              {!!info.reportsAdditionalInfoAttachment?.length && (
                <Box my={4}>
                  <Text fontSize="md" color="textSecondary" mb={4}>
                    Media Files
                  </Text>
                  <Flex gap={4} flexWrap="wrap" justify="space-between">
                    {info.reportsAdditionalInfoAttachment?.map(file => (
                      <AttachmentsView
                        key={file.fileId}
                        file={file.file}
                        w={{ base: "full", sm: "calc(100% / 4 - 1rem)" }}
                      />
                    ))}
                  </Flex>
                </Box>
              )}
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default DetailsCard;
