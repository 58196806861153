import {
  FormControl,
  InputProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorIcon,
  Spinner,
  Flex,
  Text,
} from "@chakra-ui/react";
import ErrorIcon from "../svg/ErrorIcon";

interface FormInputProps extends InputProps {
  form: any;
  label: string;
  id: string;
  options?: any;
  type?: string;
  subtitle?: string;
  isLoading?: boolean;
}

const FormInput = ({
  form,
  label,
  subtitle,
  id,
  options,
  type,
  isLoading,
  ...props
}: FormInputProps) => {
  return (
    <FormControl my={4} isInvalid={form.formState.errors[id]}>
      <Flex align="center" justify="space-between">
        <Flex gap={2} wrap="wrap" mb={2} align="baseline">
          <FormLabel
            m={0}
            fontWeight="semibold"
            fontSize="sm"
            color="textSecondary"
            htmlFor={id}
          >
            {label}
          </FormLabel>
          {subtitle && <Text fontSize="xs">{subtitle}</Text>}
        </Flex>
        {isLoading && <Spinner color="primary.500" size="sm" />}
      </Flex>
      {!type || type === "text" || type === "password" ? (
        <InputGroup>
          <Input
            _invalid={{
              bg: "#FFCCCC",
              border: "1px solid #B13D3D",
              boxShadow: "0 0 0 1px #e53e3e",
            }}
            id={id}
            type={type}
            {...form.register(id, options)}
            {...props}
          />
          <InputRightElement>
            <FormErrorIcon as={ErrorIcon} fontSize="20px" />
          </InputRightElement>
        </InputGroup>
      ) : (
        <Input
          _invalid={{
            bg: "#FFCCCC",
            border: "1px solid #B13D3D",
            boxShadow: "0 0 0 1px #e53e3e",
          }}
          type={type}
          id={id}
          {...form.register(id, options)}
          {...props}
        />
      )}
      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormInput;
