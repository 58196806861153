import { Icon, IconProps } from "@chakra-ui/react";

const Logout = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="currentcolor" {...props}>
      <path
        d="M11.43 16.7025H11.3325C8.0025 16.7025 6.3975 15.39 6.12 12.45C6.09 12.1425 6.315 11.865 6.63 11.835C6.93 11.805 7.215 12.0375 7.245 12.345C7.4625 14.7 8.5725 15.5775 11.34 15.5775H11.4375C14.49 15.5775 15.57 14.4975 15.57 11.445V6.55499C15.57 3.50249 14.49 2.42249 11.4375 2.42249H11.34C8.5575 2.42249 7.4475 3.31499 7.245 5.71499C7.2075 6.02249 6.945 6.25499 6.63 6.22499C6.315 6.20249 6.09 5.92499 6.1125 5.61749C6.3675 2.63249 7.98 1.29749 11.3325 1.29749H11.43C15.1125 1.29749 16.6875 2.87249 16.6875 6.55499V11.445C16.6875 15.1275 15.1125 16.7025 11.43 16.7025Z"
        fill="#424D44"
      />
      <path
        d="M11.25 9.5625H2.715C2.4075 9.5625 2.1525 9.3075 2.1525 9C2.1525 8.6925 2.4075 8.4375 2.715 8.4375H11.25C11.5575 8.4375 11.8125 8.6925 11.8125 9C11.8125 9.3075 11.5575 9.5625 11.25 9.5625Z"
        fill="#424D44"
      />
      <path
        d="M4.3875 12.075C4.245 12.075 4.1025 12.0225 3.99 11.91L1.4775 9.3975C1.26 9.18 1.26 8.82001 1.4775 8.60251L3.99 6.09C4.2075 5.8725 4.5675 5.8725 4.785 6.09C5.0025 6.3075 5.0025 6.66751 4.785 6.88501L2.67 9.00001L4.785 11.115C5.0025 11.3325 5.0025 11.6925 4.785 11.91C4.68 12.0225 4.53 12.075 4.3875 12.075Z"
        fill="#424D44"
      />
    </Icon>
  );
};

export default Logout;
