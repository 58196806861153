import { Icon, IconProps } from "@chakra-ui/react";

const NewCases = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 21 21" stroke="currentcolor" fill="none" {...props}>
      <g clipPath="url(#clip0_877_16362)">
        <path
          d="M9.37908 2.42148C9.67133 1.8294 10.5156 1.8294 10.8079 2.42148L12.9855 6.83317C13.1015 7.06807 13.3255 7.23096 13.5847 7.26885L18.4558 7.98083C19.109 8.07631 19.3694 8.87925 18.8964 9.33986L15.373 12.7717C15.1851 12.9547 15.0993 13.2185 15.1437 13.4771L15.975 18.3244C16.0867 18.9752 15.4035 19.4715 14.819 19.1642L10.4643 16.8741C10.2322 16.752 9.9548 16.752 9.72266 16.8741L5.36797 19.1642C4.78349 19.4715 4.10029 18.9752 4.21192 18.3244L5.04329 13.4771C5.08763 13.2185 5.00186 12.9547 4.81394 12.7717L1.29051 9.33986C0.817599 8.87925 1.07794 8.07631 1.73116 7.98083L6.60225 7.26885C6.86146 7.23096 7.08547 7.06807 7.20142 6.83317L9.37908 2.42148Z"
          strokeWidth="1.39421"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_877_16362">
          <rect
            width="19.9173"
            height="19.9173"
            transform="translate(0.13501 0.892914)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default NewCases;
