import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useUploads } from "../../context/useUploads";
import Upload from "../common/Upload";
import FilePreview from "../common/FilePreview";
import uploadLimit from "../../utils/uploadLimit";

const Attachments = ({ ...props }: BoxProps) => {
  const { files, setFiles, uploads } = useUploads();
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const list = e.target.files;
    if (!list || list.length < 1) return;
    const filtered = [...list].filter(file => {
      const limit = uploadLimit(file);
      if (limit?.isExceeded) {
        toast({
          title: "Upload Failed",
          description: limit.type + " size must be less than " + limit.size,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
      return true;
    });
    setFiles(prev => [...prev, ...filtered]);
    e.target.value = "";
  };

  return (
    <Box
      border="1px solid #D4D4D4"
      borderRadius="10px"
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      p={4}
      minH="244px"
      {...props}
    >
      <Box>
        <Flex justify="space-between" align="center" gap={4}>
          <Text
            color="textSecondary"
            my={4}
            fontSize="md"
            fontWeight="semibold"
          >
            Add Attachment
          </Text>
          <Input
            type="file"
            hidden
            id="files"
            multiple
            ref={inputRef}
            onChange={onSelectFile}
            accept="image/*,audio/*,video/*"
          />
          <Button
            fontSize="sm"
            variant="outline"
            leftIcon={<AddIcon fontSize="12px" />}
            px={10}
            onClick={() => inputRef.current?.click()}
          >
            Add Files
          </Button>
        </Flex>
        <Divider bg="#D4D4D4" my={4} />
      </Box>

      <Flex flexWrap="wrap" align="center" justify="space-evenly" gap={4}>
        {uploads &&
          uploads.length !== 0 &&
          uploads.map(file => <FilePreview key={file.id} file={file} />)}
      </Flex>

      <Flex flexDir="column" gap={4}>
        {files &&
          files.length !== 0 &&
          files.map((file, index) => <Upload key={index} file={file} />)}
      </Flex>
    </Box>
  );
};

export default Attachments;
