import React, { createContext, useContext, useState } from "react";
import { FileStore } from "../interfaces/FileUpload";

interface IUploadContext {
  uploads: FileStore[];
  setUploads: React.Dispatch<React.SetStateAction<FileStore[]>>;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const UploadContext = createContext<IUploadContext>({} as IUploadContext);

export const UploadProvider = ({ children }: { children: React.ReactNode }) => {
  const [uploads, setUploads] = useState<FileStore[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  return (
    <UploadContext.Provider value={{ uploads, setUploads, files, setFiles }}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUploads = () => useContext(UploadContext);
