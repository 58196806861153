import { extendTheme } from "@chakra-ui/react";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import Input from "./components/input";
import Radio from "./components/radio";
import breakpoints from "./foundations/breakpoints";
import styles from "./styles";

const overrides = {
  styles,

  breakpoints,

  colors: {
    primary: {
      50: "#D8F5F0",
      100: "#BEEEE6",
      200: "#9EE6DA",
      300: "#7EDECE",
      400: "#5DD5C1",
      500: "#3DCDB5",
      600: "#33AB97",
      700: "#298979",
      800: "#1F675B",
      900: "#14443C",
      1000: "#0C2924",
    },
    secondary: {
      50: "#F5EFE7",
      100: "#EFE4D8",
      200: "#E7D6C4",
      300: "#DFC9B0",
      400: "#D7BB9D",
      500: "#CFAE89",
      600: "#AC9172",
      700: "#8A745B",
      800: "#685745",
      900: "#453A2E",
      1000: "#29231B",
    },
    text: "#A0A6A1",
    textSecondary: "#424D44",
  },

  fonts: {
    heading: "Open Sans, sans serif",
    body: "Open Sans, sans serif",
  },

  components: {
    Button,
    Input,
    Checkbox,
    Radio,
  },
};

export default extendTheme(overrides);
