import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { IncidenceType } from "../../interfaces/IncidenceType";
import { SuccessStory } from "../../interfaces/SuccessStory";
import successStoriesService from "../../services/successStoriesService";
import FormInput from "../common/FormInput";
import FormSelect from "../common/FormSelect";
import FormTextarea from "../common/FormTextarea";

interface NewStoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  incidences: IncidenceType[];
}

const NewStoryModal = ({ incidences, isOpen, onClose }: NewStoryModalProps) => {
  const form = useForm();
  const toast = useToast();
  const mutation = useMutation((story: SuccessStory) => {
    return successStoriesService.postSuccessStory(story);
  });

  function onSubmit(values: any) {
    const story: SuccessStory = {
      title: values.name,
      description: values.story,
      incidenceTypeId: Number(values.incident),
      date: values.date,
    };
    mutation.mutate(story);
  }

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to submit story",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      form.reset();
      onClose();
      toast({
        title: "Success story has been submitted",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isSuccess, toast, onClose, form]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md" fontWeight="bold" color="textSecondary">
          SUCCESS STORY
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormInput
              form={form}
              label="Name"
              id="name"
              options={{ required: "Name is required" }}
            />
            <Flex
              flexDir={{ base: "column", sm: "row" }}
              gap={{ base: 0, sm: 6 }}
            >
              <FormSelect
                form={form}
                label="Incident"
                id="incident"
                options={{ required: "Incident is required" }}
              >
                <option value=""></option>
                {incidences &&
                  incidences.map(incidence => (
                    <option key={incidence.id} value={incidence.id}>
                      {incidence.name}
                    </option>
                  ))}
              </FormSelect>
              <FormInput
                form={form}
                label="Date"
                id="date"
                type="date"
                options={{ required: "Date is required" }}
              />
            </Flex>
            <FormTextarea
              form={form}
              label="Story"
              id="story"
              options={{ required: "Story is required" }}
              minH="100px"
            />
            <Button
              w="200px"
              my={4}
              p={2}
              isLoading={form.formState.isSubmitting || mutation.isLoading}
              type="submit"
              disabled={form.formState.isSubmitting || mutation.isLoading}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewStoryModal;
