import { Icon, IconProps } from "@chakra-ui/react";

const UnassignedCases = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="currentcolor" {...props}>
      <path d="M16.2312 18.5952L14.2594 16.6234H3.22523V14.7512C3.22523 14.2466 3.35137 13.8151 3.60366 13.4565C3.85594 13.098 4.18126 12.8258 4.57961 12.6399C5.3763 12.2814 6.14311 12.0026 6.88005 11.8034C7.61699 11.6042 8.35725 11.4914 9.10083 11.4648L1.25342 3.6174L2.10986 2.76096L17.0877 17.7388L16.2312 18.5952ZM4.42027 15.4284H13.0644L10.2959 12.6599C10.1896 12.6466 10.0768 12.6399 9.95728 12.6399H9.59877C8.84191 12.6399 8.10497 12.7163 7.38795 12.869C6.67092 13.0217 5.89415 13.3038 5.05762 13.7155C4.87173 13.8084 4.71903 13.9512 4.59952 14.1437C4.48002 14.3362 4.42027 14.5387 4.42027 14.7512V15.4284ZM14.598 12.6399C15.0096 12.8258 15.3416 13.098 15.5939 13.4565C15.8462 13.8151 15.9723 14.2466 15.9723 14.7512V14.9105L13.1241 12.0623C13.3631 12.142 13.6055 12.2316 13.8511 12.3312C14.0968 12.4308 14.3457 12.5337 14.598 12.6399ZM10.993 9.93118L10.0369 8.97515C10.4353 8.88221 10.7606 8.67639 11.0129 8.35772C11.2652 8.03904 11.3913 7.66725 11.3913 7.24235C11.3913 6.73778 11.2187 6.31287 10.8735 5.96764C10.5282 5.62241 10.1033 5.44979 9.59877 5.44979C9.17386 5.44979 8.80207 5.57593 8.4834 5.82822C8.16472 6.08051 7.95891 6.40582 7.86596 6.80417L6.90993 5.84814C7.16222 5.34357 7.53069 4.95186 8.01534 4.67302C8.5 4.39417 9.0278 4.25475 9.59877 4.25475C10.4353 4.25475 11.1424 4.54355 11.72 5.12116C12.2976 5.69876 12.5864 6.40582 12.5864 7.24235C12.5864 7.81331 12.4469 8.34112 12.1681 8.82577C11.8893 9.31043 11.4975 9.6789 10.993 9.93118ZM13.0644 15.4284H4.42027H13.0644Z" />
    </Icon>
  );
};

export default UnassignedCases;
