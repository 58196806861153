import { Icon, IconProps } from "@chakra-ui/react";

const GooglePlayStore = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 30 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600754 0.555936C0.222357 0.987383 0 1.65557 0 2.52266V33.4776C0 34.3447 0.222357 35.0129 0.600754 35.4443L0.696979 35.5452L16.7938 18.2046V17.7956L0.696979 0.455078L0.600754 0.555936Z"
        fill="#00D7FE"
      />
      <mask
        id="mask0_336_2101"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="12"
        width="14"
        height="12"
      >
        <path
          d="M22.1577 23.9871L16.7938 18.2045V17.7955L22.1603 12.0144L28.6386 15.9787C30.4538 17.0909 30.4538 18.9092 28.6386 20.02L22.2812 23.9114L22.1577 23.9871Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_336_2101)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1577 23.9871L16.7938 18.2045V17.7955L22.1603 12.0144L28.6386 15.9787C30.4538 17.0909 30.4538 18.9092 28.6386 20.02L22.2812 23.9114L22.1577 23.9871Z"
          fill="url(#paint0_linear_336_2101)"
        />
      </g>
      <mask
        id="mask1_336_2101"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="18"
        width="23"
        height="18"
      >
        <path
          d="M22.2812 23.9114L16.7938 18L0.600708 35.4442C1.20016 36.1264 2.18842 36.2104 3.3015 35.5296L22.2812 23.9114Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_336_2101)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2812 23.9114L16.7938 18L0.600708 35.4442C1.20016 36.1264 2.18842 36.2104 3.3015 35.5296L22.2812 23.9114Z"
          fill="url(#paint1_linear_336_2101)"
        />
      </g>
      <mask
        id="mask2_336_2101"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="18"
      >
        <path
          d="M22.2812 12.0886L3.30156 0.470364C2.18848 -0.210425 1.20022 -0.126377 0.600769 0.555813L16.7938 18L22.2812 12.0886Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_336_2101)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2812 12.0886L3.30156 0.470364C2.18848 -0.210425 1.20022 -0.126377 0.600769 0.555813L16.7938 18L22.2812 12.0886Z"
          fill="url(#paint2_linear_336_2101)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_336_2101"
          x1="27.4576"
          y1="18.0005"
          x2="16.7938"
          y2="18.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC00" />
          <stop offset="1" stopColor="#FFDA03" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_336_2101"
          x1="19.299"
          y1="23.5623"
          x2="4.556"
          y2="34.8429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E22652" />
          <stop offset="1" stopColor="#FF3A44" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_336_2101"
          x1="3.05277"
          y1="2.65585"
          x2="12.9278"
          y2="10.7162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ED574" />
          <stop offset="1" stopColor="#07F076" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default GooglePlayStore;
