import {
  Box,
  Button,
  Flex,
  IconButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FiTrash } from "react-icons/fi";
import { useMutation } from "react-query";
import { Column } from "react-table";
import DeleteConfirmationModal from "../../../components/agencies/DeleteConfirmationModal";
import Heading from "../../../components/common/Heading";
import Link from "../../../components/common/Link";
import Table from "../../../components/common/Table";
import { Agency } from "../../../interfaces/Agency";
import agenciesService from "../../../services/agenciesService";

export const Agencies = () => {
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [agencyId, setAgencyId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useMutation((agencyId: number) => {
    return agenciesService.deleteAgency(agencyId);
  });

  const tableData = React.useMemo(() => agencies, [agencies]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Short Code",
        accessor: "shortCode",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell }) => (
          <IconButton
            onClick={() => {
              setAgencyId(cell.row.values.id);
              onOpen();
            }}
            variant="ghost"
            size="sm"
            aria-label="delete agency"
            color="#FF0000"
            icon={<FiTrash />}
          />
        ),
      },
    ],
    [onOpen]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        agenciesService
          .getAgencies(pageIndex + 1, pageSize)
          .then(res => {
            setAgencies(res.data.body.data);
            setTotalCount(res.data.body.totalCount);
            setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
          })
          .catch(err => {
            toast({
              title: "Failed to fetch agencies",
              description: err?.response?.data?.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            console.error(err.response);
          })
          .finally(() => setLoading(false));
      }
    },
    [toast]
  );

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to delete agency",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      fetchData({ pageIndex: 0, pageSize: 10 });
      onClose();
      toast({
        title: "Agency has been deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isSuccess, toast, onClose, fetchData]);

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Agencies</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>Agencies</Heading>
        <Link to="/dashboard/agencies/create" _hover={{ textDecor: "none" }}>
          <Button variant="outline">Create New Agency</Button>
        </Link>
      </Flex>

      {tableData && (
        <Table
          route="/dashboard/agencies"
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Agencies"
          isAgencies
        />
      )}
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        callback={() => {
          if (agencyId) mutation.mutate(agencyId);
        }}
        isLoading={mutation.isLoading}
        heading="Delete Agency"
        confirmation="Are you sure you want to delete this agency?"
      />
    </Box>
  );
};
