import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
  return (
    <AccordionItem
      borderTopWidth={0}
      borderBottomWidth="1px"
      borderColor="text"
      my={10}
    >
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton fontWeight="semibold" color="textSecondary" py={4}>
              <Box flex="1" textAlign="left">
                {question}
              </Box>
              {isExpanded ? (
                <MinusIcon color="textSecondary" fontSize="16px" />
              ) : (
                <AddIcon color="textSecondary" fontSize="16px" />
              )}
            </AccordionButton>
          </h2>
          <AccordionPanel
            dangerouslySetInnerHTML={{ __html: answer }}
            color="textSecondary"
            pb={4}
          ></AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default FAQItem;
