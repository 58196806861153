import { Icon, IconProps } from "@chakra-ui/react";

const NoData = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 200 200" fill="currentcolor" {...props}>
      <g clipPath="url(#clip0_84_1027)">
        <path
          d="M126.968 44.9793H73.0766C71.8485 44.9809 70.6711 45.4813 69.8027 46.371C68.9343 47.2606 68.4458 48.4667 68.4443 49.7249V172.428L67.8267 172.621L54.6063 176.769C53.9797 176.965 53.303 176.898 52.7246 176.583C52.1462 176.268 51.7135 175.731 51.5213 175.089L12.1967 43.4956C12.0053 42.8537 12.0705 42.1602 12.378 41.5676C12.6855 40.9749 13.2101 40.5316 13.8365 40.335L34.2091 33.9444L93.27 15.4242L113.643 9.03353C113.953 8.93578 114.278 8.90162 114.601 8.93301C114.924 8.9644 115.238 9.06072 115.524 9.21646C115.811 9.37221 116.065 9.58431 116.271 9.84064C116.478 10.097 116.633 10.3925 116.728 10.7103L126.78 44.3466L126.968 44.9793Z"
          fill="#F2F2F2"
        />
        <path
          d="M138.728 44.3467L126.613 3.80719C126.412 3.13173 126.082 2.50357 125.644 1.95862C125.205 1.41367 124.666 0.962599 124.057 0.63119C123.448 0.29978 122.781 0.0945217 122.095 0.0271514C121.409 -0.0402189 120.716 0.0316191 120.057 0.238553L91.4145 9.22026L32.3567 27.7437L3.71411 36.7285C2.38352 37.1471 1.26941 38.0894 0.616305 39.3486C-0.0367959 40.6079 -0.175524 42.0811 0.230578 43.445L41.6366 181.992C41.9665 183.093 42.6321 184.057 43.5358 184.742C44.4396 185.427 45.5338 185.797 46.658 185.798C47.1783 185.799 47.6956 185.719 48.1927 185.561L67.8272 179.404L68.4448 179.208V178.547L67.8272 178.74L48.0106 184.957C46.8361 185.324 45.5675 185.198 44.4832 184.608C43.3989 184.018 42.5873 183.011 42.2264 181.809L0.82363 43.2584C0.644854 42.6626 0.582546 42.0366 0.640271 41.4161C0.697997 40.7957 0.874617 40.193 1.16003 39.6426C1.44544 39.0922 1.83404 38.6048 2.30357 38.2085C2.77309 37.8121 3.31431 37.5145 3.89625 37.3328L32.5389 28.3479L91.5968 9.82769L120.239 0.842823C120.681 0.704823 121.14 0.634459 121.601 0.634019C122.592 0.636297 123.555 0.963408 124.351 1.56744C125.147 2.17147 125.733 3.02068 126.023 3.99069L138.083 44.3467L138.274 44.9794H138.917L138.728 44.3467Z"
          fill="#3F3D56"
        />
        <path
          d="M37.8858 40.4385C37.2905 40.438 36.7111 40.2422 36.2325 39.8797C35.7539 39.5172 35.4013 39.0071 35.2264 38.4243L31.2488 25.1141C31.1419 24.7565 31.1048 24.3809 31.1396 24.0087C31.1745 23.6365 31.2805 23.275 31.4518 22.9447C31.623 22.6145 31.8561 22.3221 32.1376 22.0841C32.4192 21.8462 32.7438 21.6674 33.0928 21.558L87.4255 4.51721C88.1303 4.29687 88.8916 4.37202 89.5425 4.72616C90.1933 5.0803 90.6803 5.6845 90.8968 6.40615L94.8744 19.7165C95.0894 20.4386 95.0159 21.2185 94.6703 21.8852C94.3246 22.5519 93.7349 23.0509 93.0306 23.2727L38.6977 40.3135C38.4346 40.3962 38.161 40.4384 37.8858 40.4385Z"
          fill="#B13D3D"
        />
        <path
          d="M58.7222 14.2222C62.1333 14.2222 64.8985 11.3894 64.8985 7.89487C64.8985 4.40036 62.1333 1.5675 58.7222 1.5675C55.3111 1.5675 52.5459 4.40036 52.5459 7.89487C52.5459 11.3894 55.3111 14.2222 58.7222 14.2222Z"
          fill="#B13D3D"
        />
        <path
          d="M58.7225 11.9016C60.8825 11.9016 62.6336 10.1077 62.6336 7.89487C62.6336 5.68204 60.8825 3.88818 58.7225 3.88818C56.5625 3.88818 54.8115 5.68204 54.8115 7.89487C54.8115 10.1077 56.5625 11.9016 58.7225 11.9016Z"
          fill="white"
        />
        <path
          d="M186.103 184.182H81.7236C81.0276 184.181 80.3604 183.897 79.8683 183.393C79.3762 182.889 79.0994 182.205 79.0986 181.492V53.3631C79.0994 52.6501 79.3762 51.9666 79.8683 51.4625C80.3604 50.9583 81.0276 50.6748 81.7236 50.674H186.103C186.799 50.6748 187.466 50.9584 187.958 51.4625C188.45 51.9666 188.727 52.6502 188.728 53.3631V181.492C188.727 182.205 188.45 182.889 187.958 183.393C187.466 183.897 186.799 184.181 186.103 184.182Z"
          fill="#F2F2F2"
        />
        <path
          d="M138.083 44.3466H73.077C71.6853 44.3486 70.3511 44.9159 69.367 45.9241C68.3829 46.9323 67.8291 48.2991 67.8271 49.7249V178.74L68.4448 178.547V49.7249C68.4463 48.4668 68.9348 47.2606 69.8032 46.371C70.6716 45.4813 71.8489 44.9809 73.077 44.9793H138.274L138.083 44.3466ZM194.75 44.3466H73.077C71.6853 44.3486 70.3511 44.9159 69.367 45.9241C68.3829 46.9323 67.8291 48.2991 67.8271 49.7249V194.622C67.8291 196.048 68.3829 197.414 69.367 198.423C70.3511 199.431 71.6853 199.998 73.077 200H194.75C196.142 199.998 197.476 199.431 198.46 198.423C199.444 197.414 199.998 196.048 200 194.622V49.7249C199.998 48.2991 199.444 46.9323 198.46 45.9241C197.476 44.9159 196.142 44.3486 194.75 44.3466ZM199.382 194.622C199.381 195.88 198.892 197.086 198.024 197.976C197.156 198.865 195.978 199.366 194.75 199.367H73.077C71.8489 199.366 70.6716 198.865 69.8032 197.976C68.9348 197.086 68.4463 195.88 68.4448 194.622V49.7249C68.4463 48.4668 68.9348 47.2606 69.8032 46.371C70.6716 45.4813 71.8489 44.9809 73.077 44.9793H194.75C195.978 44.9809 197.156 45.4813 198.024 46.371C198.892 47.2606 199.381 48.4668 199.382 49.7249V194.622Z"
          fill="#3F3D56"
        />
        <path
          d="M162.325 58.2668H105.503C104.766 58.266 104.06 57.9657 103.539 57.4319C103.018 56.8981 102.724 56.1744 102.724 55.4195V41.4993C102.724 40.7444 103.018 40.0207 103.539 39.4869C104.06 38.9531 104.766 38.6528 105.503 38.652H162.325C163.062 38.6528 163.768 38.9531 164.289 39.4869C164.81 40.0207 165.104 40.7444 165.104 41.4993V55.4195C165.104 56.1744 164.81 56.8981 164.289 57.4319C163.768 57.9657 163.062 58.266 162.325 58.2668Z"
          fill="#B13D3D"
        />
        <path
          d="M133.914 39.6011C137.325 39.6011 140.09 36.7682 140.09 33.2737C140.09 29.7792 137.325 26.9463 133.914 26.9463C130.503 26.9463 127.737 29.7792 127.737 33.2737C127.737 36.7682 130.503 39.6011 133.914 39.6011Z"
          fill="#B13D3D"
        />
        <path
          d="M133.913 37.1277C135.991 37.1277 137.675 35.4022 137.675 33.2737C137.675 31.1452 135.991 29.4197 133.913 29.4197C131.836 29.4197 130.151 31.1452 130.151 33.2737C130.151 35.4022 131.836 37.1277 133.913 37.1277Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_1027">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default NoData;
