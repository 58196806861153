import { Box, BoxProps, Button, Flex, Image, Text } from "@chakra-ui/react";
import appHomeImage from "../../assets/images/app-home.png";
import Container from "../common/Container";
import Heading from "../common/Heading";
import Link from "../common/Link";
import Apple from "../svg/Apple";
import GooglePlayStore from "../svg/GooglePlayStore";

const GetTheApp = ({ ...props }: BoxProps) => {
  return (
    <Box
      h={{ base: "auto", md: "462px" }}
      pos="relative"
      py={{ base: "5rem", md: 10 }}
      bg="#14443C"
      color="white"
      {...props}
    >
      <Container h="100%">
        <Image
          src={appHomeImage}
          pos="absolute"
          top="50%"
          transform="translateY(-50%)"
          display={{ base: "none", md: "unset" }}
        />
        <Flex
          align="center"
          justify={{ base: "center", md: "normal" }}
          h="100%"
          ml={{ base: 0, md: "400px", lg: "500px" }}
        >
          <Box>
            <Heading color="white" mb={4} fontSize="45px">
              Get the App
            </Heading>
            <Text maxW="440px" mb={10}>
              The FlagIt App has provided access to justice for many in Nigeria
              who report unsavory practices by individuals and government
              officials.
            </Text>
            <Flex
              align="center"
              flexDir={{ base: "column", sm: "row" }}
              gap="3rem"
              wrap="wrap"
            >
              <Link
                to="https://apps.apple.com/ng/app/aff-flagit/id1564367316"
                isExternal
                isRouter={false}
              >
                <Button
                  h="64px"
                  minW="252px"
                  _hover={{ bg: "gray.100" }}
                  _active={{ bg: "gray.100" }}
                  _focus={{ bg: "gray.100" }}
                  leftIcon={<Apple fontSize="30px" />}
                  bg="white"
                  color="#424D44"
                  borderRadius="10px"
                >
                  Download for iPhone
                </Button>
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=com.iqubelabs.reportcorruption"
                isExternal
                isRouter={false}
              >
                <Button
                  h="64px"
                  minW="252px"
                  _hover={{ bg: "gray.100" }}
                  _active={{ bg: "gray.100" }}
                  _focus={{ bg: "gray.100" }}
                  leftIcon={<GooglePlayStore fontSize="30px" />}
                  bg="white"
                  color="#424D44"
                  borderRadius="10px"
                >
                  Download for Android
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default GetTheApp;
