import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  SwitchProps,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

interface FormSwitchProps extends SwitchProps {
  form: any;
  label: string;
  id: string;
  options?: any;
}

const FormSwitch = ({
  form,
  label,
  id,
  options,
  ...props
}: FormSwitchProps) => {
  return (
    <FormControl
      display="flex"
      alignItems="center"
      my={4}
      isInvalid={form.formState.errors[id]}
    >
      <FormLabel
        mb={0}
        fontWeight="semibold"
        fontSize="sm"
        color="textSecondary"
      >
        {label}
      </FormLabel>
      <Controller
        name={id}
        control={form.control}
        rules={options}
        render={({ field }) => (
          <Switch colorScheme="primary" {...field} {...props} />
        )}
      />

      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormSwitch;
