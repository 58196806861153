import { Box, Image, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/flagit-logo.png";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import Link from "../../components/common/Link";
import ReportCaseForm from "../../components/reportCase/ReportCaseForm";
import { UploadProvider } from "../../context/useUploads";

export const QuickReport = () => {
  return (
    <Container p={4}>
      <Helmet>
        <title>FlagIt | Quick Report</title>
      </Helmet>
      <Box my={{ base: 0, sm: 8 }}>
        <Box w="92px">
          <Link to="/">
            <Image src={logo} />
          </Link>
        </Box>
      </Box>
      <Box my={8} textAlign="center">
        <Heading mb={3} fontSize="45px">
          Quick Report
        </Heading>
        <Text fontSize="md" color="textSecondary">
          Flag your experience for quick and easy resolution
        </Text>
      </Box>
      <UploadProvider>
        <ReportCaseForm maxW="1000px" mx="auto" />
      </UploadProvider>
    </Container>
  );
};
