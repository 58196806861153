import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface ProtectedProps {
  isAllowed: boolean;
  redirectPath: string;
  children?: React.ReactNode;
}

const Protected = ({
  isAllowed,
  redirectPath = "/",
  children,
}: ProtectedProps) => {
  const location = useLocation();
  if (!isAllowed) {
    return (
      <Navigate to={redirectPath} replace state={{ from: location.pathname }} />
    );
  }

  return children ? <>{children}</> : <Outlet />;
};
export default Protected;
