import { Box, BoxProps, Button, Divider, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import getUserRole from "../../helpers/getUserRole";
import { useAuth } from "../../context/useAuth";
import { SuccessStory } from "../../interfaces/SuccessStory";
import Tag from "../common/Tag";

interface StoryCardProps extends BoxProps {
  story: any;
  onOpen: () => void;
  setStory: React.Dispatch<React.SetStateAction<SuccessStory | undefined>>;
}

const StoryCard = ({ story, onOpen, setStory, ...props }: StoryCardProps) => {
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);
  const openModal = () => {
    setStory(story);
    onOpen();
  };

  return (
    <Flex
      flexDir="column"
      h="180px"
      p={2}
      boxShadow="0px 0px 16px 0px #D4D4D454"
      borderRadius="5px"
      fontSize="sm"
      {...props}
    >
      <Flex justify="space-between">
        <Box>
          <Text color="textSecondary" fontWeight="bold">
            {story.title}
          </Text>
          <Text>
            {moment(story.date).format("Do, MMMM")} - {story.incidenceType.name}
          </Text>
        </Box>
        <Button
          size="xs"
          onClick={openModal}
          color="primary.500"
          variant="ghost"
        >
          View Story
        </Button>
      </Flex>
      <Divider bg="#D4D4D4" my={2} />
      <Text>
        {story.description.length < 145
          ? story.description
          : `${story.description.substring(0, 145)}...`}
      </Text>
      {!!isOverallAdmin && (
        <Flex flex="1 1 auto" align="end" justify="end">
          {story?.aprovalStatus ? (
            <Tag h="max-content" variant="approved">
              Approved
            </Tag>
          ) : (
            <Tag h="max-content" variant="disapproved">
              Disapproved
            </Tag>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default StoryCard;
