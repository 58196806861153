import { Box, Flex, Grid, Select, Text, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { useAuth } from "../../context/useAuth";
import { Report } from "../../interfaces/Report";
import reportsService from "../../services/reportsService";
import Heading from "../common/Heading";
import Table from "../common/Table";
import Tag from "../common/Tag";
import ActiveCases from "../svg/ActiveCases";
import CaseSubmitted from "../svg/CaseSubmitted";
import PendingCases from "../svg/PendingCases";
import CasesCard from "./CasesCard";

export const AgencyUserDashboard = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const auth = useAuth();
  const fetchIdRef = useRef(0);
  const toast = useToast();
  const { data: statusRes } = useQuery(
    "statuses",
    reportsService.getReportStatuses,
    { staleTime: 60000 }
  );
  const { data: statsRes } = useQuery(
    "statistics",
    reportsService.getAgentReportStatistics,
    {
      staleTime: 60000,
    }
  );

  const statuses = statusRes?.data.body.data;

  const tableData = React.useMemo(() => reports, [reports]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        if (filter)
          reportsService
            .getAssignedReportsByStatus(filter, pageIndex + 1, pageSize)
            .then(res => {
              setReports(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
              setLoading(false);
            })
            .catch(err => {
              toast({
                title: "Failed to filter assigned reports",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              console.error(err.response);
              setLoading(false);
            });
        else
          reportsService
            .getAssignedReports(pageIndex + 1, pageSize)
            .then(res => {
              setReports(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
              setLoading(false);
            })
            .catch(err => {
              toast({
                title: "Failed to fetch assigned reports",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              console.error(err.response);
              setLoading(false);
            });
      }
    },
    [toast, filter]
  );

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Case Number",
        accessor: "caseNumber",
      },
      {
        Header: "First Name",
        accessor: "reporterFirstName",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterFirstName || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Last Name",
        accessor: "reporterLastName",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterLastName || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Email",
        accessor: "reporterEmail",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterEmail || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "reporterPhoneNumber",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterPhoneNumber || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Incident",
        accessor: "incidenceType.name",
      },
      {
        Header: "Date submitted",
        accessor: "createdDate",
        Cell: ({ cell }) => (
          <Text>
            {moment(cell.row.values.createdDate).format("YYYY-MM-DD")}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "reportStatusName",
        Cell: ({ cell }) => (
          <Tag variant={cell.row.values.reportStatusName}>
            {cell.row.values.reportStatusName}
          </Tag>
        ),
      },
    ],
    []
  );

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Dashboard</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>{`Welcome ${auth?.user?.firstName}`}</Heading>
      </Flex>
      <Grid
        templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gap={4}
        my={6}
      >
        <CasesCard
          title="CASES SUBMITTED"
          number={statsRes?.data.body.casesSubmitted}
          color="#B13D3D"
          bg="#FF000026"
          Icon={CaseSubmitted}
          flex={1}
        />

        <CasesCard
          title="PENDING CASES"
          number={statsRes?.data.body.pendingCases}
          color="#CFAE89"
          bg="#F5EFE7"
          Icon={PendingCases}
          flex={1}
        />

        <CasesCard
          title="ACTIVE CASES"
          number={statsRes?.data.body.activeCases}
          color="#3DCDB5"
          bg="#D8F5F0"
          Icon={ActiveCases}
          flex={1}
        />
      </Grid>
      {tableData && (
        <Table
          route="/dashboard/assigned-cases"
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Assigned Cases"
          filter={filter}
          FilterComponent={
            <Select
              fontSize="sm"
              w="max-content"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            >
              <option value="">Status</option>
              {statuses?.map(status => (
                <option value={status.name} key={status.id}>
                  {status.name}
                </option>
              ))}
            </Select>
          }
        />
      )}
    </Box>
  );
};
