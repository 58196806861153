import {
  Box,
  BoxProps,
  Button,
  Flex,
  Grid,
  GridItem,
  Radio,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecorder } from "../../context/useRecorder";
import { useUploads } from "../../context/useUploads";
import { Agency } from "../../interfaces/Agency";
import {
  IncidenceType,
  IncidenceTypeQuestion,
} from "../../interfaces/IncidenceType";
import { Location, Report, ReportDatum } from "../../interfaces/Report";
import agenciesService from "../../services/agenciesService";
import incidenceService from "../../services/incidenceService";
import reportsService from "../../services/reportsService";
import FormInput from "../common/FormInput";
import FormLocation from "../common/FormLocation";
import FormRadio from "../common/FormRadio";
import FormSelect from "../common/FormSelect";
import FormSwitch from "../common/FormSwitch";
import FormTextarea from "../common/FormTextarea";
import FormUpload from "../common/FormUpload";

const ReportCaseForm = (props: BoxProps) => {
  const form = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const { uploads } = useUploads();
  const { recording } = useRecorder();
  const { data: agenciesRes, isLoading: agenciesLoading } = useQuery(
    ["agencies", 1, 10],
    () => agenciesService.getAgencies(1, 10),
    { staleTime: 60000 }
  );
  const agency = form.watch("agency");
  const agencyId = agency?.split(",")[1];
  const incidenceId = form.watch("incidentType");
  const { data: incidencesRes, isLoading: incidenceLoading } = useQuery(
    ["incidences", agencyId],
    () => agenciesService.getAgencyIncidenceTypes(agencyId),
    { enabled: !!agencyId, staleTime: 60000 }
  );
  const { data: questionsRes, isLoading: questionsLoading } = useQuery(
    ["questions", incidenceId],
    () => incidenceService.getIncidenceTypeQuestions(incidenceId),
    { enabled: !!incidenceId, staleTime: 60000 }
  );
  const mutation = useMutation((report: Report) => {
    return reportsService.createReport(report);
  });
  const [currentLocation, setCurrentLocation] = useState<Location>();

  useEffect(() => {
    const getCurrentLocation = () => {
      if (!navigator) return;
      navigator.geolocation.getCurrentPosition(
        position => {
          const latitude = position.coords.latitude.toString();
          const longitude = position.coords.longitude.toString();
          setCurrentLocation({ latitude, longitude, isReportLocation: false });
        },
        error => {
          toast({
            title: "Location access denied",
            description: error.message,
            status: "warning",
            isClosable: true,
            position: "top-right",
            duration: 5000,
          });
          setCurrentLocation({
            latitude: "0",
            longitude: "0",
            isReportLocation: false,
          });
        }
      );
    };
    getCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to Report Case",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast({
        title: "Report has been submitted",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/dashboard/my-cases");
    }
  }, [mutation.isSuccess, toast, navigate]);

  function onSubmit(values: any) {
    const reportData: ReportDatum[] = [];
    questionsRes?.data.body.incidenceTypeQuestions.forEach(
      (question: IncidenceTypeQuestion) => {
        if (values[question.question.title])
          reportData.push({
            questionId: question.question.id,
            value: values[question.question.title],
          });
      }
    );

    const reportAttachments = uploads.map(upload => {
      const data = { ...upload, fileId: upload.id };
      delete data.id;
      return data;
    });

    if (recording) {
      const audio = { ...recording, fileId: recording?.id };
      delete audio.id;
      reportAttachments.push(audio);
    }

    const report: Report = {
      incidenceTypeId: Number(values.incidentType),
      reporterType: values.reporterType ?? "Witness",
      identityStatus: !values.identityStatus,
      ownedBy: values.agency?.split(",")[0],
      dateOfIncidence: values.date,
      time: values.time,
      incidentDescription: recording
        ? "Attached is an audio file"
        : values.description,
      anyOtherWitnesses: values.anyWitness === "yes",
      witnessContact: values.witnessContact,
      reportData,
      reportAttachments,
      location: [values.location, currentLocation],
    };
    if (!values.location) report?.location?.shift();

    mutation.mutate(report);
  }

  return (
    <Box
      as="form"
      maxW="950px"
      onSubmit={form.handleSubmit(onSubmit)}
      {...props}
    >
      <Box mb={10}>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" }}
          gap={{ base: 0, sm: 6 }}
        >
          <GridItem w="100%">
            {agenciesLoading ? (
              <Box my={4}>
                <SkeletonText noOfLines={1} mb="0.5rem" h="21px" />
                <Skeleton h="40px" />
              </Box>
            ) : (
              agenciesRes?.data.body.data.length > 0 && (
                <FormSelect
                  form={form}
                  label="Select an Agency"
                  id="agency"
                  options={{ required: "Agency is required" }}
                >
                  <option value=""></option>
                  {agenciesRes?.data.body.data &&
                    agenciesRes?.data.body.data.map((agency: Agency) => (
                      <option
                        key={agency.id}
                        value={`${agency.shortCode},${agency.id}`}
                      >
                        {agency.name}
                      </option>
                    ))}
                </FormSelect>
              )
            )}
          </GridItem>
          <GridItem w="100%">
            {incidenceLoading ? (
              <Box my={4}>
                <SkeletonText noOfLines={1} mb="0.5rem" h="21px" />
                <Skeleton h="40px" />
              </Box>
            ) : (
              incidencesRes?.data.body[0].incidenceTypes.length > 0 && (
                <FormSelect
                  form={form}
                  label="Select type of incident"
                  id="incidentType"
                  options={{ required: "Type of incident is required" }}
                >
                  <option value=""></option>
                  {incidencesRes?.data.body &&
                    incidencesRes?.data.body[0].incidenceTypes.map(
                      (incidence: IncidenceType) => (
                        <option key={incidence.id} value={incidence.id}>
                          {incidence.name}
                        </option>
                      )
                    )}
                </FormSelect>
              )
            )}
          </GridItem>
        </Grid>
        <Flex flexDir={{ base: "column", sm: "row" }} align="center" gap={6}>
          <FormRadio
            form={form}
            label="Report as"
            id="reporterType"
            defaultValue="Witness"
          >
            <Radio value="Witness">Witness</Radio>
            <Radio value="Victim">Victim</Radio>
          </FormRadio>
          <FormSwitch
            form={form}
            label="Hide my identity"
            id="identityStatus"
            options={{ required: false }}
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={6}>
          <FormLocation
            form={form}
            label="Incident Location"
            id="location"
            options={{ required: "Location is required" }}
          />
          <FormInput
            form={form}
            label="Time"
            type="time"
            id="time"
            options={{ required: "Time is required" }}
          />
          <FormInput
            form={form}
            label="Date"
            type="date"
            id="date"
            options={{ required: "Date is required" }}
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={6}>
          <FormRadio
            form={form}
            label="Any other witness?"
            id="anyWitness"
            defaultValue="no"
          >
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </FormRadio>
          {form.watch("anyWitness") === "yes" && (
            <FormInput
              form={form}
              label="Witness Contact"
              id="witnessContact"
              type="tel"
            />
          )}
        </Flex>
        <FormTextarea
          form={form}
          label="Describe incident or Attach Voice Note"
          id="description"
          minH="150px"
          maxW={{ base: "auto", md: "50%" }}
          withRecorder={true}
          options={{ required: "Description or Voice Note is required" }}
        />

        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" }}
          gap={{ base: 0, sm: 6 }}
        >
          {questionsLoading ? (
            <>
              <GridItem my={4} w="100%">
                <SkeletonText noOfLines={1} mb={4} />
                <Skeleton h="30px" />
              </GridItem>
              <GridItem my={4} w="100%">
                <SkeletonText noOfLines={1} mb={4} />
                <Skeleton h="30px" />
              </GridItem>
            </>
          ) : (
            questionsRes?.data.body &&
            questionsRes?.data.body.incidenceTypeQuestions.map(
              (question: IncidenceTypeQuestion) => (
                <GridItem w="100%" key={question.question.id}>
                  <FormInput
                    form={form}
                    label={question.question.title}
                    subtitle={question.question.subtitle}
                    id={question.question.title}
                    options={{
                      required: question.isRequired
                        ? `${question.question.title} is required`
                        : false,
                    }}
                  />
                </GridItem>
              )
            )
          )}
        </Grid>
        <FormUpload label="Attach Media" />
      </Box>
      <Button
        my={4}
        px="50px"
        isLoading={form.formState.isSubmitting || mutation.isLoading}
        type="submit"
        isDisabled={
          form.formState.isSubmitting || mutation.isLoading || !currentLocation
        }
      >
        Submit
      </Button>
    </Box>
  );
};

export default ReportCaseForm;
