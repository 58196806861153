import { useForm } from "react-hook-form";
import {
  Button,
  Box,
  Flex,
  Input,
  useToast,
  Progress,
  Avatar,
} from "@chakra-ui/react";
import FormInput from "../common/FormInput";
import { useEffect, useRef, useState } from "react";
import fileUploadService from "../../services/fileUploadService";
import { useMutation } from "react-query";
import { useAuth } from "../../context/useAuth";
import { EditUserDTO } from "../../interfaces/User";
import { editProfileSchema } from "../../utils/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import usersService from "../../services/usersService";
import { FileStore } from "../../interfaces/FileUpload";
import FormSelect from "../common/FormSelect";
import moment from "moment";

interface FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthdate: string;
  gender: string;
  newPassword: string;
  confirmPassword: string;
}

const EditProfileForm = () => {
  const auth = useAuth();
  const form = useForm<FieldValues>({
    resolver: yupResolver(editProfileSchema),
  });
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState<FileStore | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const editMutation = useMutation((user: EditUserDTO) =>
    usersService.editUser(user)
  );
  const deleteMutation = useMutation((user: EditUserDTO) =>
    usersService.deleteProfileImage(user)
  );

  useEffect(() => {
    form.setValue("firstName", `${auth?.user?.firstName}`);
    form.setValue("lastName", `${auth?.user?.lastName}`);
    form.setValue("email", `${auth?.user?.email}`);
    form.setValue("phoneNumber", `${auth?.user?.phoneNumber}`);
    form.setValue(
      "birthdate",
      moment(`${auth?.user?.birthdate}`).format("yyyy-MM-DD")
    );
    form.setValue("gender", `${auth?.user?.gender}`);
  }, [auth, form]);

  useEffect(() => {
    if (editMutation.isError) {
      toast({
        title: "Failed to edit profile",
        description: (editMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [editMutation.isError, toast, editMutation.error]);

  useEffect(() => {
    if (editMutation.isSuccess) {
      const token = localStorage.getItem("token");
      if (token) {
        const tokenData = JSON.parse(token);
        auth?.refreshToken(tokenData);
      }

      toast({
        title: "Profile edited successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMutation.isSuccess, toast]);

  useEffect(() => {
    if (deleteMutation.isError) {
      toast({
        title: "Failed to delete profile image",
        description: (deleteMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [deleteMutation.isError, toast, deleteMutation.error]);

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      const token = localStorage.getItem("token");
      if (token) {
        const tokenData = JSON.parse(token);
        auth?.refreshToken(tokenData);
      }
      toast({
        title: "Profile image deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMutation.isSuccess, toast]);

  function onSubmit(values: FieldValues) {
    const user: EditUserDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      birthdate: values.birthdate,
      gender: values.gender,
      password: values.newPassword,
      confirmPassword: values.confirmPassword,
      filename: file?.fileName,
    };
    if (!user.filename) delete user.filename;
    if (!values.newPassword || !values.confirmPassword) {
      delete user.password;
      delete user.confirmPassword;
    }
    editMutation.mutate(user);
  }

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    setPreview(URL.createObjectURL(files[0]));
    setIsUploading(true);
    fileUploadService
      .uploadFile(files[0])
      .then(res => setFile(res.data.body))
      .catch(err => {
        toast({
          title: "Upload Failed",
          description: err?.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        console.error(err.response);
      })
      .finally(() => {
        setIsUploading(false);
        e.target.value = "";
      });
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box maxW="950px" mb={10}>
        <Box mb={10}>
          <Flex align="center" gap={6}>
            <Flex align="center" justify="center" boxSize="100px">
              <Avatar
                size="xl"
                name={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
                src={preview || auth?.user?.picture}
              />
            </Flex>
            <Box>
              <Flex flexDir="column" gap={3}>
                <Button
                  display="block"
                  onClick={() => inputRef.current?.click()}
                >
                  Change picture
                </Button>
                <Button
                  display="flex"
                  variant="outline"
                  color="#FF0000"
                  borderColor="#FF0000"
                  onClick={() => setPreview("empty")}
                  isLoading={deleteMutation.isLoading}
                >
                  Delete picture
                </Button>
              </Flex>
              {isUploading && (
                <Progress
                  my={2}
                  size="sm"
                  isIndeterminate
                  h="5px"
                  colorScheme="primary"
                />
              )}
            </Box>
          </Flex>
          <Input
            ref={inputRef}
            id="fileInput"
            type="file"
            hidden
            onChange={onFileUpload}
            accept="image/*"
          />
        </Box>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput form={form} label="First Name" id="firstName" />
          <FormInput form={form} label="Last Name" id="lastName" />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Date of Birth"
            id="birthdate"
            type="date"
          />
          <FormSelect form={form} label="Gender" id="gender">
            <option value=""></option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </FormSelect>
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Email Address"
            id="email"
            type="email"
            color="textSecondary"
            isDisabled
          />
          <FormInput
            form={form}
            label="Phone Number"
            id="phoneNumber"
            type="tel"
            color="textSecondary"
            isDisabled
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="New Password"
            id="newPassword"
            type="password"
            autoComplete="off"
          />
          <FormInput
            form={form}
            label="Confirm Password"
            id="confirmPassword"
            type="password"
            autoComplete="off"
          />
        </Flex>
      </Box>
      <Button
        my={4}
        px="50px"
        isLoading={form.formState.isSubmitting || editMutation.isLoading}
        type="submit"
        disabled={
          isUploading || form.formState.isSubmitting || editMutation.isLoading
        }
      >
        Save Changes
      </Button>
    </form>
  );
};

export default EditProfileForm;
