import { Icon, IconProps } from "@chakra-ui/react";

const PendingCases = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" fill="currentcolor" {...props}>
      <path d="M6.87988 18.9001C6.46988 18.9001 6.12988 18.5601 6.12988 18.1501V16.0801C6.12988 15.6701 6.46988 15.3301 6.87988 15.3301C7.28988 15.3301 7.62988 15.6701 7.62988 16.0801V18.1501C7.62988 18.5701 7.28988 18.9001 6.87988 18.9001Z" />
      <path d="M12 18.9C11.59 18.9 11.25 18.56 11.25 18.15V14C11.25 13.59 11.59 13.25 12 13.25C12.41 13.25 12.75 13.59 12.75 14V18.15C12.75 18.57 12.41 18.9 12 18.9Z" />
      <path d="M17.1201 18.9002C16.7101 18.9002 16.3701 18.5602 16.3701 18.1502V11.9302C16.3701 11.5202 16.7101 11.1802 17.1201 11.1802C17.5301 11.1802 17.8701 11.5202 17.8701 11.9302V18.1502C17.8701 18.5702 17.5401 18.9002 17.1201 18.9002Z" />
      <path d="M6.88007 13.1799C6.54007 13.1799 6.24007 12.9499 6.15007 12.6099C6.05007 12.2099 6.29007 11.7999 6.70007 11.6999C10.3801 10.7799 13.6201 8.76992 16.0901 5.89992L16.5501 5.35992C16.8201 5.04992 17.2901 5.00992 17.6101 5.27992C17.9201 5.54992 17.9601 6.01992 17.6901 6.33992L17.2301 6.87992C14.5601 9.99992 11.0401 12.1699 7.06007 13.1599C7.00007 13.1799 6.94007 13.1799 6.88007 13.1799Z" />
      <path d="M17.1199 9.5201C16.7099 9.5201 16.3699 9.1801 16.3699 8.7701V6.6001H14.1899C13.7799 6.6001 13.4399 6.2601 13.4399 5.8501C13.4399 5.4401 13.7799 5.1001 14.1899 5.1001H17.1199C17.5299 5.1001 17.8699 5.4401 17.8699 5.8501V8.7801C17.8699 9.1901 17.5399 9.5201 17.1199 9.5201Z" />
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" />
    </Icon>
  );
};

export default PendingCases;
