import React from "react";
import { Select } from "@chakra-ui/react";

const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}: any) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      fontSize="sm"
      value={filterValue}
      onChange={e => setFilter(e.target.value || undefined)}
    >
      <option value="">{Header}</option>
      {options.map((option, i) => (
        <option key={i} value={option as string}>
          {option as string}
        </option>
      ))}
    </Select>
  );
};
export default SelectColumnFilter;
