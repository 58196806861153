import {
  Box,
  Flex,
  Select,
  Divider,
  Text,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTable, Column, usePagination } from "react-table";
import React from "react";
import EmptyState from "../common/EmptyState";
import StoryCard from "./StoryCard";
import { SuccessStory } from "../../interfaces/SuccessStory";
import { useAuth } from "../../context/useAuth";
import getUserRole from "../../helpers/getUserRole";

export type TableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  heading?: string;
  pageCount: number;
  totalCount: number;
  loading: boolean;
  fetchData: any;
  onOpen: () => void;
  setStory: React.Dispatch<React.SetStateAction<SuccessStory | undefined>>;
  FilterComponent?: any;
  filter?: string;
};

const Table = <Data extends object>({
  data,
  columns,
  heading,
  pageCount: controlledPageCount,
  fetchData,
  loading,
  totalCount,
  onOpen,
  setStory,
  FilterComponent,
  filter,
}: TableProps<Data>) => {
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex },
    pageCount,
  } = useTable(
    {
      initialState: { pageIndex: 0, pageSize: 9 },
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  React.useEffect(() => {
    fetchData && fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const recordsCountFrom =
    pageIndex + 1 === pageCount && pageCount !== 1
      ? totalCount - pageSize + 1
      : pageIndex * pageSize + 1;
  const recordsCountTo = recordsCountFrom + page.length - 1;

  if (!loading && data.length === 0 && !filter)
    return <EmptyState h="calc(100vh - 88px - 104px - 32px)" isStory />;

  return (
    <Box
      p={4}
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      border="1px solid #D4D4D4"
      borderRadius="10px"
    >
      <Flex justify="space-between" align="center" flexWrap="wrap" gap={4}>
        <Text my={2} fontSize="md" fontWeight="semibold" color="textSecondary">
          {heading}
        </Text>
        {!!isOverallAdmin && FilterComponent}
      </Flex>
      <Divider my={6} bg="#D4D4D4" />
      {!loading && data.length === 0 && filter ? (
        <EmptyState isFilter isStory />
      ) : (
        <>
          <Box>
            <Box fontSize="xs" {...getTableProps()}>
              <Flex gap={6} flexWrap="wrap" {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <StoryCard
                      onOpen={onOpen}
                      setStory={setStory}
                      w={{
                        base: "100%",
                        sm: "calc(100% / 2 - 1.5rem)",
                        md: "calc(100% / 3 - 1.5rem)",
                      }}
                      story={row.values}
                      {...row.getRowProps()}
                    />
                  );
                })}
              </Flex>
            </Box>
          </Box>
          <Flex justify="space-between" my={4} flexWrap="wrap" gap={4}>
            <Flex fontSize="xs" align="center" gap={2}>
              <Text color="textSecondary" whiteSpace="nowrap">
                Show rows per page
              </Text>
              <Select
                size="sm"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[9, 18, 27, 36, 45].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex align="center" gap={8} fontSize="sm">
              {loading ? (
                <Spinner />
              ) : (
                <Box>
                  <Box as="span" color="textSecondary">
                    {`${recordsCountFrom}-${recordsCountTo} `}
                  </Box>
                  of {totalCount}
                </Box>
              )}
              <Flex align="center" gap={2}>
                <IconButton
                  size="xs"
                  variant="link"
                  icon={<ChevronLeftIcon fontSize="1rem" />}
                  aria-label="go to previous page"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <IconButton
                  size="xs"
                  variant="link"
                  icon={<ChevronRightIcon fontSize="1rem" />}
                  aria-label="go to previous page"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Table;
