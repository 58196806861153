import { Icon, IconProps } from "@chakra-ui/react";

const Reports = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 25 24" fill="none" stroke="currentcolor" {...props}>
      <path
        d="M16.6 12.69V18.08C16.6 20.42 15.04 21.97 12.71 21.97H6.49004C4.16004 21.97 2.60004 20.42 2.60004 18.08V10.31C2.60004 7.96998 4.16004 6.41998 6.49004 6.41998H10.32C11.35 6.41998 12.34 6.82998 13.07 7.55998L15.46 9.93998C16.19 10.67 16.6 11.66 16.6 12.69Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6 8.24997V13.64C22.6 15.97 21.04 17.53 18.71 17.53H16.6V12.69C16.6 11.66 16.19 10.67 15.46 9.93997L13.07 7.55997C12.34 6.82997 11.35 6.41997 10.32 6.41997H8.60004V5.85997C8.60004 3.52997 10.16 1.96997 12.49 1.96997H16.32C17.35 1.96997 18.34 2.37997 19.07 3.10997L21.46 5.49997C22.19 6.22997 22.6 7.21997 22.6 8.24997Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Reports;
