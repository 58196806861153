import { Box, ContainerProps, Flex, Text } from "@chakra-ui/react";
import Container from "../common/Container";
import Heading from "../common/Heading";
import Calendar from "../svg/Calendar";
import Message from "../svg/Message";
import Profile from "../svg/Profile";
import ReportCase from "../svg/ReportCase";

const HowItWorks = ({ ...props }: ContainerProps) => {
  return (
    <Container textAlign="center" {...props}>
      <Box mb={10}>
        <Heading mb={2} fontSize="45px" color="primary.500">
          How It Works
        </Heading>
        <Text fontSize="2xl">
          Create your account and submit your report in a few simple steps
        </Text>
      </Box>
      <Flex
        justify="space-around"
        align={{ base: "center", md: "normal" }}
        flexWrap="wrap"
        gap={10}
        color="textSecondary"
      >
        <Flex flexDir="column" align="center" maxW="260px">
          <Flex
            align="center"
            justify="center"
            boxSize="80px"
            bg="#CFAE891A"
            color="primary.500"
            borderRadius="full"
          >
            <Calendar fontSize="32px" color="secondary.500" />
          </Flex>
          <Text my={2} color="black" fontSize="2xl" fontWeight="bold">
            Quick report
          </Text>
          <Text>
            Use the quick report option on the go to make a quick report
          </Text>
        </Flex>
        <Flex flexDir="column" align="center" maxW="260px">
          <Flex
            align="center"
            justify="center"
            boxSize="80px"
            bg="#CFAE891A"
            color="primary.500"
            borderRadius="full"
          >
            <Profile fontSize="32px" color="secondary.500" />
          </Flex>
          <Text my={2} color="black" fontSize="2xl" fontWeight="bold">
            Create an account
          </Text>
          <Text>
            Quickly create an account by signing up with your name, email, phone
            number etc
          </Text>
        </Flex>
        <Flex flexDir="column" align="center" maxW="260px">
          <Flex
            align="center"
            justify="center"
            boxSize="80px"
            bg="#CFAE891A"
            color="primary.500"
            borderRadius="full"
          >
            <ReportCase fontSize="32px" color="secondary.500" />
          </Flex>
          <Text my={2} color="black" fontSize="2xl" fontWeight="bold">
            Submit a report
          </Text>
          <Text>
            Fill out the form with the required information about the incident.
            You can include photos videos and voice recordings
          </Text>
        </Flex>
        <Flex flexDir="column" align="center" maxW="260px">
          <Flex
            align="center"
            justify="center"
            boxSize="80px"
            bg="#CFAE891A"
            color="primary.500"
            borderRadius="full"
          >
            <Message fontSize="32px" color="secondary.500" />
          </Flex>
          <Text my={2} color="black" fontSize="2xl" fontWeight="bold">
            Get report updates
          </Text>
          <Text>
            Get necessary updates on your report when the case has been treated
            by the designated agency
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};

export default HowItWorks;
