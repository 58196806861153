import { Tag as ChakraTag, TagProps } from "@chakra-ui/react";

const Tag = ({ variant, children, ...props }: TagProps) => {
  if (variant === "approved")
    return (
      <ChakraTag
        bg="#31AF45"
        color="white"
        border="1px solid #31AF45"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  if (variant === "disapproved")
    return (
      <ChakraTag
        bg="#B13D3D"
        color="white"
        border="1px solid #B13D3D"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  if (variant === "Closed")
    return (
      <ChakraTag
        bg="#31AF452B"
        color="#31AF45"
        border="1px solid #31AF45"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  if (variant === "In Review")
    return (
      <ChakraTag
        bg="#EFC14A2B"
        color="#EFC14A"
        border="1px solid #EFC14A"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  if (variant === "Agency transfer")
    return (
      <ChakraTag
        bg="#9747FF1A"
        color="#9747FF"
        border="1px solid #9747FF"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  if (variant === "Submitted")
    return (
      <ChakraTag
        bg="#0052B41A"
        color="#0052B4"
        border="1px solid #0052B4"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  if (variant === "More Information Required")
    return (
      <ChakraTag
        bg="#BAAF001A"
        color="#BAAF00"
        border="1px solid #BAAF00"
        borderRadius="full"
        fontSize="xs"
        {...props}
      >
        {children}
      </ChakraTag>
    );
  return <ChakraTag {...props}>{children}</ChakraTag>;
};

export default Tag;
