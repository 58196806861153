import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "../../context/useAuth";
import ArrowDown from "../svg/ArrowDown";
import Dashboard from "../svg/Dashboard";
import FAQ from "../svg/FAQ";
import Logout from "../svg/Logout";
import Profile from "../svg/Profile";
import Link from "./Link";

const ProfileMenu = () => {
  const auth = useAuth();
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            variant="solid"
            bg="#DADADA3D"
            isActive={isOpen}
            as={Button}
            rightIcon={<ArrowDown />}
            h="48px"
          >
            <Flex align="center" justify="space-between" gap={2}>
              <Avatar
                boxSize="35px"
                src={auth?.user?.picture}
                name={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
              />
              <Text color="#0A230E" fontSize="14px">
                {`${auth?.user?.firstName} ${auth?.user?.lastName}`}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList>
            <Link _hover={{}} to="/dashboard">
              <MenuItem
                p={3}
                color="textSecondary"
                icon={<Dashboard color="#424D44" boxSize="18px" />}
              >
                Dashboard
              </MenuItem>
            </Link>
            <Link _hover={{}} to="/dashboard/profile">
              <MenuItem
                p={3}
                color="textSecondary"
                icon={<Profile color="#424D44" boxSize="18px" />}
              >
                Profile
              </MenuItem>
            </Link>
            <Link _hover={{}} to="/faqs">
              <MenuItem
                p={3}
                color="textSecondary"
                icon={<FAQ boxSize="18px" />}
              >
                FAQs
              </MenuItem>
            </Link>
            <MenuItem
              p={3}
              color="textSecondary"
              onClick={() => auth?.logout()}
              icon={<Logout boxSize="18px" />}
            >
              Logout
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default ProfileMenu;
