const globalStyles = {
  global: {
    html: {
      scrollBehavior: "smooth",
      fontFamily: "body",
      m: 0,
      p: 0,
      scrollPaddingTop: "105px",
    },
    body: {
      bg: "#FFFFFF",
      color: "text",
      fontSize: "sm",
    },
  },
};

export default globalStyles;
