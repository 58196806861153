import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Button,
  ModalFooter,
  useToast,
  Box,
  FormControl,
  Image,
  Flex,
  Progress,
  Input,
  FormErrorMessage,
  AspectRatio,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { queryClient } from "../..";
import { LibraryItem, LibraryType } from "../../interfaces/Library";
import fileUploadService from "../../services/fileUploadService";
import libraryService from "../../services/libraryService";
import uploadLimit from "../../utils/uploadLimit";
import FormInput from "../common/FormInput";
import FormSelect from "../common/FormSelect";
import FormTextarea from "../common/FormTextarea";
import Document from "../svg/Document";

interface AddLibraryItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  libraryTypes: LibraryType[];
}

const AddLibraryItemModal = ({
  isOpen,
  onClose,
  libraryTypes,
}: AddLibraryItemModalProps) => {
  const form = useForm();
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const mutation = useMutation((item: LibraryItem) => {
    return libraryService.addLibraryItem(item);
  });

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to add library item",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      const queryKey =
        form.watch("type") === "1"
          ? "imageResources"
          : form.watch("type") === "2"
          ? "videoResources"
          : "documentsResources";
      queryClient.invalidateQueries(queryKey);
      onClose();
      form.reset();
      setFile(null);
      toast({
        title: "Library item has been submitted",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  function onSubmit(values: any) {
    const libraryItem: LibraryItem = {
      body: values.body,
      title: values.title,
      url: values.url,
      libraryItemAttachment: { fileId: values.file?.id },
      libraryItemTypeId: Number(values.type),
    };
    if (!values.file) delete libraryItem.libraryItemAttachment;
    if (!values.url) delete libraryItem.url;

    mutation.mutate(libraryItem);
  }

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    const limit = uploadLimit(files[0]);
    if (limit?.isExceeded) {
      toast({
        title: "Upload Failed",
        description: limit.type + " size must be less than " + limit.size,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      e.target.value = "";
      return;
    }
    setFile(files[0]);
    setIsUploading(true);
    fileUploadService
      .uploadFile(files[0])
      .then(res => form.setValue("file", res.data.body))
      .catch(err => {
        setFile(null);
        toast({
          title: "Upload Failed",
          description: err?.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        console.error(err.response);
      })
      .finally(() => {
        setIsUploading(false);
        e.target.value = "";
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader color="textSecondary">Add Library Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <FormInput
                form={form}
                label="Title"
                id="title"
                options={{ required: "Title is required" }}
              />
              <FormTextarea
                form={form}
                label="Description"
                id="body"
                minH="150px"
              />
              <FormSelect
                maxW="max-content"
                label="Resource Type"
                id="type"
                options={{ required: "Resource type is required" }}
                form={form}
              >
                <option value=""></option>
                {libraryTypes &&
                  libraryTypes.map(type => (
                    <option key={type.id} value={type.id}>
                      {type.type}
                    </option>
                  ))}
              </FormSelect>
              {!form.watch("file") && (
                <FormInput
                  form={form}
                  label="URL or Upload File"
                  id="url"
                  options={{
                    validate: (value: any) => {
                      if (!value && !form.getValues("file"))
                        return "URL is required";
                      return true;
                    },
                  }}
                />
              )}
            </Box>
            {!form.watch("url") && (
              <FormControl isInvalid={form.formState.errors.file}>
                {!file ? (
                  <Button
                    onClick={() => inputRef.current?.click()}
                    variant="outline"
                  >
                    Upload File
                  </Button>
                ) : file.type.startsWith("image") ? (
                  <>
                    <Box>
                      <Flex
                        justify="space-between"
                        gap={4}
                        flexWrap="wrap"
                        mb={4}
                      >
                        <Button
                          display="block"
                          onClick={() => inputRef.current?.click()}
                          variant="outline"
                        >
                          Change File
                        </Button>
                        <Button
                          display="block"
                          onClick={() => {
                            setFile(null);
                            form.setValue("file", null);
                          }}
                        >
                          Remove File
                        </Button>
                      </Flex>
                      {isUploading && (
                        <Progress
                          my={2}
                          size="sm"
                          isIndeterminate
                          h="5px"
                          colorScheme="primary"
                        />
                      )}
                    </Box>
                    <Flex align="center" justify="center" w="full">
                      <Image
                        src={URL.createObjectURL(file)}
                        objectFit="contain"
                        maxW="100%"
                        maxH="100%"
                      />
                    </Flex>
                  </>
                ) : file.type.startsWith("video") ? (
                  <>
                    <Box>
                      <Flex
                        justify="space-between"
                        gap={4}
                        flexWrap="wrap"
                        mb={4}
                      >
                        <Button
                          display="block"
                          onClick={() => inputRef.current?.click()}
                          variant="outline"
                        >
                          Change File
                        </Button>
                        <Button display="block" onClick={() => setFile(null)}>
                          Remove File
                        </Button>
                      </Flex>
                      {isUploading && (
                        <Progress
                          my={2}
                          size="sm"
                          isIndeterminate
                          h="5px"
                          colorScheme="primary"
                        />
                      )}
                    </Box>
                    <AspectRatio w="full" ratio={16 / 9}>
                      <video
                        controls
                        style={{
                          objectFit: "contain",
                          backgroundColor: "black",
                        }}
                      >
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </AspectRatio>
                  </>
                ) : (
                  <>
                    <Box>
                      <Flex
                        justify="space-between"
                        gap={4}
                        flexWrap="wrap"
                        mb={4}
                      >
                        <Button
                          display="block"
                          onClick={() => inputRef.current?.click()}
                          variant="outline"
                        >
                          Change File
                        </Button>
                        <Button display="block" onClick={() => setFile(null)}>
                          Remove File
                        </Button>
                      </Flex>
                      {isUploading && (
                        <Progress
                          my={2}
                          size="sm"
                          isIndeterminate
                          h="5px"
                          colorScheme="primary"
                        />
                      )}
                    </Box>
                    <Flex
                      w="full"
                      align="center"
                      justify="space-between"
                      border="1px solid #D4D4D4"
                      gap={4}
                      p={1}
                      borderRadius={8}
                    >
                      <Document boxSize="40px" />
                      <Text>{file.name}</Text>
                      <Text>
                        {Number((file.size / (1024 * 1024)).toFixed(2)) < 1
                          ? `${(file.size / 1024).toFixed(2)}KB`
                          : `${(file.size / (1024 * 1024)).toFixed(2)}MB`}
                      </Text>
                    </Flex>
                  </>
                )}
                <Input
                  ref={inputRef}
                  id="fileInput"
                  type="file"
                  hidden
                  onChange={onFileUpload}
                />
                <Input
                  type="hidden"
                  {...form.register("file", {
                    validate: value => {
                      if (!value && !form.getValues("url"))
                        return "File is required";
                      return true;
                    },
                  })}
                />
                <FormErrorMessage>
                  {form.formState.errors.file &&
                    form.formState.errors.file.message}
                </FormErrorMessage>
              </FormControl>
            )}

            <ModalFooter>
              <Button
                my={4}
                px="50px"
                isLoading={form.formState.isSubmitting || mutation.isLoading}
                type="submit"
                disabled={
                  isUploading ||
                  form.formState.isSubmitting ||
                  mutation.isLoading
                }
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddLibraryItemModal;
