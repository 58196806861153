import axiosInstance from "../helpers/axiosInstance";
import { ResponseWithPagination } from "../interfaces/Response";

const getUserDevices = (page: number, size: number) => {
  const url = `/userDevice?page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<any>>(url);
};

const exportUserDevices = (page: number, size: number) => {
  const url = `/userDevice/export?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url, { responseType: "blob" });
};

const usersService = {
  getUserDevices,
  exportUserDevices,
};

export default usersService;
