import { Icon, IconProps } from "@chakra-ui/react";

const FAQ = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="currentcolor" {...props}>
      <path
        d="M6 16.7399C5.79 16.7399 5.57249 16.6874 5.37749 16.5824C4.94999 16.3574 4.6875 15.9074 4.6875 15.4274V14.3625C2.4225 14.13 0.9375 12.4649 0.9375 10.0799V5.57996C0.9375 2.99996 2.67 1.26746 5.25 1.26746H12.75C15.33 1.26746 17.0625 2.99996 17.0625 5.57996V10.0799C17.0625 12.6599 15.33 14.3924 12.75 14.3924H9.92249L6.72748 16.5225C6.50998 16.665 6.255 16.7399 6 16.7399ZM5.25 2.38495C3.315 2.38495 2.0625 3.63745 2.0625 5.57245V10.0725C2.0625 12.0075 3.315 13.26 5.25 13.26C5.5575 13.26 5.8125 13.515 5.8125 13.8225V15.42C5.8125 15.5175 5.8725 15.5625 5.91 15.585C5.9475 15.6075 6.02251 15.63 6.10501 15.5775L9.44252 13.3575C9.53252 13.2975 9.64501 13.26 9.75751 13.26H12.7575C14.6925 13.26 15.945 12.0075 15.945 10.0725V5.57245C15.945 3.63745 14.6925 2.38495 12.7575 2.38495H5.25Z"
        fill="#424D44"
      />
      <path
        d="M8.99986 9.08252C8.69236 9.08252 8.43736 8.82752 8.43736 8.52002V8.36255C8.43736 7.49255 9.07485 7.06504 9.31485 6.90004C9.59235 6.71254 9.68234 6.58505 9.68234 6.39005C9.68234 6.01505 9.37486 5.70752 8.99986 5.70752C8.62486 5.70752 8.31738 6.01505 8.31738 6.39005C8.31738 6.69755 8.06238 6.95255 7.75488 6.95255C7.44738 6.95255 7.19238 6.69755 7.19238 6.39005C7.19238 5.39255 8.00236 4.58252 8.99986 4.58252C9.99736 4.58252 10.8073 5.39255 10.8073 6.39005C10.8073 7.24505 10.1774 7.67253 9.94487 7.83003C9.65237 8.02503 9.56236 8.15255 9.56236 8.36255V8.52002C9.56236 8.83502 9.30736 9.08252 8.99986 9.08252Z"
        fill="#424D44"
      />
      <path
        d="M9 10.95C8.685 10.95 8.4375 10.695 8.4375 10.3875C8.4375 10.08 8.6925 9.82495 9 9.82495C9.3075 9.82495 9.5625 10.08 9.5625 10.3875C9.5625 10.695 9.315 10.95 9 10.95Z"
        fill="#424D44"
      />
    </Icon>
  );
};

export default FAQ;
