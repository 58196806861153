import { Box, BoxProps, Button, Flex, Image, Text } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import landingBgLeft from "../../assets/images/landing-bg-left.svg";
import landingBgRight from "../../assets/images/landing-bg-right.svg";
import landingBg from "../../assets/images/landing-bg.png";
import Container from "../common/Container";
import Link from "../common/Link";

const Landing = ({ ...props }: BoxProps) => {
  return (
    <Box
      position="relative"
      background="linear-gradient(249.15deg, rgba(61, 205, 181, 0.12) -17.12%, rgba(214, 245, 240, 0.05) 59.11%)"
      overflow="hidden"
      {...props}
    >
      <Container textAlign={{ base: "center", md: "unset" }}>
        <Flex
          minH="400px"
          justify={{ base: "center", md: "space-between" }}
          align="center"
          gap={4}
        >
          <Box color="white" maxW={600}>
            <Text
              mb={6}
              fontSize={{ base: "50px", sm: "57px" }}
              lineHeight={{ base: "60px", md: "78px" }}
              fontWeight="800"
              color="#000000"
            >
              Connect with Government Agencies in Nigeria
            </Text>
            <Text
              color="text"
              mb={8}
              lineHeight="30px"
              fontWeight="semibold"
              fontSize={{ base: "md", sm: "20px" }}
            >
              Report various experiences of corruption, bribery, electoral
              malpractice, police brutality, and gender-based violence (Domestic
              violence, sexual and non-sexual abuse, harassment, trafficking in
              persons, sextortion, etc.)
            </Text>
            <Flex
              justify={{ base: "center", md: "unset" }}
              flexDir={{ base: "column", sm: "row" }}
              gap={4}
            >
              <Link to="/dashboard/my-cases/report" _hover={{}}>
                <Button
                  h="56px"
                  px={10}
                  fontWeight="bold"
                  w={{ base: "full", sm: "auto" }}
                >
                  Make a report
                </Button>
              </Link>
              <Link to="register" _hover={{}}>
                <Button
                  h="56px"
                  px={10}
                  fontWeight="bold"
                  variant="ghost"
                  color="primary.500"
                  rightIcon={<FiArrowRight />}
                  w={{ base: "full", sm: "auto" }}
                >
                  Create an account
                </Button>
              </Link>
            </Flex>
          </Box>
          <Image src={landingBg} display={{ base: "none", md: "block" }} />
        </Flex>
      </Container>
      <Image
        zIndex={-1}
        position="absolute"
        left={0}
        top="50%"
        transform="translateY(-50%)"
        src={landingBgLeft}
      />
      <Image
        zIndex={-1}
        position="absolute"
        right={0}
        top="50%"
        transform="translateY(-50%)"
        src={landingBgRight}
      />
    </Box>
  );
};

export default Landing;
