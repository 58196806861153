import axiosInstance from "../helpers/axiosInstance";

const getIncidenceTypes = () => {
  const url = `/incidenceTypes`;
  return axiosInstance.get(url);
};

const getIncidenceTypeQuestions = (incidenceId: number) => {
  const url = `/incidenceTypes/questions/${incidenceId}`;
  return axiosInstance.get(url);
};

const incidenceService = {
  getIncidenceTypes,
  getIncidenceTypeQuestions,
};

export default incidenceService;
