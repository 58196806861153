import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Button,
  Text,
  ModalFooter,
} from "@chakra-ui/react";

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  callback: () => void;
  isLoading: boolean;
  heading: string;
  confirmation: string;
}

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  callback,
  isLoading,
  heading,
  confirmation,
}: DeleteConfirmationModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="textSecondary">{heading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="md">{confirmation}</Text>
          <Text fontSize="md" color="textSecondary" fontWeight="semibold">
            Action is irreversible
          </Text>
          <ModalFooter>
            <Button variant="outline" onClick={callback} isLoading={isLoading}>
              Yes
            </Button>
            <Button onClick={onClose} ml={6}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmationModal;
