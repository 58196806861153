import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Heading from "../../../components/common/Heading";
import EditProfileForm from "../../../components/profile/EditProfileForm";

export const Profile = () => {
  return (
    <Box maxW="850px">
      <Helmet>
        <title>FlagIt | Profile</title>
      </Helmet>
      <Heading my={8}>Profile</Heading>

      <EditProfileForm />
    </Box>
  );
};
