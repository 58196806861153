import { Icon, IconProps } from "@chakra-ui/react";

const Pause = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="currentcolor" {...props}>
      <path d="M6.48 16.3125H3.7575C2.3625 16.3125 1.6875 15.6675 1.6875 14.3325V3.6675C1.6875 2.3325 2.3625 1.6875 3.7575 1.6875H6.48C7.875 1.6875 8.55 2.3325 8.55 3.6675V14.3325C8.55 15.6675 7.875 16.3125 6.48 16.3125ZM3.7575 2.8125C2.9475 2.8125 2.8125 3.015 2.8125 3.6675V14.3325C2.8125 14.985 2.94 15.1875 3.7575 15.1875H6.48C7.29 15.1875 7.425 14.985 7.425 14.3325V3.6675C7.425 3.015 7.2975 2.8125 6.48 2.8125H3.7575Z" />
      <path d="M14.2425 16.3125H11.52C10.125 16.3125 9.45001 15.6675 9.45001 14.3325V3.6675C9.45001 2.3325 10.125 1.6875 11.52 1.6875H14.2425C15.6375 1.6875 16.3125 2.3325 16.3125 3.6675V14.3325C16.3125 15.6675 15.6375 16.3125 14.2425 16.3125ZM11.52 2.8125C10.71 2.8125 10.575 3.015 10.575 3.6675V14.3325C10.575 14.985 10.7025 15.1875 11.52 15.1875H14.2425C15.0525 15.1875 15.1875 14.985 15.1875 14.3325V3.6675C15.1875 3.015 15.06 2.8125 14.2425 2.8125H11.52Z" />
    </Icon>
  );
};

export default Pause;
