import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import logo from "../assets/images/flagit-logo.png";
import Container from "../components/common/Container";
import Link from "../components/common/Link";
import ProfileMenu from "../components/common/ProfileMenu";
import { useAuth } from "../context/useAuth";

interface NavItem {
  label: string;
  href: string;
}

const navItems: NavItem[] = [
  { label: "About", href: "/#about" },
  { label: "How It Works", href: "/#how-it-works" },
  { label: "FAQs", href: "/faqs" },
];

const NavBar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const auth = useAuth();

  return (
    <Box bg="white" zIndex={1} pos="sticky" top={0}>
      <Container>
        <Flex align="center" justify="space-between">
          <Link onClick={onClose} to="/">
            <Image src={logo} boxSize={{ base: "80px", md: "105px" }} />
          </Link>
          <IconButton
            aria-label="Toggle navbar"
            onClick={onToggle}
            display={{ base: "flex", md: "none" }}
            p={5}
            variant="link"
            bg="transparent"
            _focus={{ boxShadow: "none" }}
            icon={
              isOpen ? (
                <CloseIcon color="primary" boxSize="16px" />
              ) : (
                <HamburgerIcon color="primary" boxSize="26px" />
              )
            }
          />
          <Flex
            gap="4rem"
            fontWeight="bold"
            color="textSecondary"
            display={{ base: "none", md: "flex" }}
          >
            {navItems.map((item, key) => (
              <Link onClick={onClose} key={key} to={item.href}>
                {item.label}
              </Link>
            ))}
          </Flex>
          <Flex gap={6} display={{ base: "none", md: "flex" }} align="center">
            {!auth?.user && (
              <Link _hover={{}} to="/login">
                <Button variant="outline" h="48px" px={10} fontWeight="bold">
                  Log in
                </Button>
              </Link>
            )}
            <Link _hover={{}} to="/dashboard/my-cases/report">
              <Button h="48px" px={10} fontWeight="bold">
                Get started
              </Button>
            </Link>
            {!!auth?.user && <ProfileMenu />}
          </Flex>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <Box my={6}>
            <Stack
              mb={6}
              fontWeight="bold"
              color="textSecondary"
              spacing={6}
              display={{ md: "none" }}
            >
              {navItems.map((item, key) => (
                <Link
                  onClick={onClose}
                  display="block"
                  key={key}
                  to={item.href}
                >
                  {item.label}
                </Link>
              ))}
            </Stack>
            <Flex gap={6} flexDir="column">
              {!auth?.user && (
                <Link
                  _hover={{}}
                  to="/login"
                  display={{ base: "unset", sm: "none" }}
                >
                  <Button variant="outline" fontWeight="bold">
                    Log in
                  </Button>
                </Link>
              )}
              <Link
                _hover={{}}
                to="/dashboard/my-cases/report"
                display={{ base: "unset", sm: "none" }}
              >
                <Button fontWeight="bold">Submit a Quick Report</Button>
              </Link>
              {!auth?.user && (
                <Link
                  _hover={{}}
                  to="/login"
                  display={{ base: "none", sm: "unset" }}
                >
                  <Button variant="outline" h="50px" px={10} fontWeight="bold">
                    Log in
                  </Button>
                </Link>
              )}
              <Link
                _hover={{}}
                to="/dashboard/my-cases/report"
                display={{ base: "none", sm: "unset" }}
              >
                <Button h="50px" px={10} fontWeight="bold">
                  Submit a Quick Report
                </Button>
              </Link>
              {!!auth?.user && (
                <Box>
                  <ProfileMenu />
                </Box>
              )}
            </Flex>
          </Box>
        </Collapse>
      </Container>
    </Box>
  );
};

export default NavBar;
