import {
  AspectRatio,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FileStore } from "../../interfaces/FileUpload";

interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  file?: FileStore;
}
const MediaModal = ({ isOpen, onClose, file }: MediaModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={4} backgroundColor="black">
          {file?.fileType === "audio" && (
            <audio controls>
              <source src={file?.url || file?.fileUrl} type={file?.mimeType} />
              Your browser does not support the audio element.
            </audio>
          )}
          {file?.fileType === "image" && (
            <Image
              backgroundColor="black"
              src={file?.url || file?.fileUrl}
              objectFit="contain"
              borderRadius="5px"
              alt="media file"
              boxSize="full"
              maxH="80dvh"
              maxW="80dvw"
              margin="auto"
            />
          )}
          {file?.fileType === "video" && (
            <AspectRatio boxSize="full" ratio={16 / 9}>
              <video
                controls
                autoPlay
                style={{ backgroundColor: "black", objectFit: "contain" }}
              >
                <source
                  src={file?.url || file?.fileUrl}
                  type={file?.mimeType}
                />
                Your browser does not support the video tag.
              </video>
            </AspectRatio>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MediaModal;
