import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

interface LinkProps extends ChakraLinkProps {
  children: React.ReactNode;
  to: string;
  isRouter?: boolean;
}

const Link = ({ isRouter = true, children, to, ...props }: LinkProps) => {
  const location = useLocation();
  if (location.pathname === "/" && (to.startsWith("#") || to.startsWith("/#")))
    return (
      <ChakraLink href={to} {...props}>
        {children}
      </ChakraLink>
    );
  if (!isRouter)
    return (
      <ChakraLink href={to} {...props}>
        {children}
      </ChakraLink>
    );
  return (
    <ChakraLink as={RouterLink} to={to} end={to === ""} {...props}>
      {children}
    </ChakraLink>
  );
};

export default Link;
