import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";

interface FormSelectProps extends FormControlProps {
  form: any;
  label: string;
  id: string;
  options?: any;
  children: React.ReactNode;
}

const FormSelect = ({
  form,
  label,
  id,
  options,
  children,
  ...props
}: FormSelectProps) => {
  return (
    <FormControl my={4} isInvalid={form.formState.errors[id]} {...props}>
      <FormLabel
        fontWeight="semibold"
        fontSize="sm"
        color="textSecondary"
        htmlFor={id}
      >
        {label}
      </FormLabel>
      <Select
        _invalid={{
          bg: "#FFCCCC",
          border: "1px solid #B13D3D",
          boxShadow: "0 0 0 1px #e53e3e",
        }}
        id={id}
        {...form.register(id, options)}
      >
        {children}
      </Select>
      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormSelect;
