import { Icon, IconProps } from "@chakra-ui/react";

const Message = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 33 32" fill="currentcolor" {...props}>
      <path d="M23.3333 28.3334H9.99995C5.13328 28.3334 2.33328 25.5334 2.33328 20.6667V11.3334C2.33328 6.46675 5.13328 3.66675 9.99995 3.66675H19.3333C19.8799 3.66675 20.3333 4.12008 20.3333 4.66675C20.3333 5.21341 19.8799 5.66675 19.3333 5.66675H9.99995C6.18662 5.66675 4.33328 7.52008 4.33328 11.3334V20.6667C4.33328 24.4801 6.18662 26.3334 9.99995 26.3334H23.3333C27.1466 26.3334 29 24.4801 29 20.6667V14.0001C29 13.4534 29.4533 13.0001 30 13.0001C30.5466 13.0001 31 13.4534 31 14.0001V20.6667C31 25.5334 28.1999 28.3334 23.3333 28.3334Z" />
      <path d="M16.6663 17.1599C15.5463 17.1599 14.413 16.8133 13.5463 16.1066L9.37301 12.7733C8.94634 12.4266 8.86634 11.7999 9.21301 11.3733C9.55968 10.9466 10.1863 10.8666 10.613 11.2133L14.7863 14.5466C15.7997 15.36 17.5197 15.36 18.533 14.5466L20.1063 13.2933C20.533 12.9466 21.173 13.0133 21.5063 13.4533C21.853 13.8799 21.7864 14.5199 21.3464 14.8533L19.773 16.1066C18.9197 16.8133 17.7863 17.1599 16.6663 17.1599Z" />
      <path d="M26.6666 11.6667C24.2799 11.6667 22.3333 9.72 22.3333 7.33333C22.3333 4.94667 24.2799 3 26.6666 3C29.0533 3 31 4.94667 31 7.33333C31 9.72 29.0533 11.6667 26.6666 11.6667ZM26.6666 5C25.3866 5 24.3333 6.05333 24.3333 7.33333C24.3333 8.61333 25.3866 9.66667 26.6666 9.66667C27.9466 9.66667 28.9999 8.61333 28.9999 7.33333C28.9999 6.05333 27.9466 5 26.6666 5Z" />
    </Icon>
  );
};

export default Message;
