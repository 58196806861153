import { Icon, IconProps } from "@chakra-ui/react";

const Search = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M7.66659 14.5002C3.89992 14.5002 0.833252 11.4335 0.833252 7.66683C0.833252 3.90016 3.89992 0.833496 7.66659 0.833496C11.4333 0.833496 14.4999 3.90016 14.4999 7.66683C14.4999 11.4335 11.4333 14.5002 7.66659 14.5002ZM7.66659 1.8335C4.44659 1.8335 1.83325 4.4535 1.83325 7.66683C1.83325 10.8802 4.44659 13.5002 7.66659 13.5002C10.8866 13.5002 13.4999 10.8802 13.4999 7.66683C13.4999 4.4535 10.8866 1.8335 7.66659 1.8335Z"
        fill="#A0A6A1"
      />
      <path
        d="M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z"
        fill="#A0A6A1"
      />
    </Icon>
  );
};

export default Search;
