import {
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/useAuth";
import { useUploads } from "../../context/useUploads";
import getUserRole from "../../helpers/getUserRole";
import { AgencyUser } from "../../interfaces/AgencyUser";
import { ReportActionDTO } from "../../interfaces/ReportAction";
import agenciesService from "../../services/agenciesService";
import agencyUsersService from "../../services/agencyUsersService";
import reportsService from "../../services/reportsService";
import TransferModal from "./TransferModal";

interface NoteProps extends FlexProps {
  reportId: number;
  agencyId: number;
  isAgent?: boolean;
  agentAssigned?: string;
  dateAssigned?: string;
}

const Note = ({
  reportId,
  agencyId,
  isAgent,
  agentAssigned,
  dateAssigned,
  ...props
}: NoteProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm();
  const toast = useToast();
  const auth = useAuth();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { uploads } = useUploads();
  const navigate = useNavigate();
  const [agencyUser, setAgencyUser] = useState<AgencyUser[]>([]);
  const { isAgencyAdmin, isAgencyUser } = getUserRole(auth?.user?.roles);
  const location = useLocation();
  const { data: statusRes } = useQuery(
    "statuses",
    () => reportsService.getReportStatuses(),
    { staleTime: 60000 }
  );
  const { data: agenciesRes } = useQuery(
    ["agencies", 1, 10],
    () => agenciesService.getAgencies(1, 10),
    { staleTime: 60000 }
  );
  const { data: agencyUsersRes } = useQuery(
    ["agencyUsers", Number(auth?.user?.agency?.id), 1, 50],
    () =>
      agencyUsersService.getAgencyUsers(Number(auth?.user?.agency?.id), 1, 50),
    { enabled: !!isAgencyAdmin && isAgent, staleTime: 60000 }
  );

  useEffect(() => {
    if (agencyUser?.length > 0) form.setValue("agencyUser", agentAssigned);
  }, [agentAssigned, form, agencyUser]);

  useEffect(() => {
    setAgencyUser(agencyUsersRes?.data.body.data);
  }, [agencyUsersRes]);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onSubmit = ({
    action: reportStatusId,
    note,
    agency,
    agencyUser,
  }: any) => {
    if (reportStatusId === "5" && !isOpen) {
      onOpen();
      return;
    }
    setIsLoading(true);
    const reportActionAttachments = uploads.map(upload => ({
      fileId: Number(upload.id),
    }));

    if (!!isAgencyUser) {
      const data: ReportActionDTO = {
        agencyId,
        reportId,
        note,
        reportStatusId: Number(reportStatusId),
        reportActionAttachments,
        extraData:
          agencyUser && agencyUser !== agentAssigned
            ? agencyUser
            : agency
            ? Number(agency)
            : "",
      };
      reportsService
        .takeActionOnReport(data)
        .then(() => {
          toast({
            title: "Action taken successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          if (location.pathname.split("/")[2] === "my-cases")
            navigate("/dashboard/my-cases");
          else if (location.pathname.split("/")[2] === "assigned-cases")
            navigate("/dashboard/assigned-cases");
          else navigate("/dashboard");
        })
        .catch(err => {
          toast({
            title: "Failed to take Action",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          console.error(err.response);
        })
        .finally(() => setIsLoading(false));
    } else {
      const data: ReportActionDTO = {
        agencyId,
        reportId,
        note,
        reportStatusId: Number(reportStatusId),
        reportsAdditionalInfoAttachment: reportActionAttachments,
        extraData: Number(agency),
      };
      reportsService
        .addMoreInformation(data)
        .then(() => {
          toast({
            title: "Information added successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          if (location.pathname.split("/")[2] === "my-cases")
            navigate("/dashboard/my-cases");
          else if (location.pathname.split("/")[2] === "assigned-cases")
            navigate("/dashboard/assigned-cases");
          else navigate("/dashboard");
        })
        .catch(err => {
          toast({
            title: "Failed to add information",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          console.error(err.response);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Flex
      flexDir="column"
      border="1px solid #D4D4D4"
      borderRadius="10px"
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      p={4}
      {...props}
    >
      <Box flex="0 1 auto">
        <Text color="textSecondary" my={4} fontSize="md" fontWeight="semibold">
          Note
        </Text>
        <Divider bg="#D4D4D4" my={4} />
      </Box>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
        flex="1 1 auto"
        flexDir="column"
        gap={4}
      >
        <FormControl h="full" isInvalid={form.formState.errors.note}>
          <Textarea
            h="calc(100% - 40px)"
            minH="149px"
            flex="1 1 auto"
            resize="none"
            placeholder="Add note here"
            _placeholder={{
              color: "text",
              fontSize: "xs",
              fontWeight: "normal",
            }}
            {...form.register("note", {
              required: "Note is required",
            })}
            maxLength={100}
          />
          <FormErrorMessage>
            {form.formState.errors.note && form.formState.errors.note.message}
          </FormErrorMessage>
        </FormControl>
        <Flex
          flex="0 1 auto"
          justify="space-between"
          gap={4}
          direction={{ base: "column", sm: "row" }}
        >
          {!!isAgencyUser && (
            <>
              {!!isAgencyAdmin && isAgent && (
                <FormControl isInvalid={form.formState.errors.agencyUser}>
                  <Select
                    {...form.register("agencyUser", {
                      required: "Select user to assign case",
                    })}
                  >
                    <option value="">Assign to User</option>
                    {agencyUser &&
                      agencyUser.map(user => (
                        <option
                          value={user.userProfile?.email}
                          key={user.userProfileId}
                        >
                          {user.userProfile?.email}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage>
                    {form.formState.errors.agencyUser &&
                      form.formState.errors.agencyUser.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl
                w={{
                  base: "full",
                  sm: "auto",
                }}
                isInvalid={form.formState.errors.action}
              >
                <Select
                  {...form.register("action", {
                    required: "Action is required",
                  })}
                >
                  <option value="">Action</option>
                  {statusRes?.data.body.data
                    .sort((a, b) => a.order - b.order)
                    .map(status => (
                      <option value={status.id} key={status.id}>
                        {status.name}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>
                  {form.formState.errors.action &&
                    form.formState.errors.action.message}
                </FormErrorMessage>
              </FormControl>
            </>
          )}
          <Button ref={buttonRef} type="submit" isLoading={isLoading} px={10}>
            Apply
          </Button>
        </Flex>
        {!!isAgencyUser && (
          <TransferModal
            isLoading={isLoading}
            agencies={agenciesRes?.data.body.data}
            form={form}
            isOpen={isOpen}
            onClose={onClose}
            buttonRef={buttonRef}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Note;
