import axiosInstance from "../helpers/axiosInstance";
import { Agency } from "../interfaces/Agency";

const getAgencies = (page: number, size: number) => {
  const url = `/agency?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const createAgency = (data: Agency) => {
  const url = `/agency`;
  return axiosInstance.post(url, data);
};

const editAgency = (data: Agency) => {
  const url = `/agency`;
  return axiosInstance.put(url, data);
};

const getAgency = (agencyId: number) => {
  const url = `/agency/${agencyId}`;
  return axiosInstance.get(url);
};

const deleteAgency = (agencyId: number) => {
  const url = `/agency/${agencyId}`;
  return axiosInstance.delete(url);
};

const getAgencyIncidenceTypes = (agencyId: number) => {
  const url = `/agency/incidencType/${agencyId}`;
  return axiosInstance.get(url);
};

const agenciesService = {
  getAgencies,
  createAgency,
  editAgency,
  getAgencyIncidenceTypes,
  getAgency,
  deleteAgency,
};

export default agenciesService;
