import { Icon, IconProps } from "@chakra-ui/react";

const Facebook = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.750577 3.5935C0 5.03843 0 6.93673 0 10.7333V21.2667C0 25.0633 0 26.9616 0.750577 28.4065C1.38308 29.6241 2.37588 30.6169 3.5935 31.2494C5.03843 32 6.93673 32 10.7333 32H21.2667C25.0633 32 26.9616 32 28.4065 31.2494C29.6241 30.6169 30.6169 29.6241 31.2494 28.4065C32 26.9616 32 25.0633 32 21.2667V10.7333C32 6.93673 32 5.03843 31.2494 3.5935C30.6169 2.37588 29.6241 1.38308 28.4065 0.750577C26.9616 0 25.0633 0 21.2667 0H10.7333C6.93673 0 5.03843 0 3.5935 0.750577C2.37588 1.38308 1.38308 2.37588 0.750577 3.5935ZM22.9333 16L22.2222 20.4444H18.6667V32H13.3333V20.4444H9.33333V16H13.3333V12.4444C13.3333 8.44443 15.9111 6.22221 19.5556 6.22221C20.7111 6.22221 21.9556 6.39999 23.1111 6.57776V10.6667H21.0667C19.1111 10.6667 18.6667 11.6444 18.6667 12.8889V16H22.9333Z"
        fill="#0A230E"
      />
    </Icon>
  );
};

export default Facebook;
