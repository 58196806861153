import { Box, Flex, IconButton, Image, Stack, Text } from "@chakra-ui/react";

import partnerOne from "../assets/images/partner-1.png";
import partner from "../assets/images/partner.png";
import Container from "../components/common/Container";
import Link from "../components/common/Link";
import Facebook from "../components/svg/Facebook";
import Twitter from "../components/svg/Twitter";

interface QuickLinks {
  label: string;
  href: string;
}

const quickLinks: QuickLinks[] = [
  { label: "About", href: "/#about" },
  { label: "How It Works", href: "/#how-it-works" },
  { label: "FAQs", href: "/faqs" },
  { label: "Submit a report", href: "/dashboard/my-cases/report" },
  { label: "Privacy Policy", href: "/privacy-policy" },
];

const Footer = () => {
  return (
    <Container my={8}>
      <Box color="#0A230E">
        <Flex
          flexDir={{ base: "column", sm: "row" }}
          align={{ base: "center", sm: "normal" }}
          justify="space-between"
          gap={{ base: 10, sm: 4 }}
          mb={16}
        >
          <Flex gap={6} fontSize="sm" textAlign="center">
            <Box>
              <Text mb={1}>Initiative of</Text>
              <Image src={partnerOne} w="121px" />
            </Box>
            <Box>
              <Text mb={1}>Supported by</Text>
              <Image src={partner} w="121px" />
            </Box>
          </Flex>
          <Stack spacing={4} align={{ base: "center", sm: "normal" }}>
            <Text fontWeight="bold" mb={4}>
              Quick Links
            </Text>
            {quickLinks.map((link, key) => (
              <Link display="block" key={key} to={link.href}>
                {link.label}
              </Link>
            ))}
          </Stack>
          <Flex
            gap={6}
            flexDir="column"
            align={{ base: "center", sm: "flex-end" }}
            textAlign={{ base: "center", sm: "right" }}
          >
            <Box>
              <Link
                to="https://www.facebook.com/akinfadeyifoundation"
                isExternal
                isRouter={false}
              >
                <IconButton
                  size="lg"
                  variant="ghost"
                  icon={<Facebook fontSize="30px" />}
                  aria-label="go to facebook page"
                />
              </Link>
              <Link
                to="https://twitter.com/flagit_aff"
                isExternal
                isRouter={false}
              >
                <IconButton
                  size="lg"
                  variant="ghost"
                  icon={<Twitter fontSize="30px" />}
                  aria-label="go to twitter page"
                />
              </Link>
            </Box>
            <Text>
              The Akin Fadeyi Foundation
              <br />
              Golden Spring Estate Abuja.
            </Text>
            <Link isRouter={false} to="tel:+234(0)9051922874">
              +234 (0) 905 192 2874{" "}
            </Link>
            <Link isRouter={false} to="mailto:info@akinfadeyifoundation.org">
              info@akinfadeyifoundation.org
            </Link>
          </Flex>
        </Flex>

        <Box p={6} color="textSecondary" textAlign="center">
          Copyright © {new Date().getFullYear()} - All rights Reserved
        </Box>
      </Box>
    </Container>
  );
};

export default Footer;
