import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Grid,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Spinner,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { useAuth } from "../../context/useAuth";
import getUserRole from "../../helpers/getUserRole";
import { Stats } from "../../interfaces/Report";
import ClosedCases from "../svg/ClosedCases";
import Export from "../svg/Export";
import NewCases from "../svg/NewCases";
import OngoingCases from "../svg/OngoingCases";
import PendingCases from "../svg/PendingCases";
import UnassignedCases from "../svg/UnassignedCases";
import EmptyState from "./EmptyState";
import Search from "./Search";

export type TableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  pageCount: number;
  totalCount: number;
  loading: boolean;
  filter: string;
  handlePagination: (pageIndex: number, pageSize: number) => void;
  handleSort: (sort: { id: string; desc?: boolean }) => void;
  handleExport: () => void;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  statistics?: Stats;
  isExporting: boolean;
  setFilter: (value: React.SetStateAction<string>) => void;
  ExportComponent: React.ReactElement;
};

const DataTable = <Data extends object>({
  data,
  columns,
  pageCount: controlledPageCount,
  loading,
  totalCount,
  filter,
  handlePagination,
  handleSort,
  query,
  setQuery,
  statistics,
  setFilter,
  handleExport,
  isExporting,
  ExportComponent,
}: TableProps<Data>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageSize,
      pageIndex,
      sortBy: [sort],
    },
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );
  const navigate = useNavigate();
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);

  const recordsCountFrom =
    pageIndex + 1 === pageCount && pageCount !== 1
      ? totalCount - pageSize + 1
      : pageIndex * pageSize + 1;
  const recordsCountTo = recordsCountFrom + page.length - 1;

  useEffect(() => {
    handlePagination(pageIndex + 1, pageSize);
  }, [pageIndex, pageSize, handlePagination]);

  useEffect(() => {
    if (sort) handleSort(sort);
  }, [sort, handleSort]);

  return (
    <Box
      p={4}
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      border="1px solid #D4D4D4"
      borderRadius="10px"
    >
      <Flex justify="space-between" align="center" flexWrap="wrap" gap={4}>
        <Flex gap={4} align="center">
          <Grid
            bg={
              filter === "unassignedCases"
                ? "#FFCCCC3D"
                : filter === "ongoingCases"
                ? "#F5EFE7"
                : filter === "pendingCases"
                ? "#FFF5003D"
                : filter === "closedCases"
                ? "#D8F2F58C"
                : "#D8F5F05C"
            }
            placeItems="center"
            boxSize="50px"
            borderRadius="full"
          >
            {filter === "unassignedCases" ? (
              <UnassignedCases boxSize="24px" color="#B13D3D" />
            ) : filter === "ongoingCases" ? (
              <OngoingCases boxSize="24px" color="#B13D3D" />
            ) : filter === "pendingCases" ? (
              <PendingCases boxSize="24px" color="#B13D3D" />
            ) : filter === "closedCases" ? (
              <ClosedCases boxSize="24px" color="#B13D3D" />
            ) : (
              <NewCases boxSize="24px" color="#3DCDB5" />
            )}
          </Grid>
          <Text
            my={2}
            fontSize="md"
            fontWeight="semibold"
            color="textSecondary"
            textTransform="capitalize"
          >
            {filter ? `${filter.split("Cases")[0]} Cases` : "All Cases"}:
          </Text>
          <Text
            color={
              filter === "unassignedCases"
                ? "#B13D3D"
                : filter === "ongoingCases"
                ? "#CFAE89"
                : filter === "pendingCases"
                ? "#EFC14A"
                : filter === "closedCases"
                ? "#2ED2E9"
                : "#3DCDB5"
            }
            fontSize="2xl"
            fontWeight="bold"
            bgColor={
              filter === "unassignedCases"
                ? "#FFCCCC3D"
                : filter === "ongoingCases"
                ? "#F5EFE7"
                : filter === "pendingCases"
                ? "#FFF5003D"
                : filter === "closedCases"
                ? "#D8F2F58C"
                : "#D8F5F05C"
            }
            boxSize="41px"
            borderRadius="10px"
            display="grid"
            placeItems="center"
          >
            {totalCount}
          </Text>
        </Flex>
        <Flex gap={4} align="center" wrap="wrap">
          <Search value={query} onChange={e => setQuery(e.target.value)} />
          <Popover>
            <PopoverTrigger>
              <Button variant="outline" rightIcon={<Export />}>
                Export
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader py={4}></PopoverHeader>
              <PopoverBody display="flex" flexDirection="column" gap={4} py={4}>
                {ExportComponent}
              </PopoverBody>
              <PopoverFooter>
                <Button
                  w="full"
                  onClick={handleExport}
                  isLoading={isExporting}
                  isDisabled={loading || data.length === 0 || isExporting}
                >
                  Download
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
          {statistics && (
            <Select
              fontSize="sm"
              w="max-content"
              value={filter}
              onChange={e =>
                setFilter(
                  e.target.value === "casesSubmitted" ? "" : e.target.value
                )
              }
              textTransform="capitalize"
            >
              {Object.keys(statistics).map(stat => (
                <option key={stat} value={stat}>
                  {stat === "casesSubmitted"
                    ? "All Cases"
                    : stat.split("Cases")[0]}
                </option>
              ))}
            </Select>
          )}
        </Flex>
      </Flex>
      <Divider my={6} bg="#D4D4D4" />
      {!loading && data.length === 0 ? (
        <EmptyState isFilter />
      ) : (
        <>
          <TableContainer>
            <ChakraTable fontSize="xs" {...getTableProps()}>
              <Thead bg="#DADADA21">
                {headerGroups.map(headerGroup => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <Th
                        color="text"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        {...(loading && {
                          cursor: "not-allowed",
                          pointerEvents: "none",
                        })}
                      >
                        {column.render("Header")}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      cursor="pointer"
                      _hover={{ bg: "gray.50" }}
                    >
                      {row.cells.map(cell => (
                        <Td
                          color="textSecondary"
                          {...cell.getCellProps()}
                          onClick={() => {
                            const reportId = (row.original as any).id;
                            if (isOverallAdmin)
                              navigate(`/dashboard/overall-cases/${reportId}`);
                            else
                              navigate(`/dashboard/agency-cases/${reportId}`);
                          }}
                        >
                          <Text as="span" maxW={300} noOfLines={1}>
                            {cell.render("Cell")}
                          </Text>
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </ChakraTable>
          </TableContainer>
          <Flex justify="space-between" my={4} flexWrap="wrap" gap={4}>
            <Flex fontSize="xs" align="center" gap={2}>
              <Text color="textSecondary" whiteSpace="nowrap">
                Show rows per page
              </Text>
              <Select
                size="sm"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex align="center" gap={8} fontSize="sm">
              {loading ? (
                <Spinner />
              ) : (
                <Box>
                  <Box as="span" color="textSecondary">
                    {`${recordsCountFrom}-${recordsCountTo} `}
                  </Box>
                  of {totalCount}
                </Box>
              )}
              <Flex align="center" gap={2}>
                <IconButton
                  size="xs"
                  variant="link"
                  icon={<ChevronLeftIcon fontSize="1rem" />}
                  aria-label="go to previous page"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <IconButton
                  size="xs"
                  variant="link"
                  icon={<ChevronRightIcon fontSize="1rem" />}
                  aria-label="go to previous page"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default DataTable;
