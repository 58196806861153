import { Accordion, Box, ContainerProps, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import FAQItem from "../../components/faq/FAQItem";
import faq from "../../constants/faq";

export const FAQ = ({ ...props }: ContainerProps) => {
  return (
    <Container py={10} {...props}>
      <Helmet>
        <title>FlagIt | Frequently Asked Questions</title>
      </Helmet>
      <Box
        textAlign={{ base: "center", md: "unset" }}
        maxW={{ base: "unset", md: "646px" }}
        mb={4}
      >
        <Heading mb={2} fontSize="30px">
          Frequently Asked Questions
        </Heading>
        <Text fontSize="18px">
          Get ready answers to your questions about the product
        </Text>
      </Box>
      <Accordion w={{ base: "auto", md: "629.5px" }} allowToggle>
        {faq.map((item, index) => (
          <FAQItem question={item.question} answer={item.answer} key={index} />
        ))}
      </Accordion>
    </Container>
  );
};
