import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { ReportPayload } from "../interfaces/Report";
import reportsService from "../services/reportsService";

const useGetOverallReports = (payload: ReportPayload) => {
  const toast = useToast();
  const { data, isError, isFetching, error } = useQuery(
    ["reports", payload],
    () => reportsService.getOverallReports(payload),
    { staleTime: 60000 }
  );

  useEffect(() => {
    if (!isError) return;
    const message = axios.isAxiosError(error)
      ? (error.response?.data as any)?.message ?? error.response?.statusText
      : "An unknown error occurred";

    toast({
      title: "Failed to fetch overall reports",
      description: message ?? "An unknown error occurred",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }, [isError, error, toast]);

  return {
    isFetchingOverallReports: isFetching,
    overallReports: data?.data.body,
  };
};

export default useGetOverallReports;
