import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/flagit-logo.png";
import VerifyForm from "../../components/auth/VerifyForm";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import Link from "../../components/common/Link";
import { useAuth } from "../../context/useAuth";

export const Verify = () => {
  const auth = useAuth();
  return (
    <Container p={4}>
      <Helmet>
        <title>FlagIt | Verify</title>
      </Helmet>
      <Box my={{ base: 0, sm: 8 }}>
        <Box w="92px">
          <Link to="/">
            <Image src={logo} />
          </Link>
        </Box>
      </Box>
      <Flex align="center" justify="center">
        <Box textAlign="center">
          <Heading size="2xl">Enter Code</Heading>
          <Text my={2}>
            Please enter the code sent to{" "}
            <Text as="span" color="textSecondary">
              {auth?.userData?.phoneNumber}
            </Text>
          </Text>
          <VerifyForm />
        </Box>
      </Flex>
    </Container>
  );
};
