import { Box, Button, Flex, Select, Text, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { Column } from "react-table";
import Heading from "../../../components/common/Heading";
import Table from "../../../components/common/Table";
import Tag from "../../../components/common/Tag";
import Export from "../../../components/svg/Export";
import { Report, ReportStatus } from "../../../interfaces/Report";
import reportsService from "../../../services/reportsService";

export const AssignedCases = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const fetchIdRef = useRef(0);
  const toast = useToast();
  const [filter, setFilter] = useState("");
  const { data: statusRes } = useQuery(
    "statuses",
    () => reportsService.getReportStatuses(),
    { staleTime: 60000 }
  );

  const mutation = useMutation(() =>
    reportsService.exportAgentReports(pageIndex, pageSize, filter)
  );

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to export report",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      const url = window.URL.createObjectURL(new Blob([mutation.data.data]));
      const headerLine = mutation.data.headers["content-disposition"];
      const startFileNameIndex = headerLine?.indexOf('"') + 1;
      const endFileNameIndex = headerLine?.lastIndexOf('"');
      const fileName = headerLine?.substring(
        startFileNameIndex,
        endFileNameIndex
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [mutation.isSuccess, mutation.data]);

  const tableData = React.useMemo(() => reports, [reports]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Case Number",
        accessor: "caseNumber",
      },
      {
        Header: "First Name",
        accessor: "reporterFirstName",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterFirstName || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Last Name",
        accessor: "reporterLastName",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterLastName || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Email",
        accessor: "reporterEmail",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterEmail || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "reporterPhoneNumber",
        Cell: ({ cell }) => (
          <Text>{cell.row.values.reporterPhoneNumber || "Anonymous"}</Text>
        ),
      },
      {
        Header: "Incident",
        accessor: "incidenceType.name",
      },
      {
        Header: "Date submitted",
        accessor: "createdDate",
        Cell: ({ cell }) => (
          <Text>
            {moment(cell.row.values.createdDate).format("YYYY-MM-DD")}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "reportStatusName",
        Cell: ({ cell }) => (
          <Tag variant={cell.row.values.reportStatusName}>
            {cell.row.values.reportStatusName}
          </Tag>
        ),
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setPageIndex(pageIndex + 1);
      setPageSize(pageSize);
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        if (filter)
          reportsService
            .getAssignedReportsByStatus(filter, pageIndex + 1, pageSize)
            .then(res => {
              setReports(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
            })
            .catch(err => {
              toast({
                title: "Failed to fetch assigned reports",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              console.error(err.response);
            })
            .finally(() => setLoading(false));
        else
          reportsService
            .getAssignedReports(pageIndex + 1, pageSize)
            .then(res => {
              setReports(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
            })
            .catch(err => {
              toast({
                title: "Failed to fetch assigned reports",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              console.error(err.response);
            })
            .finally(() => setLoading(false));
      }
    },
    [toast, filter]
  );
  return (
    <Box>
      <Helmet>
        <title>FlagIt | Assigned Cases</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>Assigned Cases</Heading>
        <Button
          variant="outline"
          onClick={() => mutation.mutate()}
          isLoading={mutation.isLoading}
          rightIcon={<Export />}
          isDisabled={loading || tableData.length === 0}
        >
          Export
        </Button>
      </Flex>

      {tableData && (
        <Table
          route="/dashboard/assigned-cases"
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Assigned Cases"
          filter={filter}
          FilterComponent={
            <Select
              fontSize="sm"
              w="max-content"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            >
              <option value="">Status</option>
              {(statusRes?.data.body.data as ReportStatus[])
                ?.sort((a, b) => a.order - b.order)
                .map(status => (
                  <option value={status.name} key={status.id}>
                    {status.name}
                  </option>
                ))}
            </Select>
          }
        />
      )}
    </Box>
  );
};
