import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";

export const PrivacyPolicy = () => {
  return (
    <Container py={10} lineHeight="24px">
      <Helmet>
        <title>FlagIt | Privacy Policy</title>
      </Helmet>
      <Box maxW="1000px">
        <Box my={4}>
          <Heading fontSize="45px" mb={2}>
            Privacy Policy
          </Heading>
          <Text>
            Welcome to the{" "}
            <Text as="span" color="#0A230E" fontWeight="bold">
              FLAGIT APP
            </Text>
            , an online accountability platform initiated by the Akin Fadeyi
            Foundation (“FlagIt, “We”, “Us” or “Our”). This privacy policy
            explains how we collect, use, disclose and protect information that
            applies to our services, and your choice about the collection and
            use of your information. This privacy policy applies to both
            agencies, institutions, and the general public (individually
            referred to as “you”) of our website and mobile application to which
            this policy is posted. Welcome to the FLAGIT APP, an online
            accountability platform initiated by the Akin Fadeyi Foundation
            (“FlagIt, “We”, “Us” or “Our”). This privacy policy explains how we
            collect, use, disclose and protect information that applies to our
            services, and your choice about the collection and use of your
            information. This privacy policy applies to both agencies,
            institutions, and the general public (individually referred to as
            “you”) of our website and mobile application to which this policy is
            posted.
          </Text>
        </Box>
        <Box my={4}>
          <Heading fontSize="24px" mb={2}>
            APPLICATION OF THIS PRIVACY POLICY
          </Heading>
          <Text>
            This policy applies to the use of our platforms, regardless of the
            means of access. You may utilise our platforms through a desktop,
            laptop, mobile phone, tablet, or other electronic devices.
          </Text>
        </Box>
        <Box my={4}>
          <Heading fontSize="md" mb={2}>
            INFORMATION WE COLLECT
          </Heading>
          <Box my={4}>
            <Text color="#0A230E" fontWeight="semibold">
              Information You Provide Us
            </Text>
            <Text>
              When you log on to our website or download the app to make a
              report, you will be required to create an account by signing up on
              the App. When you create an account, submit a report, or
              correspond with us, we will ask for information such as username,
              first and last name, phone number, location, e-mail address,
              pictures, and videos of incident reports. If someone else
              witnesses an incident, the contact number of the witness may be
              required.
              <br />
              <br />
              We may also retain any messages you send through the platform and
              may collect the information you provide in the User Content you
              post to the platform. The Agency uses your information to operate,
              maintain and provide feedback on reports made. We may also use
              this information to correspond with you and address any issues you
              raise about the platform.
            </Text>
          </Box>
          <Box my={4}>
            <Text color="#0A230E" fontWeight="semibold">
              Information From Third Parties
            </Text>
            <Text>
              We may receive information about you from third parties. For
              example, if you were a witness to an incident that was reported on
              our Platform(s), your name, email address and phone number may be
              provided.
            </Text>
          </Box>
          <Box my={4}>
            <Box my={4}>
              <Text fontWeight="semibold" color="#0A230E">
                Information Automatically Collected
              </Text>
              <Text>
                We may collect information about your use of our Platforms. This
                information may include Personal Information as well as
                statistical information that does not identify you
                ("Analytics"). Some Analytics may be correlated with Personal
                Information. When Analytics are, directly or indirectly,
                associated or combined with Personal Information, such Analytics
                will be considered Personal Information for purposes of this
                Privacy Policy. Information that we automatically collect in
                connection with your access or use of our Platforms may include:
              </Text>
            </Box>
            <Box my={4}>
              <Text color="#0A230E">Device Information:</Text>
              <Text>
                We may collect device-specific information (such as hardware
                model, operating system version, unique device identifiers, and
                mobile network information, including your mobile phone number).
                We may associate your device identifiers or mobile phone number
                with your FlagIt account.
              </Text>
            </Box>
            <Box my={4}>
              <Text color="#0A230E">Log Information:</Text>
              <Text>
                We may record or log information from your devices, their
                software, and your activity accessing or using our Platforms.
                This information may include:
              </Text>
              <UnorderedList>
                <ListItem my={3}>
                  The Device's Internet Protocol ("IP") address
                </ListItem>
                <ListItem my={3}>
                  Identification numbers associated with your devices
                </ListItem>
                <ListItem my={3}>
                  Device event information such as crashes, system activity, and
                  hardware settings
                </ListItem>
                <ListItem my={3}>Location preferences</ListItem>
                <ListItem my={3}>Date and time stamps of transactions</ListItem>
                <ListItem my={3}>System configuration information</ListItem>
                <ListItem my={3}>
                  Other interactions with our services{" "}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          <Box my={4}>
            <Text color="#0A230E" fontWeight="semibold">
              Where we store user data
            </Text>
            <Text>
              The reports sent in via the FlagIt App will be stored on dedicated
              private servers. By choosing to use the app and providing us with
              your personal information while submitting a report, you agree to
              this transfer, storage, or processing. All information provided is
              confidential and not shared with third parties not already stated
              on the application.
            </Text>
          </Box>
          <Box my={4}>
            <Text color="#0A230E" fontWeight="semibold">
              Who it will be shared with?
            </Text>
            <Text>
              Data collected on the FlagIt platforms will only be shared with
              the relevant agencies concerned. We will not share any user data
              with third-party organizations outside of those directly
              concerned. We take deliberate and actionable steps to ensure that
              third parties who have access to your personal information treat
              it with the same consideration that we do.
            </Text>
          </Box>
        </Box>
        <Box my={4}>
          <Heading fontSize="24px" mb={2}>
            COOKIES INFORMATION
          </Heading>
          <Text>
            When you visit our Platforms, we may send one or more cookies — a
            small text file containing a string of alphanumeric characters — to
            your computer that uniquely identifies your browser and lets FlagIt
            help you log in faster and enhance your navigation through the site.
            You can control or reset your cookies through your web browser,
            which will allow you to customize your cookie preferences and to
            refuse all cookies or to indicate when a cookie is being sent.
            However, some features of the Service may not function properly if
            the ability to accept cookies is disabled.
          </Text>
        </Box>
        <Box my={4}>
          <Text color="#0A230E">Location Data</Text>
          <Text>
            When you access the Platforms on a mobile device, we may access,
            collect, monitor and/or remotely store “location data,” which may
            include GPS coordinates (latitude and/or longitude) or similar
            information regarding the location of your mobile device. Location
            data may convey to us information about where and when reports were
            made. You can disable location sharing, however, some features of
            the Platform, may not function properly if the use or availability
            of location data is impaired or disabled.
          </Text>
        </Box>
        <Box my={4}>
          <Heading fontSize="24px" mb={2}>
            HOW WE USE YOUR INFORMATION
          </Heading>
          <Text>
            We use the information we collect about you for the following
            purposes:
          </Text>
        </Box>
        <Box my={4}>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              Responding to Reports:
            </Text>{" "}
            We use the information that you provide us to give feedback and
            respond to reports you make on the Platforms. This includes allowing
            you to log into the FlagIt App and respond to any queries or reports
            made. We also use the information we collect to enable you
            automatically access the app without having to re-enter your
            previous information the next time you visit the site.
          </Box>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              For data analytics:
            </Text>{" "}
            We use information about you to help us improve the FLAG IT service
            and our users’ experience, including by monitoring aggregate metrics
            such as total number of visitors, traffic, and demographic patterns.
          </Box>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              For internal Use:
            </Text>{" "}
            This is meant to keep our services secured and operational, such as
            for troubleshooting and testing purposes, and for service
            improvement, research, and development purposes.
          </Box>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              To communicate with you about the Service:
            </Text>{" "}
            We use your contact information to get in touch with you and to send
            communications about critical elements of the Service/Platform. For
            example, we may send you emails about technical issues, security
            alerts, or administrative matters.
          </Box>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              User Satisfaction:
            </Text>{" "}
            We use information about you and information that you provide to us
            to resolve technical issues you experience with the Platforms, and
            to ensure that we can repair and improve the FlagIt platforms.
          </Box>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              For security measures:
            </Text>{" "}
            We use information about you to monitor activities that we think is
            suspicious or potentially fraudulent, and to identify violations of
            this Privacy Policy or our Terms of Service.
          </Box>
          <Box my={4}>
            <Text as="span" color="#0A230E">
              For matters that you have specifically consent to:
            </Text>{" "}
            From time to time, we may seek your consent to use your information
            for a particular purpose. Where you consent to our doing so, we will
            use it for that purpose only, and when you no longer want us to use
            your information, you may withdraw your consent to this use.
            <br />
            <br />
            Thank you for consenting to use the FlagIt Accountability App. We
            take the issue of confidentiality and privacy very seriously and
            will do our best to abide by the principle of ‘Do No Harm’ in the
            handling, storage, and processing of information received by users.
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
