import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Box,
  Flex,
  useToast,
  Progress,
  Input,
  Avatar,
  Spinner,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../common/FormInput";
import agencyUsersService from "../../services/agencyUsersService";
import { AgencyUser, AgencyUserDTO } from "../../interfaces/AgencyUser";
import { editAgencyUserSchema } from "../../utils/validationSchema";
import { FileStore } from "../../interfaces/FileUpload";
import fileUploadService from "../../services/fileUploadService";

const EditAgencyUserForm = () => {
  const { userId } = useParams();
  const form = useForm({
    resolver: yupResolver(editAgencyUserSchema),
  });
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState<FileStore | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: response, isLoading } = useQuery(
    ["agencyUser", userId],
    () => agencyUsersService.getAgencyUser(Number(userId)),
    { staleTime: 60000 }
  );
  const mutation = useMutation((agencyUser: AgencyUserDTO) => {
    return agencyUsersService.editAgencyUser(agencyUser);
  });

  useEffect(() => {
    if (response) {
      const user: AgencyUser = response?.data.body;
      form.setValue("email", user.userProfile?.email);
      form.setValue("phoneNumber", user.userProfile?.phoneNumber);
      form.setValue("firstName", user.userProfile?.firstName);
      form.setValue("lastName", user.userProfile?.lastName);
      form.setValue("batchId", user.batchId);
    }
  }, [form, response]);

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to edit user",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast({
        title: "User edited successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/dashboard/agency-users");
    }
  }, [mutation.isSuccess, toast, navigate]);

  function onSubmit(values: any) {
    const user: AgencyUserDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      fileName: file?.fileName,
      agencyUserId: response?.data.body.userProfileId,
    };
    if (!user.fileName) delete user.fileName;
    mutation.mutate(user);
  }

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    setPreview(URL.createObjectURL(files[0]));
    setIsUploading(true);
    fileUploadService
      .uploadFile(files[0])
      .then(res => setFile(res.data.body))
      .catch(err => {
        toast({
          title: "Upload Failed",
          description: err?.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        console.error(err.response);
      })
      .finally(() => {
        setIsUploading(false);
        e.target.value = "";
      });
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  if (isLoading)
    return (
      <Flex align="center" justify="center" h="calc(100vh - 3rem - 188px)">
        <Spinner />
      </Flex>
    );

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box maxW="950px" mb={10}>
        <Box mb={10}>
          <Flex align="center" gap={6}>
            <Flex align="center" justify="center" boxSize="100px">
              <Avatar
                size="xl"
                name={`${response?.data.body.userProfile?.firstName} ${response?.data.body.userProfile?.lastName}`}
                src={
                  preview ? preview : response?.data.body.userProfile?.fileName
                }
              />
            </Flex>
            <Box>
              <Flex flexDir="column" gap={3}>
                <Button
                  display="block"
                  onClick={() => inputRef.current?.click()}
                >
                  Change picture
                </Button>
              </Flex>
              {isUploading && (
                <Progress
                  my={2}
                  size="sm"
                  isIndeterminate
                  h="5px"
                  colorScheme="primary"
                />
              )}
            </Box>
          </Flex>
          <Input
            ref={inputRef}
            id="fileInput"
            type="file"
            hidden
            onChange={onFileUpload}
            accept="image/*"
          />
        </Box>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <Flex w="full" align="flex-start" gap={4}>
            <FormInput
              form={form}
              label="Email"
              id="email"
              options={{ required: "Email is required" }}
              type="email"
              isDisabled
            />
          </Flex>
          <FormInput
            form={form}
            label="Phone Number"
            id="phoneNumber"
            options={{ required: "Phone Number is required" }}
            type="tel"
            isDisabled
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="First Name"
            id="firstName"
            options={{ required: "First Name is required" }}
          />
          <FormInput
            form={form}
            label="Last Name"
            id="lastName"
            options={{ required: "Last Name is required" }}
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Badge ID"
            id="batchId"
            options={{ required: "Badge ID is required" }}
            isDisabled
          />
        </Flex>
      </Box>
      <Button
        my={4}
        px="50px"
        isLoading={form.formState.isSubmitting || mutation.isLoading}
        type="submit"
        disabled={
          isUploading || form.formState.isSubmitting || mutation.isLoading
        }
      >
        Submit
      </Button>
    </form>
  );
};

export default EditAgencyUserForm;
