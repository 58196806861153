import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useUploads } from "../../context/useUploads";
import uploadLimit from "../../utils/uploadLimit";
import FilePreview from "./FilePreview";
import Upload from "./Upload";

interface FormUploadProps {
  label: string;
}

const FormUpload = ({ label, ...props }: FormUploadProps) => {
  const { setFiles, uploads, files } = useUploads();
  const toast = useToast();
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach(rejection => {
        toast({
          title: "Upload Failed",
          description: rejection.errors[0].message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      });
      const validated = [...acceptedFiles].filter(file => {
        const limit = uploadLimit(file);
        if (limit?.isExceeded) {
          toast({
            title: "Upload Failed",
            description: limit.type + " size must be less than " + limit.size,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          return false;
        }
        return true;
      });
      setFiles(prev => [...prev, ...validated]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
      "video/*": [],
      "audio/*": [],
    },
    onDrop,
  });

  return (
    <FormControl my={4}>
      <FormLabel fontWeight="semibold" fontSize="sm" color="textSecondary">
        {label}
      </FormLabel>
      <Box
        p={4}
        px={6}
        bg="#F5F5F54D"
        border="2px dashed #D4D4D459"
        mb={6}
        {...getRootProps()}
      >
        {isDragActive ? (
          <Grid minH="74px" placeItems="center">
            <Text fontSize="lg" color="primary.500" fontWeight="bold">
              Drop the files here...
            </Text>
          </Grid>
        ) : (
          <>
            <Button
              fontSize="sm"
              px="50px"
              variant="outline"
              leftIcon={<AddIcon fontSize="12px" />}
            >
              Add Files
            </Button>
            <input {...getInputProps()} />
            <Text fontSize="xs" fontWeight="semibold" my={2}>
              Maximum sizes for files are Images{" "}
              <Box as="span" color="secondary.500">
                (5MB)
              </Box>
              , Videos{" "}
              <Box as="span" color="secondary.500">
                (70MB)
              </Box>
              , Audios{" "}
              <Box as="span" color="secondary.500">
                (70MB)
              </Box>
            </Text>
          </>
        )}
      </Box>
      {uploads?.length > 0 && (
        <Flex flexWrap="wrap" align="center" gap={4}>
          {uploads.map(file => (
            <FilePreview key={file.id} file={file} />
          ))}
        </Flex>
      )}
      {files?.length > 0 && (
        <Flex flexDir="column" gap={4} my={4}>
          {files.map((file, index) => (
            <Upload key={index} file={file} maxW="400px" />
          ))}
        </Flex>
      )}
    </FormControl>
  );
};

export default FormUpload;
