import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useAuth } from "../../context/useAuth";
import { UserDTO } from "../../interfaces/User";
import { registerSchema } from "../../utils/validationSchema";
import Link from "../common/Link";
import ErrorIcon from "../svg/ErrorIcon";

interface FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  gender: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const togglePassword = () => setShowPassword(prev => !prev);
  const toggleConfirmPassword = () => setShowConfirmPassword(prev => !prev);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<FieldValues>({ resolver: yupResolver(registerSchema) });
  const auth = useAuth();

  function onSubmit(values: UserDTO) {
    const data = { ...values, phoneNumber: `+${values.phoneNumber}` };
    auth?.register(data);
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={10} maxW="480px">
      <Box mb={10}>
        <FormControl my={6} isInvalid={!!errors.firstName}>
          <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="firstName">
            First Name
          </FormLabel>
          <InputGroup h="50px">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="firstName"
              placeholder="John"
              {...register("firstName")}
            />
            <InputRightElement h="full">
              <Flex align="center" h="full">
                <FormErrorIcon as={ErrorIcon} fontSize="20px" />
              </Flex>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={6} isInvalid={!!errors.lastName}>
          <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="lastName">
            Last Name
          </FormLabel>
          <InputGroup h="50px">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="lastName"
              placeholder="Doe"
              {...register("lastName")}
            />
            <InputRightElement h="full">
              <Flex align="center" h="full">
                <FormErrorIcon as={ErrorIcon} fontSize="20px" />
              </Flex>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={6} isInvalid={!!errors.email}>
          <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="email">
            Email Address
          </FormLabel>
          <InputGroup h="50px">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="email"
              type="email"
              placeholder="e.g. john-doe@flagit.com"
              {...register("email")}
            />
            <InputRightElement h="full">
              <Flex align="center" h="full">
                <FormErrorIcon as={ErrorIcon} fontSize="20px" />
              </Flex>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={6} isInvalid={!!errors.phoneNumber}>
          <FormLabel
            fontWeight="semibold"
            color="#0A230E"
            htmlFor="phoneNumber"
          >
            Phone Number
          </FormLabel>
          <InputGroup h="50px">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { ref, ...field } }) => (
                <PhoneInput
                  {...field}
                  inputProps={{
                    ref,
                    required: true,
                    autoFocus: true,
                  }}
                  country="ng"
                  countryCodeEditable={false}
                  specialLabel=""
                  inputStyle={{
                    border: "none",
                    height: "46px",
                    width: "100%",
                    padding: 0,
                    color: "inherit",
                  }}
                  containerStyle={{
                    border: "1px solid #D4D4D4",
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "10px",
                    borderRadius: "0.375rem",
                  }}
                  buttonStyle={{ position: "static" }}
                />
              )}
            />

            <InputRightElement h="full">
              <Flex align="center" h="full">
                <FormErrorIcon as={ErrorIcon} fontSize="20px" />
              </Flex>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
        </FormControl>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormControl my={6} isInvalid={!!errors.birthdate}>
            <FormLabel
              fontWeight="semibold"
              color="#0A230E"
              htmlFor="birthdate"
            >
              Date of Birth
            </FormLabel>
            <InputGroup h="50px">
              <Input
                h="50px"
                _invalid={{
                  bg: "#FFCCCC",
                  border: "1px solid #B13D3D",
                  boxShadow: "0 0 0 1px #e53e3e",
                }}
                id="birthdate"
                type="date"
                autoComplete="off"
                {...register("birthdate")}
              />
              <InputRightElement h="full">
                <Flex align="center" h="full">
                  <FormErrorIcon as={ErrorIcon} fontSize="20px" />
                </Flex>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.birthdate?.message}</FormErrorMessage>
          </FormControl>
          <FormControl my={6} isInvalid={!!errors.gender}>
            <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="gender">
              Gender
            </FormLabel>
            <Select
              borderColor="#D4D4D4"
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="gender"
              autoComplete="off"
              {...register("gender")}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
            <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl my={6} isInvalid={!!errors.password}>
          <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="password">
            Password
          </FormLabel>
          <Box w="full" position="relative">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              id="password"
              pr="99px"
              type={showPassword ? "text" : "password"}
              placeholder="Tiger123@Naija"
              autoComplete="off"
              {...register("password")}
            />
            <Box
              as="span"
              position="absolute"
              zIndex="overlay"
              top="50%"
              transform="translateY(-50%)"
              right={2}
              fontWeight="bold"
              fontSize="xs"
              onClick={togglePassword}
              color="secondary.500"
              cursor="pointer"
              _hover={{ textDecor: "underline" }}
            >
              {showPassword ? "Hide" : "Show"}
            </Box>
          </Box>
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={6} isInvalid={!!errors.confirmPassword}>
          <FormLabel
            fontWeight="semibold"
            color="#0A230E"
            htmlFor="confirmPassword"
          >
            Confirm Password
          </FormLabel>
          <Box w="full" position="relative">
            <Input
              h="50px"
              _invalid={{
                bg: "#FFCCCC",
                border: "1px solid #B13D3D",
                boxShadow: "0 0 0 1px #e53e3e",
              }}
              pr="99px"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Tiger123@Naija"
              autoComplete="off"
              {...register("confirmPassword")}
            />
            <Box
              as="span"
              position="absolute"
              zIndex="overlay"
              top="50%"
              transform="translateY(-50%)"
              right={2}
              fontWeight="bold"
              fontSize="xs"
              onClick={toggleConfirmPassword}
              color="secondary.500"
              cursor="pointer"
              _hover={{ textDecor: "underline" }}
            >
              {showConfirmPassword ? "Hide" : "Show"}
            </Box>
          </Box>
          <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my={6} display="flex" alignItems="center">
          <FormLabel
            htmlFor="experiment"
            fontWeight="semibold"
            color="#0A230E"
            mb="0"
          >
            I accept that information shared can be used in line with the
            privacy policy
          </FormLabel>
          <Switch
            isChecked={isChecked}
            onChange={() => setIsChecked(prev => !prev)}
            colorScheme="primary"
            id="experiment"
          />
        </FormControl>
      </Box>
      <Text my={6} textAlign="left" fontSize="md">
        your information is treated with utmost confidentiality will not be
        shared with third parties
      </Text>
      <Button h="50px" w="full" my={6} isLoading={auth?.loading} type="submit">
        Create account
      </Button>
      <Text textAlign="center" color="#0A230E" fontWeight="bold">
        Already have an account?{" "}
        <Link color="secondary.500" to="/login">
          Log In
        </Link>
      </Text>
    </Box>
  );
};

export default RegisterForm;
