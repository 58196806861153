import { LinkProps, Flex, Text } from "@chakra-ui/react";
import Link from "../components/common/Link";

interface SidebarItemProps extends LinkProps {
  to: string;
  icon: React.ReactElement;
  label: string;
  onClick?: () => void;
}

const SidebarItem = ({ to, icon, label, onClick }: SidebarItemProps) => {
  return (
    <Link
      to={to}
      color="text"
      _hover={{ color: "primary.500" }}
      _activeLink={{
        bg: "primary.50",
        color: "primary.500",
        fontWeight: "bold",
      }}
      _focus={{ boxShadow: "none" }}
      _active={{ boxShadow: "0 0 0 3px rgba(61, 205, 181, 0.5)" }}
      borderRadius="8px"
      onClick={onClick}
    >
      <Flex align="center" gap={4} p={3}>
        {icon}
        <Text>{label}</Text>
      </Flex>
    </Link>
  );
};

export default SidebarItem;
