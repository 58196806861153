import { Box } from "@chakra-ui/react";
import About from "../../components/home/About";
import GetTheApp from "../../components/home/GetTheApp";
import HowItWorks from "../../components/home/HowItWorks";
import Landing from "../../components/home/Landing";

export const Home = () => {
  return (
    <Box>
      <Landing py="5rem" pt="2rem" />
      <About id="about" mb="5rem" />
      <HowItWorks id="how-it-works" mb="10rem" />
      <GetTheApp mb="10rem" />
    </Box>
  );
};
