import { IconButton } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

const NextArrow = ({
  className,
  onClick,
  onPage,
  lastPage,
  isFetching,
}: any) => {
  const disabled = className
    .split(" ")
    .find((name: string) => name === "slick-disabled");
  const hasMore = lastPage?.data?.length >= lastPage?.pageSize;

  return (
    <IconButton
      display={!disabled || hasMore ? "inline-flex" : "none"}
      isLoading={isFetching}
      zIndex={2}
      pos="absolute"
      top="50%"
      right="0"
      transform="translateY(-50%)"
      aria-label="next arrow"
      size="md"
      onClick={
        onPage
          ? () => {
              if (disabled) {
                if (hasMore) onPage();
              } else onClick();
            }
          : onClick
      }
      icon={<ChevronRightIcon fontSize="30px" />}
      borderRadius="full"
      color="textSecondary"
      bg="white"
      boxShadow="-8px 0px 16px 0px #FFFFFF52"
      variant="ghost"
    />
  );
};

export default NextArrow;
