import { Box, Flex, FlexProps, IconProps, Text } from "@chakra-ui/react";

interface CasesCardProps extends FlexProps {
  title: string;
  number?: number;
  bg: string;
  color: string;
  Icon: (props: IconProps) => JSX.Element;
  isActive?: boolean;
}

const CasesCard = ({
  title,
  number,
  bg,
  color,
  Icon,
  isActive = false,
  ...props
}: CasesCardProps) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      gap={2}
      p={2}
      pl={4}
      boxShadow="0px 8px 16px #D4D4D43D"
      outline={isActive ? `3px solid ${color}` : "1px solid #D4D4D4"}
      borderRadius="10px"
      w="full"
      minH="96px"
      overflow="hidden"
      pos="relative"
      _before={{
        content: '""',
        pos: "absolute",
        left: 0,
        top: 0,
        h: "100%",
        w: "8px",
        bg: color,
      }}
      {...props}
    >
      <Box fontWeight="bold">
        <Text color={color}>{title.toUpperCase()}</Text>
        <Text fontSize="35px" color="#0A230E">
          {number ?? "..."}
        </Text>
      </Box>
      <Flex
        bg={bg}
        align="center"
        justify="center"
        boxSize="60px"
        borderRadius="full"
      >
        <Icon boxSize="24px" color={color} />
      </Flex>
    </Flex>
  );
};

export default CasesCard;
