import { Box, Button, Flex, Switch, useToast } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Column } from "react-table";
import Heading from "../../../components/common/Heading";
import Link from "../../../components/common/Link";
import Table from "../../../components/common/Table";
import { useAuth } from "../../../context/useAuth";
import getUserRole from "../../../helpers/getUserRole";
import { AgencyUser } from "../../../interfaces/AgencyUser";
import agencyUsersService from "../../../services/agencyUsersService";

export const AgencyUsers = () => {
  const [agencyUsers, setAgencyUsers] = useState<AgencyUser[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = useRef(0);
  const toast = useToast();
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);

  const handleIsAdmin = (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: number
  ) => {
    setLoading(true);
    const data: AgencyUser = {
      agencyId: auth?.user?.agency?.id,
      userProfileId: userId,
    };
    if (e.target.checked) {
      data.isAdmin = true;
      agencyUsersService
        .changeUserAdminStatus(data)
        .then(res => {
          toast({
            title: "User updated successfully",
            description: res.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch(err => {
          toast({
            title: "Failed to update user",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .finally(() => setLoading(false));
    } else {
      data.isAdmin = false;
      agencyUsersService
        .changeUserAdminStatus(data)
        .then(res => {
          toast({
            title: "User updated successfully",
            description: res.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch(err => {
          toast({
            title: "Failed to update user",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .finally(() => setLoading(false));
    }
  };
  const handleIsDisabled = (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: number
  ) => {
    setLoading(true);
    const shortCode = auth?.user?.agency?.shortCode;
    if (!shortCode) return;

    if (e.target.checked) {
      agencyUsersService
        .changeAgencyUserStatus("enable", userId, shortCode)
        .then(res => {
          toast({
            title: "User enabled successfully",
            description: res.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch(err => {
          toast({
            title: "Failed to enable user",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .finally(() => setLoading(false));
    } else {
      agencyUsersService
        .changeAgencyUserStatus("disable", userId, shortCode)
        .then(res => {
          toast({
            title: "User disabled successfully",
            description: res.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch(err => {
          toast({
            title: "Failed to disable user",
            description: err?.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const tableData = React.useMemo(() => agencyUsers, [agencyUsers]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Badge ID",
        accessor: "batchId",
      },
      {
        Header: "First Name",
        accessor: "userProfile.firstName",
      },
      {
        Header: "Last Name",
        accessor: "userProfile.lastName",
      },
      {
        Header: "Email",
        accessor: "userProfile.email",
      },
      {
        Header: "Phone Number",
        accessor: "userProfile.phoneNumber",
      },
      {
        Header: "Admin",
        accessor: "isAdmin",
        Cell: ({ cell }) => {
          return (
            <Switch
              disabled={loading || cell.row.values.userProfile.lockoutEnabled}
              defaultChecked={cell.row.values.isAdmin}
              onChange={e => handleIsAdmin(e, cell.row.values.userProfile.id)}
              size="sm"
              colorScheme="primary"
              id="isAdmin"
            />
          );
        },
      },
      {
        Header: "Enabled",
        accessor: "userProfile",
        Cell: ({ cell }) => {
          return (
            <Switch
              defaultChecked={!cell.row.values.userProfile.lockoutEnabled}
              onChange={e =>
                handleIsDisabled(e, cell.row.values.userProfile.userId)
              }
              disabled={loading}
              size="sm"
              colorScheme="primary"
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        if (!isOverallAdmin)
          agencyUsersService
            .getAgencyUsers(
              Number(auth?.user?.agency?.id),
              pageIndex + 1,
              pageSize
            )
            .then(res => {
              setAgencyUsers(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
            })
            .catch(err => {
              toast({
                title: "Failed to fetch agency users",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              console.error(err.response);
            })
            .finally(() => setLoading(false));
        else
          agencyUsersService
            .getAgencyUsersOverallAdmin(
              Number(auth?.user?.agency?.id),
              pageIndex + 1,
              pageSize
            )
            .then(res => {
              setAgencyUsers(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
            })
            .catch(err => {
              toast({
                title: "Failed to fetch agency users",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              console.error(err.response);
            })
            .finally(() => setLoading(false));
      }
    },
    [toast, auth, isOverallAdmin]
  );
  return (
    <Box>
      <Helmet>
        <title>FlagIt | Agency Users</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>Agency Users</Heading>
        <Link
          to="/dashboard/agency-users/create"
          _hover={{ textDecor: "none" }}
        >
          <Button variant="outline">Create New Agency User</Button>
        </Link>
      </Flex>

      {tableData && (
        <Table
          route="/dashboard/agency-users"
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Agency Users"
        />
      )}
    </Box>
  );
};
