import * as yup from "yup";
import { passwordRegExp, phoneRegExp } from "./regex";

export const registerSchema = yup
  .object({
    firstName: yup
      .string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    birthdate: yup.string().required("Date of Birth is required"),
    gender: yup.string().required("Gender is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        passwordRegExp,
        "Minimum eight characters, at least one letter and one number"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

export const resetPasswordSchema = yup
  .object({
    code: yup
      .string()
      .required("Full Name is required")
      .min(2, "Full Name must be at least 2 characters"),
    newPassword: yup
      .string()
      .required("New Password is required")
      .matches(
        passwordRegExp,
        "Minimum eight characters, at least one letter and one number"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

export const editProfileSchema = yup
  .object({
    firstName: yup
      .string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
    birthdate: yup.string().required("Date of Birth is required"),
    gender: yup.string().required("Middle Name is required"),
    newPassword: yup.string().matches(passwordRegExp, {
      message: "Minimum eight characters, at least one letter and one number",
      excludeEmptyString: true,
    }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

export const createAgencyUserSchema = yup
  .object({
    firstName: yup
      .string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
    batchId: yup.string().required("Badge ID is required"),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone Number is not valid"),
  })
  .required();

export const editAgencyUserSchema = yup
  .object({
    firstName: yup
      .string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
    email: yup.string(),
    phoneNumber: yup.string(),
  })
  .required();
