import { useForm } from "react-hook-form";
import {
  Button,
  Box,
  Flex,
  FormControl,
  Input,
  FormErrorMessage,
  useToast,
  Progress,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import FormInput from "../common/FormInput";
import FormTextarea from "../common/FormTextarea";
import { useEffect, useRef, useState } from "react";
import fileUploadService from "../../services/fileUploadService";
import agenciesService from "../../services/agenciesService";
import { useMutation, useQuery } from "react-query";
import { Agency } from "../../interfaces/Agency";
import { useNavigate, useParams } from "react-router-dom";

const EditAgencyForm = () => {
  const { agencyId } = useParams();
  const form = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { data: response, isLoading } = useQuery(
    ["agency", agencyId],
    () => agenciesService.getAgency(Number(agencyId)),
    { staleTime: 60000 }
  );
  const mutation = useMutation((agency: Agency) => {
    return agenciesService.editAgency({ ...response?.data.body, ...agency });
  });

  useEffect(() => {
    if (response) {
      const agency: Agency = response?.data.body;
      form.setValue("name", `${agency.name}`);
      form.setValue("shortCode", `${agency.shortCode}`);
      form.setValue("description", `${agency.description}`);
      form.setValue("color", `${agency.color}`);
      form.setValue("email", `${agency.email}`);
      form.setValue("phoneNumber", `${agency.phoneNumber}`);
      setPreview(agency.logo);
    }
  }, [form, response]);

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to edit agency",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast({
        title: "Agency has been edited",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/dashboard/agencies");
    }
  }, [mutation.isSuccess, toast, navigate]);

  function onSubmit(values: any) {
    mutation.mutate({
      ...values,
      logo: values.logo.fileName || response?.data.body.logo,
      logoUrl: values.logo.url || response?.data.body.logoUrl,
    });
  }

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    setPreview(URL.createObjectURL(files[0]));
    setIsUploading(true);
    fileUploadService
      .uploadFile(files[0])
      .then(res => form.setValue("logo", res.data.body))
      .catch(err => {
        toast({
          title: "Upload Failed",
          description: err?.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        console.error(err.response);
      })
      .finally(() => {
        setIsUploading(false);
        e.target.value = "";
      });
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  if (isLoading)
    return (
      <Flex align="center" justify="center" h="calc(100vh - 3rem - 188px)">
        <Spinner />
      </Flex>
    );

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box maxW="950px" mb={10}>
        <FormControl isInvalid={form.formState.errors.logo}>
          {preview ? (
            <Flex align="center" gap={6}>
              <Flex align="center" justify="center" boxSize="100px">
                <Avatar
                  size="xl"
                  src={preview}
                  name={response?.data.body.name}
                  objectFit="contain"
                  h="100%"
                  w="100%"
                />
              </Flex>
              <Box>
                <Button
                  display="block"
                  onClick={() => inputRef.current?.click()}
                >
                  Change Logo
                </Button>
                {isUploading && (
                  <Progress
                    my={2}
                    size="sm"
                    isIndeterminate
                    h="5px"
                    colorScheme="primary"
                  />
                )}
              </Box>
            </Flex>
          ) : (
            <Button onClick={() => inputRef.current?.click()}>
              Upload Logo
            </Button>
          )}
          <Input
            ref={inputRef}
            id="fileInput"
            type="file"
            hidden
            onChange={onFileUpload}
            accept="image/*"
          />
          <Input type="hidden" {...form.register("logo")} />
          <FormErrorMessage>
            {form.formState.errors.logo && form.formState.errors.logo.message}
          </FormErrorMessage>
        </FormControl>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Name"
            id="name"
            options={{ required: "Name is required" }}
          />
          <FormInput
            form={form}
            label="Short Code"
            id="shortCode"
            options={{ required: "Short Code is required" }}
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Email"
            id="email"
            type="email"
            options={{ required: "Email is required" }}
            isDisabled
          />
          <FormInput
            form={form}
            label="Phone Number"
            id="phoneNumber"
            type="tel"
            options={{ required: "Phone Number is required" }}
            isDisabled
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormTextarea
            form={form}
            label="Description"
            id="description"
            options={{ required: "Description is required" }}
            minH="150px"
          />
          <FormInput
            w="80px"
            h="50px"
            form={form}
            label="Color"
            type="color"
            id="color"
            options={{ required: "Color is required" }}
          />
        </Flex>
      </Box>
      <Button
        my={4}
        px="50px"
        isLoading={form.formState.isSubmitting || mutation.isLoading}
        type="submit"
        disabled={
          isUploading || form.formState.isSubmitting || mutation.isLoading
        }
      >
        Save Changes
      </Button>
    </form>
  );
};

export default EditAgencyForm;
