import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/useAuth";
import { AgencyUserDTO } from "../../interfaces/AgencyUser";
import { UserProfile } from "../../interfaces/User";
import agencyUsersService from "../../services/agencyUsersService";
import usersService from "../../services/usersService";
import { createAgencyUserSchema } from "../../utils/validationSchema";
import FormInput from "../common/FormInput";
import FormPhoneNumber from "../common/FormPhoneNumber";

const CreateAgencyUserForm = () => {
  const form = useForm({
    resolver: yupResolver(createAgencyUserSchema),
  });
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<UserProfile | null>(null);
  const navigate = useNavigate();
  const mutation = useMutation((agencyUser: AgencyUserDTO) => {
    return agencyUsersService.createAgencyUser(agencyUser);
  });
  const auth = useAuth();
  const email = form.watch("email");

  useEffect(() => {
    if (
      !!email?.trim() &&
      form.formState.dirtyFields.email &&
      !form.formState.errors.email
    ) {
      setIsLoading(true);
      usersService
        .getUserByEmail(email)
        .then(res => setUser(res.data.body))
        .catch(err => {})
        .finally(() => setIsLoading(false));
    }
  }, [form, email]);

  useEffect(() => {
    form.setValue("firstName", user?.firstName);
    form.setValue("lastName", user?.lastName);
    form.setValue("phoneNumber", user?.phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to Create User",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast({
        title: "User created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/dashboard/agency-users");
    }
  }, [mutation.isSuccess, toast, navigate]);

  function onSubmit(values: any) {
    mutation.mutate({
      ...values,
      agencyId: Number(auth?.user?.agency?.id),
      phoneNumber: `+${values.phoneNumber}`,
    });
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box maxW="950px" mb={10}>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <Flex w="full" align="flex-start" gap={4}>
            <FormInput
              form={form}
              label="Email"
              id="email"
              options={{ required: "Email is required" }}
              type="email"
              isLoading={isLoading}
            />
          </Flex>
          <FormPhoneNumber form={form} label="Phone Number" id="phoneNumber" />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="First Name"
            id="firstName"
            options={{ required: "First Name is required" }}
          />
          <FormInput
            form={form}
            label="Last Name"
            id="lastName"
            options={{ required: "Last Name is required" }}
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Badge ID"
            id="batchId"
            options={{ required: "Badge ID is required" }}
          />
        </Flex>
      </Box>
      <Button
        my={4}
        px="50px"
        isLoading={form.formState.isSubmitting || mutation.isLoading}
        type="submit"
        disabled={form.formState.isSubmitting || mutation.isLoading}
      >
        Submit
      </Button>
    </form>
  );
};

export default CreateAgencyUserForm;
