import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import { FileStore } from "../../interfaces/FileUpload";
import AttachmentsView from "./AttachmentsView";

interface AttachmentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  attachments: FileStore[];
}
const AttachmentsModal = ({
  isOpen,
  onClose,
  attachments,
}: AttachmentsModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="textSecondary">Attachments</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            justify="space-evenly"
            align="center"
            gap={6}
            flexWrap="wrap"
            my={6}
            mb={10}
          >
            {attachments &&
              attachments.map(attachment => (
                <AttachmentsView
                  key={attachment.id}
                  file={attachment}
                  w={{ base: "full", sm: "calc(100% / 3 - 1rem)" }}
                />
              ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AttachmentsModal;
