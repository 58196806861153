import React from "react";
import { Box, Drawer, DrawerContent, Flex, FlexProps } from "@chakra-ui/react";
import SidebarContent from "./SidebarContent";

interface SidebarProps extends FlexProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

const Sidebar = ({ isOpen, onClose, children, ...props }: SidebarProps) => {
  return (
    <Flex h="100vh" {...props}>
      <SidebarContent
        onClose={onClose}
        flex="1"
        display={{ base: "none", md: "block" }}
        overflowY="auto"
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
        isFullHeight
      >
        <DrawerContent h="full">
          <SidebarContent onClose={onClose} h="full" overflowY="auto" />
        </DrawerContent>
      </Drawer>
      <Box flex={{ base: "4", xl: "5" }} overflowY="auto">
        {children}
      </Box>
    </Flex>
  );
};

export default Sidebar;
