import {
  Box,
  BoxProps,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps
} from "@chakra-ui/react";
import SearchIcon from "../svg/Search";

type Props = {
  boxProps?: BoxProps;
} & InputProps;

const Search = ({ boxProps, ...props }: Props) => {
  return (
    <Box as="form" {...boxProps}>
      <InputGroup bg="white" size="md">
        <Input
          _placeholder={{ color: "text", fontsize: "xsm" }}
          placeholder="Search"
          {...props}
        />
        <InputLeftElement width="3rem">
          <IconButton
            variant="ghost"
            size="sm"
            aria-label="Search database"
            _hover={{}}
            icon={<SearchIcon />}
          />
        </InputLeftElement>
      </InputGroup>
    </Box>
  );
};

export default Search;
