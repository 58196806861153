import { useRef, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Select,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "react-query";
import libraryService from "../../services/libraryService";
import Slider, { Settings } from "react-slick";
import LibraryCard from "./LibraryCard";
import { LibraryItem } from "../../interfaces/Library";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { AiOutlineReload } from "react-icons/ai";
import { useAuth } from "../../context/useAuth";
import Heading from "../common/Heading";
import getUserRole from "../../helpers/getUserRole";

const ImageSlider = () => {
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const lastPage = useRef(null);
  const libraryRequest = !!isOverallAdmin
    ? filter === "approved"
      ? libraryService.getApprovedImageResources
      : filter === "disapproved"
      ? libraryService.getDisapprovedImageResources
      : libraryService.getImageResources
    : libraryService.getApprovedImageResources;
  const {
    data,
    error,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(["imageResources", filter], libraryRequest, {
    getNextPageParam: prevPage => {
      lastPage.current = prevPage.data.body;
      return page + 1;
    },
    staleTime: 60000,
  });

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: (
      <NextArrow
        lastPage={lastPage.current}
        isFetching={isFetchingNextPage}
        onPage={() => {
          fetchNextPage();
          setPage(prev => prev + 1);
        }}
      />
    ),
    prevArrow: <PrevArrow />,
  };

  return (
    <Box mb={8} minH="252px">
      <Flex gap={4} align="center" justify="space-between" mb={4}>
        <Text color="textSecondary" fontWeight="semibold">
          Images
        </Text>
        {isOverallAdmin && (
          <Select
            fontSize="sm"
            w="max-content"
            value={filter}
            onChange={e => setFilter(e.target.value)}
          >
            <option value="">Status</option>
            <option value="approved">Approved</option>
            <option value="disapproved">Disapproved</option>
          </Select>
        )}
      </Flex>

      {isFetching && !isFetchingNextPage ? (
        <Flex justify="space-between">
          <Skeleton
            w="320px"
            h="210px"
            display={{ base: "none", lg: "block" }}
          />

          <Skeleton
            w="320px"
            h="210px"
            display={{ base: "none", sm: "block" }}
          />

          <Skeleton
            w={{ base: "full", sm: "320px" }}
            h="210px"
            mr={{ base: 0, sm: "55px" }}
          />
        </Flex>
      ) : error ? (
        <Flex h="210px" justify="center" align="center" gap={2}>
          <Text color="red.500" fontWeight="semibold">
            Failed to load images, please retry.
          </Text>
          <IconButton
            onClick={() =>
              refetch({ refetchPage: (page, index) => index === 1 })
            }
            aria-label="retry"
            size="md"
            icon={<AiOutlineReload fontSize="20px" />}
            borderRadius="full"
            color="textSecondary"
            bg="white"
            boxShadow="-8px 0px 16px 0px #FFFFFF52"
            variant="ghost"
          />
        </Flex>
      ) : !isFetching && data?.pages[0].data.body.data.length === 0 ? (
        <Flex h="210px" justify="center" align="center" gap={2}>
          <Heading size="md">No resource available</Heading>
        </Flex>
      ) : (
        <Slider {...settings}>
          {data?.pages.map(page =>
            page.data.body.data.map((item: LibraryItem) => (
              <LibraryCard key={item.id} item={item} label={item.title} />
            ))
          )}
        </Slider>
      )}
    </Box>
  );
};

export default ImageSlider;
