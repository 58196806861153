import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import EditAgencyUserForm from "../../../components/agencyUsers/EditAgencyUserForm";
import Heading from "../../../components/common/Heading";
import Link from "../../../components/common/Link";

export const EditAgencyUser = () => {
  return (
    <Box>
      <Helmet>
        <title>FlagIt | Agency User</title>
      </Helmet>
      <Flex align="center" gap={4} my={8}>
        <Heading>Edit Agency User</Heading>
        <Link to="/dashboard/agency-users" _hover={{}}>
          <Button variant="ghost" size="sm" leftIcon={<ArrowBackIcon />}>
            Go back
          </Button>
        </Link>
      </Flex>
      <EditAgencyUserForm />
    </Box>
  );
};
