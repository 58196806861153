import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import Heading from "../../../components/common/Heading";
import Link from "../../../components/common/Link";
import AddLibraryItemModal from "../../../components/library/AddLibraryItemModal";
import DocumentsSlider from "../../../components/library/DocumentsSlider";
import ImageSlider from "../../../components/library/ImageSlider";
import VideoSlider from "../../../components/library/VideoSlider";
import Emergency from "../../../components/svg/Emergency";
import { useAuth } from "../../../context/useAuth";
import getUserRole from "../../../helpers/getUserRole";
import libraryService from "../../../services/libraryService";

export const Library = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAuth();
  const { isOverallAdmin, isAgencyAdmin, isDefaultUser } = getUserRole(
    auth?.user?.roles
  );
  const { data: response } = useQuery(
    "libraryTypes",
    libraryService.getLibraryTypes,
    { staleTime: 60000 }
  );

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Library</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={6}
      >
        <Heading>Library</Heading>
        {(!!isAgencyAdmin || !!isOverallAdmin) && (
          <Button onClick={onOpen} leftIcon={<AddIcon color="white" />}>
            Add Library Item
          </Button>
        )}
        {isDefaultUser && (
          <Link
            to="/dashboard/my-cases/report"
            _hover={{ textDecoration: "none" }}
          >
            <Button variant="outline" leftIcon={<Emergency />}>
              Submit a Report
            </Button>
          </Link>
        )}
      </Flex>
      <ImageSlider />
      <VideoSlider />
      <DocumentsSlider />
      <AddLibraryItemModal
        isOpen={isOpen}
        onClose={onClose}
        libraryTypes={response?.data.body}
      />
    </Box>
  );
};
