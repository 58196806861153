import {
  FormControl,
  InputProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorIcon,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Location } from "../../interfaces/Report";
import ErrorIcon from "../svg/ErrorIcon";

interface FormLocationProps extends InputProps {
  form: any;
  label: string;
  id: string;
  options?: any;
}

const FormLocation = ({
  form,
  label,
  id,
  options,
  ...props
}: FormLocationProps) => {
  useEffect(() => {
    const opts = {
      fields: ["address_components", "geometry", "formatted_address"],
      strictBounds: false,
    };
    const input = document.getElementById("location-input") as HTMLInputElement;
    if (!input) return;
    const autocomplete = new google.maps.places.Autocomplete(input, opts);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      if (!place.address_components) return;

      let city = "";
      let state = "";
      for (const component of place.address_components) {
        const componentType = component?.types[0];
        switch (componentType) {
          case "locality": {
            city = component.long_name;
            break;
          }

          case "administrative_area_level_1": {
            state = component.long_name;
            break;
          }
        }
      }
      const location: Location = {
        latitude: `${place.geometry.location.lat()}`,
        longitude: `${place.geometry.location.lng()}`,
        address: `${place.formatted_address}`,
        city,
        state,
        isReportLocation: true,
      };

      form.setValue(id, location);
      form.clearErrors(id);
    });

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [form, id]);

  return (
    <FormControl my={4} isInvalid={form.formState.errors[id]}>
      <FormLabel
        fontWeight="semibold"
        fontSize="sm"
        color="textSecondary"
        htmlFor={id}
      >
        {label}
      </FormLabel>

      <InputGroup>
        <Input
          _invalid={{
            bg: "#FFCCCC",
            border: "1px solid #B13D3D",
            boxShadow: "0 0 0 1px #e53e3e",
          }}
          id="location-input"
          {...props}
        />
        <Input hidden id={id} {...form.register(id, options)} />
        <InputRightElement>
          <FormErrorIcon as={ErrorIcon} fontSize="20px" />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {form.formState.errors[id] && form.formState.errors[id].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormLocation;
