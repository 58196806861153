import axiosInstance from "../helpers/axiosInstance";
import {
  Report,
  ReportPayload,
  ReportStatus,
  Stats
} from "../interfaces/Report";
import { ReportAction, ReportActionRes } from "../interfaces/ReportAction";
import { Response, ResponseWithPagination } from "../interfaces/Response";

const getUserReports = (page: number, size: number) => {
  const url = `/reports/user?page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getUserReportsByStatus = (status: string, page: number, size: number) => {
  const url = `/reports/user/filter?status=${status}&page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getAssignedReports = (page: number, size: number) => {
  const url = `/reports/agent?page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getAssignedReportsByStatus = (
  status: string,
  page: number,
  size: number
) => {
  const url = `/reports/agent/filter?status=${status}&page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getAgencyReports = (payload: ReportPayload) => {
  const { page, pageSize, order, orderBy, filter, query, shortCode } = payload;
  let url = `/reports/admin/dashboard-reports/${shortCode}?page=${page}&pageSize=${pageSize}&order=${order}&orderBy=${orderBy}`;
  if (filter) url += `&filter=${filter}`;
  if (query) url += `&query=${query}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getAgencyReportsByStatus = (
  status: string,
  agency: string,
  page: number,
  size: number
) => {
  const url = `/reports/agency/${agency}/filter?status=${status}&page=${page}&pageSize=${size}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getOverallReports = (payload: ReportPayload) => {
  const { page, pageSize, order, orderBy, filter, query } = payload;
  let url = `/reports/overAllAdmin/dashboard-reports?page=${page}&pageSize=${pageSize}&order=${order}&orderBy=${orderBy}`;
  if (filter) url += `&filter=${filter}`;
  if (query) url += `&query=${query}`;
  return axiosInstance.get<ResponseWithPagination<Report[]>>(url);
};

const getOverallAdminReport = (reportId: string) => {
  const url = `/reports/overAllAdmin/${reportId}`;
  return axiosInstance.get<Response<Report>>(url);
};

const createReport = (data: Report) => {
  const url = `/reports`;
  return axiosInstance.post(url, data);
};

const getReport = (reportId: string) => {
  const url = `/reports/${reportId}`;
  return axiosInstance.get<Response<Report>>(url);
};

const getUserReport = (reportId: string) => {
  const url = `/reports/user/${reportId}`;
  return axiosInstance.get<Response<Report>>(url);
};

const getUserReportStatistics = () => {
  const url = `/reports/user/statistics`;
  return axiosInstance.get<Response<Stats>>(url);
};

const getAgentReportStatistics = () => {
  const url = `/reports/agent/statistics`;
  return axiosInstance.get<Response<Stats>>(url);
};

const getAgencyReportStatistics = (agency: string) => {
  const url = `/reports/agency/${agency}/statistics`;
  return axiosInstance.get<Response<Stats>>(url);
};

const getOverallReportStatistics = () => {
  const url = `/reports/overall/statistics`;
  return axiosInstance.get<Response<Stats>>(url);
};

const getReportStatuses = () => {
  const url = `/reportsStatus`;
  return axiosInstance.get<ResponseWithPagination<ReportStatus[]>>(url);
};

const getActionLog = (reportId: number) => {
  const url = `/ReportActionLog?reportId=${reportId}`;
  return axiosInstance.get<Response<ReportAction[]>>(url);
};

const takeActionOnReport = (data: ReportAction) => {
  const url = `/ReportActionLog`;
  return axiosInstance.post(url, data);
};

const addMoreInformation = (data: ReportAction) => {
  const url = `/additionalInformation`;
  return axiosInstance.post(url, data);
};

const getMoreInformation = (reportId: number) => {
  const url = `/additionalInformation/${reportId}`;
  return axiosInstance.get<Response<ReportActionRes[]>>(url);
};

const exportUserReports = (page: number, size: number, filter: string) => {
  const url = `/reports/user/export?page=${page}&pageSize=${size}&filter=${filter}`;
  return axiosInstance.get(url, { responseType: "blob" });
};

const exportAgentReports = (page: number, size: number, filter: string) => {
  const url = `/reports/agent/export?page=${page}&pageSize=${size}&filter=${filter}`;
  return axiosInstance.get(url, { responseType: "blob" });
};

const exportAgencyReports = (
  agency: string,
  page: number,
  size: number,
  filter: string
) => {
  const url = `/reports/agency/${agency}/export?page=${page}&pageSize=${size}&filter=${filter}`;
  return axiosInstance.get(url, { responseType: "blob" });
};

const exportOverallReports = (
  startDate: string,
  endDate: string,
  page: number,
  size: number
) => {
  const url = `/reports/overallAdmin/export?startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${size}`;
  return axiosInstance.get(url, { responseType: "blob" });
};

const reportsService = {
  getUserReports,
  createReport,
  getReport,
  getUserReport,
  takeActionOnReport,
  getReportStatuses,
  getActionLog,
  getAgencyReports,
  getMoreInformation,
  addMoreInformation,
  getAssignedReports,
  exportUserReports,
  exportAgentReports,
  exportAgencyReports,
  getUserReportsByStatus,
  getAgencyReportsByStatus,
  getAssignedReportsByStatus,
  getUserReportStatistics,
  getAgentReportStatistics,
  getAgencyReportStatistics,
  getOverallReportStatistics,
  getOverallReports,
  exportOverallReports,
  getOverallAdminReport,
};

export default reportsService;
