import axiosInstance from "../helpers/axiosInstance";
import { EditUserDTO } from "../interfaces/User";

const getUserInfo = () => {
  const url = "/usersProfile";
  return axiosInstance.get(url);
};

const getAllUsers = (page: number, size: number) => {
  const url = `/usersProfile/users?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url);
};

const exportUsers = (page: number, size: number) => {
  const url = `/usersProfile/export?page=${page}&pageSize=${size}`;
  return axiosInstance.get(url, { responseType: "blob" });
};

const getUserByEmail = (email: string) => {
  const url = `/usersProfile/${email}`;
  return axiosInstance.get(url);
};

const editUser = (body: EditUserDTO) => {
  const url = `/usersProfile`;
  return axiosInstance.put(url, body);
};

const deleteProfileImage = (body: EditUserDTO) => {
  const url = `/usersProfile`;
  return axiosInstance.put(url, body);
};

const usersService = {
  getUserInfo,
  editUser,
  getUserByEmail,
  deleteProfileImage,
  getAllUsers,
  exportUsers,
};

export default usersService;
