import { Icon, IconProps } from "@chakra-ui/react";

const ClosedCases = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" stroke="currentcolor" fill="none" {...props}>
      <path
        d="M17.7712 9.11859V9.85155C17.7703 11.5695 17.214 13.2412 16.1853 14.6172C15.1566 15.9932 13.7107 16.9998 12.0632 17.487C10.4157 17.9741 8.6549 17.9156 7.04336 17.3202C5.43182 16.7248 4.05591 15.6245 3.12084 14.1832C2.18577 12.742 1.74163 11.0371 1.85467 9.32279C1.96771 7.60851 2.63187 5.9767 3.74809 4.67072C4.86432 3.36474 6.3728 2.45457 8.04856 2.07595C9.72433 1.69733 11.4776 1.87055 13.0469 2.56978"
        stroke="#2ED2E9"
        strokeWidth="1.59338"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7711 3.47803L9.80414 11.4529L7.41406 9.06284"
        stroke="#2ED2E9"
        strokeWidth="1.59338"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ClosedCases;
