import { AspectRatio, Box, Flex, IconButton, Image } from "@chakra-ui/react";
import { FileStore } from "../../interfaces/FileUpload";
import { useUploads } from "../../context/useUploads";

import { AiOutlineCloseCircle } from "react-icons/ai";
interface FilePreviewProps {
  file: FileStore;
}

const FilePreview = ({ file }: FilePreviewProps) => {
  const { setUploads } = useUploads();
  return (
    <Box minW="89px" minH="89px">
      <Flex justify="end" py={1}>
        <IconButton
          variant="link"
          borderRadius="full"
          aria-label="Remove file"
          color="#FF0000"
          size="sm"
          icon={<AiOutlineCloseCircle fontSize="20px" />}
          onClick={() => setUploads(prev => prev.filter(f => f.id !== file.id))}
        />
      </Flex>
      {file?.fileType === "audio" && (
        <audio controls>
          <source
            src={file?.blob || file.url || file.fileUrl}
            type={file.mimeType}
          />
          Your browser does not support the audio element.
        </audio>
      )}
      {file?.fileType === "image" && (
        <Image
          src={file?.blob || file.url || file.fileUrl}
          boxSize="89px"
          objectFit="cover"
          borderRadius="5px"
          alt="media file"
        />
      )}
      {file?.fileType === "video" && (
        <AspectRatio w="200px" ratio={16 / 9}>
          <video
            controls
            style={{ objectFit: "contain", backgroundColor: "black" }}
          >
            <source
              src={file?.blob || file.url || file.fileUrl}
              type={file.mimeType}
            />
            Your browser does not support the video tag.
          </video>
        </AspectRatio>
      )}
    </Box>
  );
};

export default FilePreview;
