import { useEffect, useState } from "react";
import {
  CloseButton,
  Flex,
  Box,
  BoxProps,
  Text,
  Image,
} from "@chakra-ui/react";
import SidebarItem from "./SidebarItem";
import {
  userNavigation,
  agencyAdminNavigation,
  Navigation,
  overallAdminNavigation,
  agencyUserNavigation,
} from "../constants/navigation";
import logo from "../assets/images/flagit-logo.png";
import Link from "../components/common/Link";
import { useAuth } from "../context/useAuth";
import getUserRole from "../helpers/getUserRole";

interface SidebarProps extends BoxProps {
  onClose?: () => void;
}

const SidebarContent = ({ onClose, ...props }: SidebarProps) => {
  const [navigation, setNavigation] = useState<Navigation[]>([]);
  const auth = useAuth();

  useEffect(() => {
    const { isOverallAdmin, isAgencyAdmin, isAgencyUser } = getUserRole(
      auth?.user?.roles
    );

    if (!!isOverallAdmin) setNavigation(overallAdminNavigation);
    else if (!!isAgencyAdmin) setNavigation(agencyAdminNavigation);
    else if (!!isAgencyUser) setNavigation(agencyUserNavigation);
    else setNavigation(userNavigation);
  }, [auth]);

  return (
    <Box p={{ base: 2, sm: 4 }} boxShadow="4px 0px 16px #D4D4D45C" {...props}>
      <Flex justify="space-between">
        <Link to="/">
          <Image src={logo} w={{ base: "100px", sm: "122px" }} />
        </Link>
        <CloseButton
          fontSize="16px"
          display={{ base: "flex", md: "none" }}
          onClick={onClose}
        />
      </Flex>
      <Text fontWeight="semibold" my={{ base: 4, sm: 6 }} mx={3}>
        MAIN MENU
      </Text>
      <Flex flexDir="column" gap={{ base: 2, sm: 3 }} w="100%">
        {navigation.map((nav, index) => (
          <SidebarItem
            key={index}
            to={nav.route}
            label={nav.label}
            icon={<nav.icon boxSize="24px" />}
            onClick={onClose}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default SidebarContent;
