import { Box, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";

const DashboardLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box h="full" overflow="hidden">
      <SideBar isOpen={isOpen} onClose={onClose}>
        <Header onOpen={onOpen} />
        <Box p={{ base: 4, lg: 6 }} overflowY="auto" h="calc(100% - 88px)">
          <Outlet />
        </Box>
      </SideBar>
    </Box>
  );
};

export default DashboardLayout;
