import { Box } from "@chakra-ui/react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const HomeLayout = () => {
  return (
    <Box>
      <NavBar />
      <Outlet />
      <Footer />
    </Box>
  );
};

export default HomeLayout;
