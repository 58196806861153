import { Icon, IconProps } from "@chakra-ui/react";

const SuccessStories = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" fill="currentcolor" {...props}>
      <path d="M15.8099 20.18C15.5499 20.18 15.2799 20.17 14.9899 20.14C14.4699 20.1 13.88 20 13.27 19.85L11.59 19.45C6.97995 18.36 5.46995 15.92 6.54995 11.32L7.52995 7.13001C7.74995 6.18001 8.00995 5.41001 8.32995 4.77002C10.0499 1.22002 13.3399 1.54001 15.6799 2.09001L17.3499 2.48001C19.6899 3.03001 21.1699 3.90001 21.9999 5.23002C22.8199 6.56002 22.95 8.27002 22.4 10.61L21.4199 14.79C20.5599 18.45 18.7699 20.18 15.8099 20.18ZM13.1199 3.25001C11.4499 3.25001 10.3899 3.94002 9.67995 5.42002C9.41995 5.96002 9.18995 6.63001 8.98995 7.47001L8.00995 11.66C7.11995 15.44 8.14995 17.09 11.93 17.99L13.6099 18.39C14.1499 18.52 14.6599 18.6 15.1199 18.64C17.8399 18.91 19.19 17.72 19.95 14.45L20.9299 10.27C21.3799 8.34002 21.3199 6.99001 20.7199 6.02001C20.1199 5.05001 18.9399 4.39002 16.9999 3.94002L15.3299 3.55001C14.4999 3.35001 13.7599 3.25001 13.1199 3.25001Z" />
      <path d="M8.33005 22.2502C5.76005 22.2502 4.12005 20.7102 3.07005 17.4602L1.79005 13.5102C0.370052 9.11017 1.64005 6.63017 6.02005 5.21017L7.60005 4.70017C8.12005 4.54017 8.51005 4.43017 8.86005 4.37017C9.15005 4.31017 9.43005 4.42017 9.60005 4.65017C9.77005 4.88017 9.80005 5.18017 9.68005 5.44017C9.42005 5.97017 9.19005 6.64017 9.00005 7.48017L8.02005 11.6702C7.13005 15.4502 8.16005 17.1002 11.9401 18.0002L13.6201 18.4002C14.1601 18.5302 14.6701 18.6102 15.1301 18.6502C15.4501 18.6802 15.7101 18.9002 15.8001 19.2102C15.8801 19.5202 15.7601 19.8402 15.5001 20.0202C14.8401 20.4702 14.0101 20.8502 12.9601 21.1902L11.3801 21.7102C10.2301 22.0702 9.23005 22.2502 8.33005 22.2502ZM7.78005 6.22017L6.49005 6.64017C2.92005 7.79017 2.07005 9.47017 3.22005 13.0502L4.50005 17.0002C5.66005 20.5702 7.34005 21.4302 10.9101 20.2802L12.4901 19.7602C12.5501 19.7402 12.6001 19.7202 12.6601 19.7002L11.6001 19.4502C6.99005 18.3602 5.48005 15.9202 6.56005 11.3202L7.54005 7.13017C7.61005 6.81017 7.69005 6.50017 7.78005 6.22017Z" />
      <path d="M17.49 10.5098C17.43 10.5098 17.37 10.4998 17.3 10.4898L12.45 9.25978C12.05 9.15978 11.81 8.74978 11.91 8.34978C12.01 7.94978 12.42 7.70978 12.82 7.80978L17.67 9.03978C18.07 9.13978 18.31 9.54978 18.21 9.94978C18.13 10.2798 17.82 10.5098 17.49 10.5098Z" />
      <path d="M14.56 13.8899C14.5 13.8899 14.44 13.8799 14.37 13.8699L11.46 13.1299C11.06 13.0299 10.82 12.6199 10.92 12.2199C11.02 11.8199 11.43 11.5799 11.83 11.6799L14.74 12.4199C15.14 12.5199 15.38 12.9299 15.28 13.3299C15.2 13.6699 14.9 13.8899 14.56 13.8899Z" />
    </Icon>
  );
};

export default SuccessStories;
