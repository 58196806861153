import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  ContainerProps,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import aboutImage1 from "../../assets/images/about-image-1.png";
import aboutImage2 from "../../assets/images/about-image-2.png";
import aboutImage from "../../assets/images/about-image.png";
import Container from "../common/Container";
import Heading from "../common/Heading";
import Link from "../common/Link";

const About = (props: ContainerProps) => {
  return (
    <Container {...props}>
      <Box mb={{ base: "5rem", md: "8rem" }} color="textSecondary">
        <Heading mb={4} fontSize="45px" color="primary.500">
          About FlagIt
        </Heading>
        <Text>
          FlagIt is a mobile and web-based App that supports institutions and
          individuals in addressing issues of corruption, accountability, and
          social injustice. It empowers Nigerians to report any incident of
          corruption, electoral malpractice, police brutality, and official
          highhandedness to the relevant agencies for redress. It also leverages
          multi-sectoral collaboration to address issues of domestic violence,
          sexual abuse, harassment, trafficking in persons, and sextortion,
          amongst others. The driving principle for this intervention is the
          total well-being of women, men, girls, and boys.
          <Box as="span" display="block" my={4} />
          Since the launch of the FlagIt app in 2019, the app has made
          tremendous strides, enabling key governmental agencies and individuals
          to tackle and address issues of abuse, corruption, and malpractices
          within their jurisdiction. Our widely reported milestone was the
          onboarding of the Federal Road Safety Corps (FRSC), which enabled
          users to report cases to the FRSC and get them resolved swiftly. The
          engagement with the FRSC earned AFF, the pillar of support award in
          2022 for its work on anti-corruption using the FlagIt app. In 2020,
          based on reports on the FlagIt app, AFF was able to support survivors
          of sextortion and cyberbullying, bringing down an established ring of
          cyberbullies who specialized in extorting and trading explicit
          pictures of female undergraduates. The intervention of AFF and ABUAD
          using the App led to the successful apprehension and prosecution of
          the perpetrator at the court. For more details on the app, check out
          the Foundation’s page:{" "}
          <Link
            color="primary.500"
            fontWeight="semibold"
            to="https://www.akinfadeyifoundation.org"
            isExternal
            isRouter={false}
          >
            www.akinfadeyifoundation.org
          </Link>
        </Text>
        <Text my={4}>Partner agencies and institutions include:</Text>
        <Grid
          as="ul"
          listStyleType="none"
          my={4}
          gap={4}
          templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }}
        >
          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>Federal Road Safety Corps (FRSC) </Text>
          </GridItem>

          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>National Orientation Agency (NOA) </Text>
          </GridItem>

          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>Afe Babalola University </Text>
          </GridItem>

          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>Nigeria Police Force </Text>
          </GridItem>
          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>NAPTIP </Text>
          </GridItem>

          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>ICPC </Text>
          </GridItem>

          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>UNDP </Text>
          </GridItem>

          <GridItem as={Flex} gap={2} align="center">
            <CheckCircleIcon color="primary.500" />
            <Text>UNODC </Text>
          </GridItem>
        </Grid>
      </Box>
      <Flex direction={{ base: "column", md: "row" }} align="center" gap="3rem">
        <Box display={{ base: "none", sm: "block" }} flex={1}>
          <Flex align="center" gap="2rem">
            <Box>
              <Image
                src={aboutImage}
                h="425px"
                objectFit="cover"
                borderRadius="10px"
              />
            </Box>
            <Box>
              <Image
                src={aboutImage1}
                objectFit="cover"
                borderRadius="10px"
                mb="2rem"
              />
              <Image src={aboutImage2} objectFit="cover" borderRadius="10px" />
            </Box>
          </Flex>
        </Box>
        <Box flex={1}>
          <Heading size="md" mb={6}>
            Akin Fadeyi Foundation
          </Heading>
          <Text lineHeight="28px" fontSize="md" color="textSecondary">
            The Akin Fadeyi Foundation (AFF) is a communication for change
            centre focused on human rights and sustainable political,
            socio-economic, and environmental transformation. Since 2016, AFF
            has been a pacesetter in designing and implementing innovative
            programs and products that promote sustainable development.
            <br />
            <br />
            The Akin Fadeyi Foundation is the initiator of the ‘Corruption Not
            in My Country’ media advocacy campaign and the FlagIt App, a mobile
            technology tool developed with the support of the MacArthur
            Foundation.
            <br />
            <br />
            As an NGO, one of our focal mandates is good governance through the
            entrenchment of accountability and transparency tools that influence
            policy reforms and support behavioral change. We aim to help
            Nigerians identify corruption and influence their behaviours against
            extortion and bribe-giving while promoting whistleblowing and
            reportage. We also believe in a world free from all forms of
            gender-based violence and police brutality. Herein, we leverage the
            collective strengths of like-minded institutions to drive change and
            champion policy reforms. For more information about AFF and our
            partners, visit{" "}
            <Link
              color="primary.500"
              fontWeight="semibold"
              to="https://www.akinfadeyifoundation.org"
              isExternal
              isRouter={false}
            >
              www.akinfadeyifoundation.org
            </Link>
          </Text>
        </Box>
      </Flex>
    </Container>
  );
};

export default About;
