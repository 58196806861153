import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  ModalHeader,
  ModalFooter,
  Button,
  useToast,
  AspectRatio,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { queryClient } from "../..";
import getUserRole from "../../helpers/getUserRole";
import { useAuth } from "../../context/useAuth";
import { LibraryItem } from "../../interfaces/Library";
import libraryService from "../../services/libraryService";
import DeleteConfirmationModal from "../agencies/DeleteConfirmationModal";
import FormTextarea from "../common/FormTextarea";
import Tag from "../common/Tag";
import { getYoutubeURL } from "../../utils/getYoutubeURL";

interface ResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
  item?: LibraryItem;
}

const ResourceModal = ({ isOpen, onClose, item }: ResourceModalProps) => {
  const form = useForm();
  const auth = useAuth();
  const { isOverallAdmin } = getUserRole(auth?.user?.roles);
  const toast = useToast();
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const approveMutation = useMutation((resourceId: number) => {
    return libraryService.approveLibraryItem(resourceId);
  });
  const disapproveMutation = useMutation(
    (data: { id: number; note: string }) => {
      return libraryService.disapproveLibraryItem(data);
    }
  );
  const deleteMutation = useMutation((resourceId: number) => {
    return libraryService.deleteLibraryItem(resourceId);
  });

  useEffect(() => {
    if (approveMutation.isError) {
      toast({
        title: "Failed to approve library item",
        description: (approveMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [approveMutation.isError, toast, approveMutation.error]);

  useEffect(() => {
    if (disapproveMutation.isError) {
      toast({
        title: "Failed to disapprove library item",
        description: (disapproveMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [disapproveMutation.isError, toast, disapproveMutation.error]);

  useEffect(() => {
    if (approveMutation.isSuccess) {
      const queryKey =
        item?.libraryItemType === "Image"
          ? "imageResources"
          : item?.libraryItemType === "Video"
          ? "videoResources"
          : "documentsResources";
      queryClient.invalidateQueries(queryKey);
      onClose();
      toast({
        title: "Library item has been approved",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveMutation.isSuccess]);

  useEffect(() => {
    if (disapproveMutation.isSuccess) {
      const queryKey =
        item?.libraryItemType === "Image"
          ? "imageResources"
          : item?.libraryItemType === "Video"
          ? "videoResources"
          : "documentsResources";
      queryClient.invalidateQueries(queryKey);
      onClose();
      toast({
        title: "Library item has been disapproved",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disapproveMutation.isSuccess]);

  useEffect(() => {
    if (deleteMutation.isError) {
      toast({
        title: "Failed to delete library item",
        description: (deleteMutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [deleteMutation.isError, toast, deleteMutation.error]);

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      const queryKey =
        item?.libraryItemType === "Image"
          ? "imageResources"
          : item?.libraryItemType === "Video"
          ? "videoResources"
          : "documentsResources";
      queryClient.invalidateQueries(queryKey);
      onClose();
      toast({
        title: "Library item has been deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMutation.isSuccess]);

  function onSubmit(values: any) {
    if (item?.isApproved)
      disapproveMutation.mutate({
        id: Number(item?.id),
        note: values.note,
      });
    else approveMutation.mutate(Number(item?.id));
  }

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="textSecondary">Library Resource</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="space-between" gap={4} wrap="wrap" mb={4}>
            <Box>
              <Flex my={2} gap={4}>
                <Text w="80px">Title</Text>
                <Text color="textSecondary">{item?.title}</Text>
              </Flex>
              <Flex my={2} gap={4}>
                <Text w="80px">Description</Text>
                <Text color="textSecondary">{item?.body}</Text>
              </Flex>
            </Box>
            {!!isOverallAdmin &&
              (item?.isApproved ? (
                <Tag h="max-content" variant="approved">
                  Approved
                </Tag>
              ) : (
                <Tag h="max-content" variant="disapproved">
                  Disapproved
                </Tag>
              ))}
          </Flex>
          <Box>
            {item?.libraryItemType === "Image" &&
              (item.url ? (
                <Image w="full" h="full" objectFit="contain" src={item.url} />
              ) : (
                <Image
                  w="full"
                  h="full"
                  objectFit="contain"
                  src={item?.libraryItemAttachment?.fileStore?.url}
                />
              ))}
            {item?.libraryItemType === "Video" &&
              (item.url ? (
                item.url.includes("youtube") ||
                item.url.includes("youtu.be") ? (
                  <AspectRatio w="full" ratio={16 / 9}>
                    <iframe
                      loading="lazy"
                      title={item.title}
                      src={getYoutubeURL(item.url)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </AspectRatio>
                ) : (
                  <AspectRatio w="full" ratio={16 / 9}>
                    <video
                      autoPlay
                      controls
                      style={{ objectFit: "contain", backgroundColor: "black" }}
                    >
                      <source src={item.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </AspectRatio>
                )
              ) : (
                <AspectRatio w="full" ratio={16 / 9}>
                  <video
                    autoPlay
                    controls
                    style={{ objectFit: "contain", backgroundColor: "black" }}
                  >
                    <source
                      src={item?.libraryItemAttachment?.fileStore?.url}
                      type={item?.libraryItemAttachment?.fileStore?.mimeType}
                    />
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              ))}
            {item?.libraryItemType === "Document" &&
              (item.url ? (
                <AspectRatio w="full" ratio={1 / 1.4142}>
                  <iframe
                    loading="lazy"
                    title={item?.title}
                    src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${item.url}#toolbar=0&scrollbar=0`}
                    allowFullScreen
                  ></iframe>
                </AspectRatio>
              ) : (
                <AspectRatio w="full" ratio={1 / 1.4142}>
                  <iframe
                    loading="lazy"
                    title={item?.title}
                    src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${item?.libraryItemAttachment?.fileStore?.url}#toolbar=0&scrollbar=0`}
                    allowFullScreen
                  ></iframe>
                </AspectRatio>
              ))}
          </Box>
        </ModalBody>
        {!!isOverallAdmin && (
          <ModalFooter>
            <Box as="form" w="full" onSubmit={form.handleSubmit(onSubmit)}>
              {item?.isApproved && (
                <FormTextarea
                  form={form}
                  label="Note"
                  id="note"
                  options={{ required: "Note is required" }}
                  minH="150px"
                />
              )}
              <Flex gap={4} justify="space-between">
                {item?.isApproved ? (
                  <Button
                    isLoading={disapproveMutation.isLoading}
                    isDisabled={disapproveMutation.isLoading}
                    type="submit"
                    variant="outline"
                    w="200px"
                    my={4}
                    p={2}
                  >
                    Disapprove
                  </Button>
                ) : (
                  <Button
                    isLoading={approveMutation.isLoading}
                    isDisabled={approveMutation.isLoading}
                    type="submit"
                    variant="outline"
                    w="200px"
                    my={4}
                    p={2}
                  >
                    Approve
                  </Button>
                )}
                <Button w="200px" my={4} p={2} onClick={onOpenConfirm}>
                  Delete Resource
                </Button>
              </Flex>
            </Box>
          </ModalFooter>
        )}
      </ModalContent>
      <DeleteConfirmationModal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        callback={() => {
          if (item?.id) deleteMutation.mutate(item.id);
        }}
        isLoading={deleteMutation.isLoading}
        heading="Delete Resource"
        confirmation="Are you sure you want to delete this resource?"
      />
    </Modal>
  );
};

export default ResourceModal;
