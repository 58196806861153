import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Agency } from "../../interfaces/Agency";
import agenciesService from "../../services/agenciesService";
import fileUploadService from "../../services/fileUploadService";
import FormInput from "../common/FormInput";
import FormPhoneNumber from "../common/FormPhoneNumber";
import FormTextarea from "../common/FormTextarea";

const CreateAgencyForm = () => {
  const form = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const mutation = useMutation((agency: Agency) => {
    return agenciesService.createAgency(agency);
  });

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to create agency",
        description: (mutation.error as any)?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast({
        title: "Agency has been created",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/dashboard/agencies");
    }
  }, [mutation.isSuccess, toast, navigate]);

  function onSubmit(values: any) {
    mutation.mutate({
      ...values,
      logo: values.logo.fileName,
      phoneNumber: `+${values.phoneNumber}`,
    });
  }

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length > 1) return;
    setPreview(URL.createObjectURL(files[0]));
    setIsUploading(true);
    fileUploadService
      .uploadFile(files[0])
      .then(res => form.setValue("logo", res.data.body))
      .catch(err => {
        toast({
          title: "Upload Failed",
          description: err?.response?.data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        console.error(err.response);
      })
      .finally(() => {
        setIsUploading(false);
        e.target.value = "";
      });
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Box maxW="950px" mb={10}>
        <FormControl isInvalid={form.formState.errors.logo}>
          {preview ? (
            <Flex align="center" gap={6}>
              <Flex align="center" justify="center" boxSize="100px">
                <Image
                  src={preview}
                  objectFit="contain"
                  maxW="100%"
                  maxH="100%"
                />
              </Flex>
              <Box>
                <Button
                  display="block"
                  onClick={() => inputRef.current?.click()}
                >
                  Change Logo
                </Button>
                {isUploading && (
                  <Progress
                    my={2}
                    size="sm"
                    isIndeterminate
                    h="5px"
                    colorScheme="primary"
                  />
                )}
              </Box>
            </Flex>
          ) : (
            <Button onClick={() => inputRef.current?.click()}>
              Upload Logo
            </Button>
          )}
          <Input
            ref={inputRef}
            id="fileInput"
            type="file"
            hidden
            onChange={onFileUpload}
            accept="image/*"
          />
          <Input
            type="hidden"
            {...form.register("logo", { required: "Logo is required" })}
          />
          <FormErrorMessage>
            {form.formState.errors.logo && form.formState.errors.logo.message}
          </FormErrorMessage>
        </FormControl>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Name"
            id="name"
            options={{ required: "Name is required" }}
          />
          <FormInput
            form={form}
            label="Short Code"
            id="shortCode"
            options={{ required: "Short Code is required" }}
          />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormInput
            form={form}
            label="Email"
            id="email"
            type="email"
            options={{ required: "Email is required" }}
          />
          <FormPhoneNumber form={form} label="Phone Number" id="phoneNumber" />
        </Flex>
        <Flex flexDir={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 6 }}>
          <FormTextarea
            form={form}
            label="Description"
            id="description"
            options={{ required: "Description is required" }}
            minH="150px"
          />
          <FormInput
            w="80px"
            h="50px"
            form={form}
            label="Color"
            type="color"
            id="color"
            options={{ required: "Color is required" }}
          />
        </Flex>
      </Box>
      <Button
        my={4}
        px="50px"
        isLoading={form.formState.isSubmitting || mutation.isLoading}
        type="submit"
        disabled={
          isUploading || form.formState.isSubmitting || mutation.isLoading
        }
      >
        Submit
      </Button>
    </form>
  );
};

export default CreateAgencyForm;
