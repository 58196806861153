import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import logo from "../../assets/images/flagit-logo.png";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import Link from "../../components/common/Link";
import { useAuth } from "../../context/useAuth";
import authService from "../../services/authService";
import { resetPasswordSchema } from "../../utils/validationSchema";

interface FieldValues {
  code: string;
  newPassword: string;
  confirmPassword: string;
}

export const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePassword = () => setShowPassword(prev => !prev);
  const toggleConfirmPassword = () => setShowConfirmPassword(prev => !prev);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FieldValues>({ resolver: yupResolver(resetPasswordSchema) });
  const auth = useAuth();
  const toast = useToast();
  const mutation = useMutation((email: string) => {
    return authService.resetPassword(email);
  });

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to edit profile",
        description: (mutation.error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  useEffect(() => {
    if (mutation.isSuccess) {
      auth?.getUserInfo(false);
      toast({
        title: "Profile edited successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess, toast]);

  function onSubmit(values: FieldValues) {
    console.log(values);
  }
  return (
    <Container p={4}>
      <Helmet>
        <title>FlagIt | Reset Password</title>
      </Helmet>
      <Box my={{ base: 0, sm: 8 }}>
        <Box w="92px">
          <Link to="/">
            <Image src={logo} />
          </Link>
        </Box>
      </Box>
      <Flex align="center" justify="center">
        <Box textAlign="center">
          <Heading size="2xl">Reset Password</Heading>
          <Text color="textSecondary" my={6}>
            Enter the code sent to your email and reset your password
          </Text>
          <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={10}>
            <Box mb={10}>
              <FormControl my={6} isInvalid={!!errors.code}>
                <FormLabel fontWeight="semibold" color="#0A230E" htmlFor="code">
                  Code
                </FormLabel>
                <Input h="50px" id="code" {...register("code")} />
                <FormErrorMessage>
                  {errors.code && errors.code.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl my={6} isInvalid={!!errors.newPassword}>
                <FormLabel
                  fontWeight="semibold"
                  color="#0A230E"
                  htmlFor="newPassword"
                >
                  New Password
                </FormLabel>
                <Box w="full" position="relative">
                  <Input
                    h="50px"
                    id="newPassword"
                    pr="99px"
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    {...register("newPassword")}
                  />
                  <Box
                    as="span"
                    position="absolute"
                    zIndex="overlay"
                    top="50%"
                    transform="translateY(-50%)"
                    right={2}
                    fontWeight="bold"
                    fontSize="xs"
                    onClick={togglePassword}
                    color="secondary.500"
                    cursor="pointer"
                    _hover={{ textDecor: "underline" }}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Box>
                </Box>
                <FormErrorMessage>
                  {errors.newPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl my={6} isInvalid={!!errors.confirmPassword}>
                <FormLabel
                  fontWeight="semibold"
                  color="#0A230E"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </FormLabel>
                <Box w="full" position="relative">
                  <Input
                    h="50px"
                    id="confirmPassword"
                    pr="99px"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="on"
                    {...register("confirmPassword")}
                  />
                  <Box
                    as="span"
                    position="absolute"
                    zIndex="overlay"
                    top="50%"
                    transform="translateY(-50%)"
                    right={2}
                    fontWeight="bold"
                    fontSize="xs"
                    onClick={toggleConfirmPassword}
                    color="secondary.500"
                    cursor="pointer"
                    _hover={{ textDecor: "underline" }}
                  >
                    {showConfirmPassword ? "Hide" : "Show"}
                  </Box>
                </Box>
                <FormErrorMessage>
                  {errors.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Button
              h="50px"
              w="full"
              my={6}
              isLoading={auth?.loading}
              type="submit"
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};
