import { useRef, useState } from "react";
import {
  FlexProps,
  Flex,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import Pause from "../svg/Pause";
import { FileStore } from "../../interfaces/FileUpload";
import Play from "../svg/Play";

interface AudioPlayerProps extends FlexProps {
  recording?: FileStore | null;
}

const AudioPlayer = ({ recording, ...props }: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const rafRef = useRef<number>();
  const [playing, setPlaying] = useState(false);
  const [seeker, setSeeker] = useState(0);

  const whilePlaying = () => {
    if (audioRef.current) setSeeker(Math.floor(audioRef.current.currentTime));
    rafRef.current = requestAnimationFrame(whilePlaying);
  };

  if (audioRef.current)
    audioRef.current.addEventListener("ended", function () {
      if (audioRef.current) audioRef.current.currentTime = 0;
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
      setPlaying(false);
    });

  const calculateTime = (secs?: number) => {
    if (secs && secs !== Infinity) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    }
  };

  return (
    <>
      {recording && (
        <audio src={recording.blob || recording.url} ref={audioRef}></audio>
      )}
      <Flex
        gap={2}
        justify="space-between"
        align="center"
        w="full"
        p={2}
        fontSize="sm"
        borderRadius="5px"
        bg="#D8F5F0"
        _notLast={{ mb: 4 }}
        {...props}
      >
        {!playing ? (
          <IconButton
            bg="#9EE6DA"
            _hover={{}}
            _active={{}}
            _focus={{}}
            color="#0C2924"
            borderRadius="full"
            aria-label="play recording"
            icon={<Play />}
            fontSize="20px"
            size="sm"
            onClick={() => {
              setPlaying(true);
              audioRef.current?.play();
              requestAnimationFrame(whilePlaying);
            }}
          />
        ) : (
          <IconButton
            bg="#9EE6DA"
            _hover={{}}
            _active={{}}
            _focus={{}}
            color="#0C2924"
            borderRadius="full"
            aria-label="pause recording"
            icon={<Pause />}
            fontSize="20px"
            size="sm"
            onClick={() => {
              setPlaying(false);
              audioRef.current?.pause();
              if (rafRef.current) cancelAnimationFrame(rafRef.current);
            }}
          />
        )}
        {recording && audioRef.current && (
          <>
            <Text color="#424D44">
              {audioRef.current.currentTime
                ? calculateTime(audioRef.current.currentTime)
                : calculateTime(audioRef.current.duration)}
            </Text>
            <Slider
              flex="2"
              min={0}
              defaultValue={0}
              max={Math.floor(audioRef.current.duration)}
              value={seeker}
              onChange={val => {
                if (audioRef.current) {
                  audioRef.current.currentTime = val;
                  setSeeker(val);
                }
              }}
            >
              <SliderTrack bg="white">
                <SliderFilledTrack bg="primary.800" />
              </SliderTrack>
            </Slider>
          </>
        )}
      </Flex>
    </>
  );
};

export default AudioPlayer;
