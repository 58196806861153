import { IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

const PrevArrow = ({ className, onClick }: any) => {
  const name = className
    .split(" ")
    .find((name: string) => name === "slick-disabled");
  return (
    <IconButton
      className={name}
      zIndex={2}
      pos="absolute"
      top="50%"
      left="0"
      transform="translateY(-50%)"
      aria-label="prev arrow"
      size="md"
      onClick={onClick}
      icon={<ChevronLeftIcon fontSize="30px" />}
      borderRadius="full"
      color="textSecondary"
      bg="white"
      boxShadow="-8px 0px 16px 0px #FFFFFF52"
      variant="ghost"
    />
  );
};

export default PrevArrow;
