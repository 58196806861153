import { Box, Flex, Text } from "@chakra-ui/react";
import Heading from "../../../components/common/Heading";
import ReportCaseForm from "../../../components/reportCase/ReportCaseForm";
import { UploadProvider } from "../../../context/useUploads";
import { RecorderProvider } from "../../../context/useRecorder";

export const ReportCase = () => {
  return (
    <Box>
      <Flex
        justify="space-between"
        align="flex-start"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Box>
          <Heading mb={3}>Report Case</Heading>
          <Text fontSize="md" maxW="448px">
            Your information is treated with utmost confidentiality and will not
            be shared with third parties
          </Text>
        </Box>
      </Flex>
      <UploadProvider>
        <RecorderProvider>
          <ReportCaseForm />
        </RecorderProvider>
      </UploadProvider>
    </Box>
  );
};
