import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import logo from "../../assets/images/flagit-logo.png";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import Link from "../../components/common/Link";
import authService from "../../services/authService";

interface FieldValues {
  email: string;
}

export const ForgotPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FieldValues>();
  const toast = useToast();
  const mutation = useMutation((email: string) => {
    return authService.resetPassword(email);
  });

  useEffect(() => {
    if (mutation.isError) {
      toast({
        title: "Failed to initiate password reset",
        description: (mutation.error as any).response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [mutation.isError, toast, mutation.error]);

  function onSubmit({ email }: FieldValues) {
    mutation.mutate(email);
  }
  return (
    <Container p={4}>
      <Helmet>
        <title>FlagIt | Forgot Password</title>
      </Helmet>
      <Box my={{ base: 0, sm: 8 }}>
        <Box w="92px">
          <Link to="/">
            <Image src={logo} />
          </Link>
        </Box>
      </Box>
      <Flex align="center" justify="center">
        <Box textAlign="center">
          <Heading size="2xl">Forgot Password</Heading>
          <Text color="textSecondary" my={6}>
            Enter your email to reset your password
          </Text>
          <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={10}>
            <Box mb={10}>
              {mutation.isSuccess && (
                <Alert color="#2d3748" status="success">
                  <AlertIcon />
                  {mutation.data.data?.message}
                </Alert>
              )}
              <FormControl my={6} isInvalid={!!errors.email}>
                <FormLabel
                  fontWeight="semibold"
                  color="#0A230E"
                  htmlFor="email"
                >
                  Email Address
                </FormLabel>
                <Input
                  h="50px"
                  id="email"
                  type="email"
                  {...register("email", { required: "Email is required" })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Button
              h="50px"
              w="full"
              my={6}
              isLoading={mutation.isLoading}
              type="submit"
            >
              Send link
            </Button>
            <Link
              to="/login"
              textAlign="center"
              color="#0A230E"
              fontWeight="bold"
              my={6}
            >
              Back to log in
            </Link>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};
