import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./context/useAuth";
import getUserRole from "./helpers/getUserRole";
import "./index.css";
import DashboardLayout from "./layouts/DashboardLayout";
import HomeLayout from "./layouts/HomeLayout";
import ScrollToTop from "./layouts/ScrollToTop";
import {
  Agencies,
  AgencyCaseDetails,
  AgencyCases,
  AgencyUsers,
  AssignedCaseDetails,
  AssignedCases,
  CreateAgency,
  CreateAgencyUser,
  Dashboard,
  EditAgency,
  EditAgencyUser,
  EditLocation,
  EmergencyMessage,
  FAQ,
  ForgotPassword,
  Home,
  Library,
  Locations,
  Login,
  MyCaseDetails,
  MyCases,
  OverallCaseDetails,
  OverallCases,
  PrivacyPolicy,
  Profile,
  QuickReport,
  Register,
  ReportCase,
  ResetPassword,
  SuccessStories,
  UserDevices,
  Users,
  Verify,
  VerifyEmail
} from "./routes";
import Protected from "./routes/auth/Protected";

export const App = () => {
  const auth = useAuth();
  const { isOverallAdmin, isAgencyAdmin, isAgencyUser, isDefaultUser } =
    getUserRole(auth?.user?.roles);

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="faqs" element={<FAQ />} />
        </Route>
        <Route path="register" element={<Register />} />
        <Route path="verify" element={<Verify />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="quick-report" element={<QuickReport />} />
        <Route
          element={<Protected isAllowed={!!auth?.user} redirectPath="/login" />}
        >
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route
              element={
                <Protected
                  isAllowed={!!isOverallAdmin}
                  redirectPath="/dashboard"
                />
              }
            >
              <Route path="agencies" element={<Agencies />} />
              <Route path="agencies/create" element={<CreateAgency />} />
              <Route path="agencies/:agencyId" element={<EditAgency />} />
            </Route>
            <Route
              element={
                <Protected
                  isAllowed={!!isAgencyAdmin || !!isOverallAdmin}
                  redirectPath="/dashboard"
                />
              }
            >
              {/* <Route path="agency-cases" element={<AgencyCases />} /> */}
              <Route
                path="agency-cases/:caseId"
                element={<AgencyCaseDetails />}
              />
              <Route path="agency-users" element={<AgencyUsers />} />
              <Route
                path="agency-users/create"
                element={<CreateAgencyUser />}
              />
              <Route path="agency-users/:userId" element={<EditAgencyUser />} />
            </Route>
            <Route
              element={
                <Protected
                  isAllowed={!!isAgencyUser || !!isAgencyAdmin}
                  redirectPath="/dashboard"
                />
              }
            >
              <Route path="assigned-cases" element={<AssignedCases />} />
              <Route
                path="assigned-cases/:caseId"
                element={<AssignedCaseDetails />}
              />
            </Route>
            <Route
              element={
                <Protected
                  isAllowed={isDefaultUser}
                  redirectPath="/dashboard"
                />
              }
            >
              <Route path="my-cases" element={<MyCases />} />
              <Route path="my-cases/:caseId" element={<MyCaseDetails />} />
              <Route path="my-cases/report" element={<ReportCase />} />
            </Route>
            <Route
              element={
                <Protected
                  isAllowed={!!isOverallAdmin}
                  redirectPath="/dashboard"
                />
              }
            >
              {/* <Route path="overall-cases" element={<OverallCases />} /> */}
              <Route
                path="overall-cases/:caseId"
                element={<OverallCaseDetails />}
              />
              <Route path="users" element={<Users />} />
              <Route path="user-devices" element={<UserDevices />} />
              <Route path="locations" element={<Locations />} />
              <Route path="locations/:locationId" element={<EditLocation />} />
            </Route>
            <Route path="emergency-message" element={<EmergencyMessage />} />
            <Route path="library" element={<Library />} />
            <Route path="success-stories" element={<SuccessStories />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ScrollToTop>
  );
};
