import { AxiosRequestConfig } from "axios";
import axiosInstance from "../helpers/axiosInstance";

const uploadFile = (
  file: File,
  config?: AxiosRequestConfig<FormData> | undefined
) => {
  const url = `/fileUpload`;
  const isImage = file.type.startsWith("image");
  const isVideo = file.type.startsWith("video");
  const isAudio = file.type.startsWith("audio");
  const body = new FormData();
  body.append("file", file);
  if (isImage) body.append("fileType", "image");
  else if (isVideo) body.append("fileType", "video");
  else if (isAudio) body.append("fileType", "audio");
  else body.append("fileType", "application");
  return axiosInstance.post(url, body, config);
};

const fileUploadService = { uploadFile };

export default fileUploadService;
