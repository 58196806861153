import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Column } from "react-table";
import Heading from "../../../components/common/Heading";
import SelectColumnFilter from "../../../components/common/SelectColumnFilter";
import NewStoryModal from "../../../components/successStories/NewStoryModal";
import Table from "../../../components/successStories/Table";
import ViewStoryModal from "../../../components/successStories/ViewStoryModal";
import { useAuth } from "../../../context/useAuth";
import getUserRole from "../../../helpers/getUserRole";
import { SuccessStory } from "../../../interfaces/SuccessStory";
import incidenceService from "../../../services/incidenceService";
import successStoriesService from "../../../services/successStoriesService";

export const SuccessStories = () => {
  const [stories, setStories] = useState<SuccessStory[]>([]);
  const [story, setStory] = useState<SuccessStory>();
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const fetchIdRef = useRef(0);
  const toast = useToast();
  const auth = useAuth();
  const {
    isOpen: isOpenNewStory,
    onClose: onCloseNewStory,
    onOpen: onOpenNewStory,
  } = useDisclosure();
  const {
    isOpen: isOpenViewStory,
    onClose: onCloseViewStory,
    onOpen: onOpenViewStory,
  } = useDisclosure();
  const { data: incidencesRes } = useQuery(
    ["incidences"],
    incidenceService.getIncidenceTypes,
    { staleTime: 60000 }
  );
  const { isOverallAdmin, isDefaultUser } = getUserRole(auth?.user?.roles);
  const tableData = React.useMemo(() => stories, [stories]);

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: "",
        accessor: "aprovalStatus",
        disableFilters: true,
      },
      {
        Header: "Name",
        accessor: "title",
        disableFilters: true,
      },
      {
        Header: "Story",
        accessor: "description",
        disableFilters: true,
      },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
      },
      {
        Header: "Incident",
        accessor: "incidenceType",
        disableFilters: true,
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageIndex: number; pageSize: number }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        if (!!isOverallAdmin)
          if (filter === "approved")
            successStoriesService
              .getApprovedSuccessStories(pageIndex + 1, pageSize)
              .then(res => {
                setStories(res.data.body.data);
                setTotalCount(res.data.body.totalCount);
                setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
                setLoading(false);
              })
              .catch(err => {
                console.error(err.response);
                toast({
                  title: "Failed to fetch success stories",
                  description: err?.response?.data?.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "top-right",
                });
                setLoading(false);
              });
          else if (filter === "disapproved")
            successStoriesService
              .getDisapprovedSuccessStories(pageIndex + 1, pageSize)
              .then(res => {
                setStories(res.data.body.data);
                setTotalCount(res.data.body.totalCount);
                setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
                setLoading(false);
              })
              .catch(err => {
                console.error(err.response);
                toast({
                  title: "Failed to fetch success stories",
                  description: err?.response?.data?.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "top-right",
                });
                setLoading(false);
              });
          else
            successStoriesService
              .getOverallSuccessStories(pageIndex + 1, pageSize)
              .then(res => {
                setStories(res.data.body.data);
                setTotalCount(res.data.body.totalCount);
                setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
                setLoading(false);
              })
              .catch(err => {
                console.error(err.response);
                toast({
                  title: "Failed to fetch success stories",
                  description: err?.response?.data?.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "top-right",
                });
                setLoading(false);
              });
        else
          successStoriesService
            .getSuccessStories(pageIndex + 1, pageSize)
            .then(res => {
              setStories(res.data.body.data);
              setTotalCount(res.data.body.totalCount);
              setPageCount(Math.ceil(res.data.body.totalCount / pageSize));
              setLoading(false);
            })
            .catch(err => {
              console.error(err.response);
              toast({
                title: "Failed to fetch success stories",
                description: err?.response?.data?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              setLoading(false);
            });
      }
    },
    [toast, isOverallAdmin, filter]
  );

  return (
    <Box>
      <Helmet>
        <title>FlagIt | Success Stories</title>
      </Helmet>
      <Flex
        justify="space-between"
        align="center"
        flexWrap="wrap"
        gap={4}
        my={8}
      >
        <Heading>Success Stories</Heading>
        {isDefaultUser && (
          <Button
            w={{ base: "full", sm: "auto" }}
            fontSize={{ base: "sm", md: "md" }}
            px={10}
            leftIcon={<AddIcon />}
            onClick={onOpenNewStory}
          >
            Add New Story
          </Button>
        )}
      </Flex>

      {tableData && (
        <Table
          loading={loading}
          fetchData={fetchData}
          totalCount={totalCount}
          pageCount={pageCount}
          columns={columns}
          data={tableData}
          heading="Stories"
          onOpen={onOpenViewStory}
          setStory={setStory}
          filter={filter}
          FilterComponent={
            <Select
              fontSize="sm"
              w="max-content"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            >
              <option value="">Status</option>
              <option value="approved">Approved</option>
              <option value="disapproved">Disapproved</option>
            </Select>
          }
        />
      )}
      <NewStoryModal
        incidences={incidencesRes?.data.body}
        isOpen={isOpenNewStory}
        onClose={onCloseNewStory}
      />
      <ViewStoryModal
        fetchData={fetchData}
        isOpen={isOpenViewStory}
        onClose={onCloseViewStory}
        story={story}
      />
    </Box>
  );
};
