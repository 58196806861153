import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon
} from "@chakra-ui/icons";
import {
  Box,
  chakra,
  Divider,
  Flex,
  IconButton,
  Select,
  Spinner,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import EmptyState from "./EmptyState";

export type TableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  heading?: string;
  pageCount: number;
  totalCount: number;
  loading: boolean;
  fetchData: any;
  route?: string;
  FilterComponent?: any;
  filter?: any;
  isAgencies?: boolean;
  cursor?: string;
  onClick?: (data: any) => void;
};

const Table = <Data extends object>({
  data,
  columns,
  heading,
  pageCount: controlledPageCount,
  fetchData,
  loading,
  totalCount,
  route,
  FilterComponent,
  filter,
  isAgencies = false,
  cursor = "pointer",
  onClick,
}: TableProps<Data>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex },
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const recordsCountFrom =
    pageIndex + 1 === pageCount && pageCount !== 1
      ? totalCount - pageSize + 1
      : pageIndex * pageSize + 1;
  const recordsCountTo = recordsCountFrom + page.length - 1;

  if (!loading && data.length === 0 && !filter)
    return (
      <EmptyState
        h="calc(100vh - 88px - 104px - 32px)"
        isAgencies={isAgencies}
      />
    );

  return (
    <Box
      p={4}
      boxShadow="0px 8px 16px 0px #D4D4D43D"
      border="1px solid #D4D4D4"
      borderRadius="10px"
    >
      <Flex justify="space-between" align="center" flexWrap="wrap" gap={4}>
        <Text my={2} fontSize="md" fontWeight="semibold" color="textSecondary">
          {heading}
        </Text>
        {FilterComponent}
      </Flex>
      <Divider my={6} bg="#D4D4D4" />
      {!loading && data.length === 0 && filter ? (
        <EmptyState isFilter />
      ) : (
        <>
          <TableContainer>
            <ChakraTable fontSize="xs" {...getTableProps()}>
              <Thead bg="#DADADA21">
                {headerGroups.map(headerGroup => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <Th
                        color="text"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      cursor={cursor}
                      _hover={{ bg: "gray.50" }}
                    >
                      {row.cells.map(cell => (
                        <Td
                          color="textSecondary"
                          {...cell.getCellProps()}
                          onClick={() => {
                            if (onClick) onClick(row.original);
                            if (
                              route &&
                              cell.column.Header &&
                              cell.column.Header !== "Admin" &&
                              cell.column.Header !== "Enabled"
                            )
                              navigate(
                                `${route}/${
                                  (row.original as any).id ||
                                  (row.original as any).userProfileId
                                }`
                              );
                          }}
                        >
                          <Text as="span" maxW={300} noOfLines={1}>
                            {cell.render("Cell")}
                          </Text>
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </ChakraTable>
          </TableContainer>
          <Flex justify="space-between" my={4} flexWrap="wrap" gap={4}>
            <Flex fontSize="xs" align="center" gap={2}>
              <Text color="textSecondary" whiteSpace="nowrap">
                Show rows per page
              </Text>
              <Select
                size="sm"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex align="center" gap={8} fontSize="sm">
              {loading ? (
                <Spinner />
              ) : (
                <Box>
                  <Box as="span" color="textSecondary">
                    {`${recordsCountFrom}-${recordsCountTo} `}
                  </Box>
                  of {totalCount}
                </Box>
              )}
              <Flex align="center" gap={2}>
                <IconButton
                  size="xs"
                  variant="link"
                  icon={<ChevronLeftIcon fontSize="1rem" />}
                  aria-label="go to previous page"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <IconButton
                  size="xs"
                  variant="link"
                  icon={<ChevronRightIcon fontSize="1rem" />}
                  aria-label="go to previous page"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Table;
