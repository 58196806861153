import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/flagit-logo.png";
import RegisterForm from "../../components/auth/RegisterForm";
import Container from "../../components/common/Container";
import Heading from "../../components/common/Heading";
import Link from "../../components/common/Link";

export const Register = () => {
  return (
    <Container p={4}>
      <Helmet>
        <title>FlagIt | Register</title>
      </Helmet>
      <Box my={{ base: 0, sm: 8 }}>
        <Box w="92px">
          <Link to="/">
            <Image src={logo} />
          </Link>
        </Box>
      </Box>
      <Flex align="center" justify="center">
        <Box textAlign="center">
          <Heading size="2xl">Create your account</Heading>
          <Text color="textSecondary" my={6}>
            Flag your experience for quick and easy resolution
          </Text>
          <RegisterForm />
        </Box>
      </Flex>
    </Container>
  );
};
